<template>
  <v-expansion-panel v-model="store.paymentOpened" expand>
    <v-expansion-panel-content>
      <div slot="header">
        <h3>Payment</h3>
      </div>
      <div>
        <div v-if="store.isTransactionCompleted" class="px-4 py-2 pb-3">
          <span class="complete-text font-weight-bold green--text"> &check; &nbsp; Payment successful </span>
        </div>
        <div v-else-if="store.invoiceCreated" class="px-4 py-2 pb-3">
          <span class="complete-text font-weight-bold green--text"> &check; &nbsp; Invoice created </span>
        </div>
        <div v-else-if="job?.status === 'Cancelled' || job?.cancellationReason" class="px-4 py-2 pb-3">
          <span class="complete-text font-weight-bold red--text"> Job cancelled </span>
        </div>
        <div v-else>
          <div v-if="!store.paymentDetailsConfirmed">
            <v-layout v-if="true" wrap grey lighten-4>
              <span class="ma-3 font-weight-bold text-center pa-2 pl-3 call-sid-text">
                &#33; &nbsp; You must confirm payment details before taking the payment
              </span>
            </v-layout>
          </div>
          <div v-else>
            <v-layout v-if="!callSid" wrap grey lighten-4>
              <span class="ma-3 font-weight-bold text-center pa-2 pl-3 call-sid-text">
                &#33; &nbsp; You must be on a call to take payment details
              </span>
            </v-layout>

            <v-layout v-else>
              <AgentAssist :call-sid="callSid" :job="job" @resumeCallRecording="resumeCallRecording" />
            </v-layout>
          </div>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import Vue from 'vue'
import Component from 'vue-class-component'
import Store from '@/store'
import Job from '@/models/Job'
import { Prop } from 'vue-property-decorator'
import TwilioController from '@/api/twilioController'
import eventBus from '@/common/bus'
import AgentAssist from './AgentAssist/AgentAssist.vue'

@Component({
  components: { AgentAssist },
})
export default class Payment extends Vue {
  @Prop() private job: Job | null

  public store = useCustomerToPayStore()

  public get callSid(): string {
    return Store.Instance.state.CurrentCallSid
  }

  public async resumeCallRecording() {
    if (this.callSid) {
      try {
        const isPaused = await TwilioController.PauseRecording(this.callSid, false)
        if (!isPaused) {
          eventBus.$emit('clearTwilioSnackbar')
        } else {
          eventBus.$emit('showTwilioSnackbar', 'Error resuming recording for Customer to Pay process.', false)
        }
      } catch {
        eventBus.$emit('showTwilioSnackbar', 'Error resuming recording for Customer to Pay process.', false)
      }
    }
  }
}
</script>

<style scoped>
.complete-text {
  font-size: 16px;
}
</style>
