const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export function getFormattedCurrency(value: number | undefined): string {
  return currencyFormatter.format(value || 0)
}
