import { defineStore } from 'pinia'
import Store from '@/store'
import { ConferenceOptions, Reservation } from 'twilio-taskrouter'
import { Call } from '@twilio/voice-sdk'
import { computed, ref } from 'vue'
import { useTwilioWorker } from './worker'
import eventBus from '@/common/bus'
import CallTaskAlert from '@/models/twilio/CallTaskAlert'
import { getLogger } from 'loglevel'
import { useTwilioDevice } from './device'
import TwilioController from '@/api/twilioController'

const logger = getLogger('@simplifi/twilio-client')

export const useTwilioCall = defineStore('twilio-call', () => {
  const device = useTwilioDevice()
  const worker = useTwilioWorker()

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const callingAudio: HTMLAudioElement = new Audio(require('@/assets/media/ringing.mp3'))

  const initialized = ref<boolean>(false)
  const callRef = ref<Call>()
  const reservationRef = ref<Reservation>()
  const callAlert = ref<CallTaskAlert>()
  const onHold = ref<boolean>(false)
  const callingLabel = ref<string>()
  const policyName = ref<string>()

  function onWorkerReservationCreated(reservation: Reservation) {
    logger.debug('worker:reservationCreated', reservation.sid, reservation.task.sid)

    reservation.on('accepted', onReservationAccepted)
    reservation.on('rejected', onReservationRejected)
    reservation.on('timeout', onReservationTimeout)
    reservation.on('canceled', onReservationCanceled)
    reservation.on('rescinded', onReservationRescinded)

    // TODO: handle the different channels, make sure we don't alert the user if they're on another call
    if (reservation.task.taskChannelUniqueName !== 'voice' || callRef.value) {
      reservation.reject({ activitySid: Store.Instance.state.Environment.TwilioBusyActivitySid })
      return
    }

    // Handle incoming call reservation
    reservationRef.value = reservation

    const newAlert = new CallTaskAlert()
    newAlert.loadFromReservation(reservation)
    callAlert.value = newAlert

    if (newAlert.isCallFromHari) {
      eventBus.$emit('showIncomingCallAlertModal', newAlert)
    }
    callingAudio.play()
  }

  async function onReservationAccepted(reservation: Reservation) {
    logger.debug('reservation:accepted', reservation.sid, reservation.task.sid)

    reservationRef.value = reservation
    callAlert.value = undefined
    // try changing the worker status to Busy
    if (worker.instance.activity.name === 'Idle') {
      await worker.setActivity(Store.Instance.state.Environment.TwilioBusyActivitySid, 'Reservation accepted')
    }
  }

  function onReservationRejected(reservation: Reservation) {
    logger.debug('reservation:rejected', reservation.sid, reservation.task.sid)
    reservationNotAccepted(reservation)
  }

  function onReservationTimeout(reservation: Reservation) {
    logger.debug('reservation:timeout', reservation.sid, reservation.task.sid)
    reservationNotAccepted(reservation)
  }

  function onReservationCanceled(reservation: Reservation) {
    logger.debug('reservation:canceled', reservation.sid, reservation.task.sid)
    reservationNotAccepted(reservation)
  }

  function onReservationRescinded(reservation: Reservation) {
    logger.debug('reservation:rescinded', reservation.sid, reservation.task.sid)
    reservationNotAccepted(reservation)
  }

  function init() {
    if (initialized.value) {
      return
    }

    logger.debug('call:init')

    callingAudio.setSinkId('default')
    worker.instance.on('reservationCreated', onWorkerReservationCreated)

    initialized.value = true
  }

  function setCall(call: Call) {
    callRef.value = call
  }

  function clearCall() {
    callRef.value = undefined
    onHold.value = false
  }

  function clearReservation() {
    reservationRef.value = undefined
  }

  function clearCallAlert() {
    callAlert.value = undefined
  }

  function reservationNotAccepted(reservation: Reservation) {
    if (reservationRef.value?.sid === reservation.sid) {
      eventBus.$emit('hideIncomingCallAlertModal')
      reservationRef.value = undefined
      callAlert.value = undefined
    }
  }

  type CallArgs = {
    to: string
    jobId?: string
    contractorId?: string
    emergencyId?: string
    taskSid?: string
    policyName?: string
  }

  async function makeCall(args: CallArgs): Promise<Call> {
    device.instance.disconnectAll()

    const params: Record<string, string> = {
      From: worker.instance.attributes.contact_uri,
      To: args.to,
    }
    if (args.jobId) {
      params.JobId = args.jobId
    }
    if (args.contractorId) {
      params.ContractorId = args.contractorId
    }
    if (args.emergencyId) {
      params.EmergencyId = args.emergencyId
    }
    if (args.taskSid) {
      params.TaskSid = args.taskSid
    }

    callRef.value = await device.instance.connect({ params, ...device.audioConfig })
    callingLabel.value = args.to
    policyName.value = args.policyName

    return callRef.value
  }

  async function answerCall(): Promise<Record<string, any>> {
    const conferenceOptions: ConferenceOptions = {
      beep: false,
      beepOnCustomerEntrance: false,
      record: 'true',
      recordingChannels: 'dual',
      recordingStatusCallback: Store.Instance.state.Environment.RecordingStatusCallbackUrl,
      recordingStatusCallbackMethod: 'POST',
      conferenceStatusCallback: `${Store.Instance.state.Environment.WebhooksApiBaseUrl}/api/conference/status-change`,
      conferenceStatusCallbackEvent: 'leave,join',
      conferenceStatusCallbackMethod: 'POST',
    }

    const reservation = reservationRef.value
    if (reservation?.status !== 'pending') {
      throw new Error('Attempted to answer call without reservation')
    }

    await reservation.conference(conferenceOptions)
    clearCallAlert()

    callingLabel.value = reservation.task.attributes.from
    policyName.value = reservation.task.attributes.policyName
    return reservation.task.attributes
  }

  function toggleMute() {
    if (!callRef.value) {
      throw new Error('Attempted to toggle mute without active call.')
    }

    const mute = !callRef.value?.isMuted()
    callRef.value.mute(mute)
    if (mute) {
      eventBus.$emit('showTwilioSnackbar', 'Call muted', true)
    } else {
      eventBus.$emit('clearTwilioSnackbar')
    }
  }

  async function toggleHold(): Promise<boolean> {
    const callSid = callRef.value?.parameters.CallSid
    if (!callSid) {
      throw new Error('Attempted to hold without an active call')
    }

    onHold.value = await TwilioController.ConferenceHold(callSid, !onHold.value)
    if (onHold.value) {
      eventBus.$emit('showTwilioSnackbar', 'Call on hold', true)
    } else {
      eventBus.$emit('clearTwilioSnackbar')
    }
    return onHold.value
  }

  async function endCall() {
    if (!callRef.value) {
      throw new Error('Attempted to end call without active call.')
    }

    if (reservationRef.value?.status === 'pending') {
      reservationRef.value.reject()
      clearReservation()
    }

    const callSid = callRef.value?.parameters.CallSid
    device.instance.disconnectAll()

    await TwilioController.HandleAgentLeavingConference(callSid)
    await worker.setActivity(Store.Instance.state.Environment.TwilioWrapUpActivitySid, 'Call ended')
  }

  return {
    init,
    setCall,
    clearCall,
    clearCallAlert,
    clearReservation,
    toggleMute,
    toggleHold,
    makeCall,
    answerCall,
    endCall,
    onHold,
    inCall: computed(() => !!callRef.value),
    callAlert,
    callingLabel,
    policyName,
    call: callRef,
    callMuted: computed(() => callRef.value?.isMuted() ?? false),
    reservation: reservationRef,
  }
})
