export enum QuestionEntityType {
  Policy,
  Emergency,
  EmergencyDetail,
}

export enum ModelOperation {
  Add,
  Edit,
  Delete,
}

export enum AnswerType {
  Text = 1,
  Dropdown = 2,
  Number = 3,
  Checkbox = 4,
}

export enum OperatorType {
  EqualTo = 1,
  GreaterThan = 2,
  LessThan = 3,
  GreaterThanOrEqualTo = 4,
  LessThanOrEqualTo = 5,
  NotEqualTo = 6,
  In = 7,
  NotIn = 8,
}

export enum RequestStatus {
  Pending = 1,
  Approved = 2,
  Reject = 3,
}

export enum ContactPreferenceEnum {
  SMS = 1,
  Email = 2,
  Call = 3,
}

export enum DocumentPreference {
  SMS = 1,
  Email = 2,
  Both = 3,
}

export enum Complexity {
  Low = 1,
  BelowNormal = 2,
  Normal = 3,
  AboveNormal = 4,
  High = 5,
}

export enum HealthAndSafetyLevel {
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum Priority {
  Low = 1,
  BelowNormal = 2,
  Normal = 3,
  AboveNormal = 4,
  High = 5,
  RealTime = 6,
}

export interface IJobNoteType {
  id: JobNoteType
  description: string
}

export type JobNoteType =
  | 'Miscellaneous'
  | 'IncomingCall'
  | 'OutgoingCall'
  | 'LinkedCall'
  | 'Compliment'
  | 'ContractorInvoicingAmendment'
  | 'ClientInvoicingAmendment'
  | 'ComplaintNote'
export const JobNoteTypes: IJobNoteType[] = [
  { id: 'Miscellaneous', description: 'Miscellaneous' },
  { id: 'IncomingCall', description: 'Incoming Call' },
  { id: 'OutgoingCall', description: 'Outgoing Call' },
  { id: 'ComplaintNote', description: 'Complaint' },
  { id: 'Compliment', description: 'Compliment' },
  {
    id: 'ContractorInvoicingAmendment',
    description: 'Contractor Invoicing Amendment',
  },
  { id: 'ClientInvoicingAmendment', description: 'Client Invoicing Amendment' },
]

export enum JobVisitStatus {
  All = 0,
  Pending = 1,
  Accepted = 2,
  InProgress = 3,
  Completed = 4,
  Unavailable = 5,
  Cancelled = 6,
  AutoDeploying = 7,
}

export enum SIJobStatus {
  New = 0,
  CustomerNotified = 1,
  VisitBooked = 2,
  Testing = 3,
  ReportProvided = 4,
  Closed = 5,
  Monitoring = 6,
}

export enum RequestType {
  AddHEJobWithEmergencies,
  AddEmergency,
  AddEmergencyAnswer,
  UpdateJobCoordinates,
  AddWeatherForecast,
  AddCustomerAvailabilityTimeSlot,
  AddCustomerToPay,
  UpdateCustomerPaymentDetail,
  AddEngineerMaterialRequest,
  UpdateEngineerRequestStatus,
  AddJobNote,
  AddContractorAppointedDetail,
  UpdateContractorAppointedDetail,
  AddHealthAndSafetyAnswer,
  AddVulnerabilityAnswer,
  UpdateEmergencyAcceptedOrRejected,
  SendJobSMS,
  AddComplaint,
  UpdateComplaint,
  AddJobWithPolicyEnquiry,
  UpdateContractorAppointedStatus,
  UpdateJobFNOLCallRecordingUrl,
  UpdateJobNoteCallRecordingUrl,
  UpdateCustomerDetail,
  AddThirdPartyAppointedContractor,
  AddEmergencySoftFixed,
  SendJobEmail,
  UpdateVisitDetailForAcceptRejectJob,
  UpdateEngineerJobVisitStatus,
  AddEngineerVisitComplete,
  UpdateEmergencyFollowOnWork,
  UpdateEngineerJobToComplete,
  AddEngineerExtraTimeRequest,
  AddEngineerRunningLateRequest,
  UpdateCustomerAddressDetail,
  CancelAssignedJobContractor,
  SaveIncomingJobSms,
  AddAccessNoteAnswer,
  UpdateHealthAndSafetyForJob,
  AddEngineerRequest,
  AddEngineerJobVisitNote,
  UpdateInvoiceDetail,
  AddAdditionalCostAuthorisedRequest,
  AddRequestToEscalateCTPProcess,
  OverrideClientInvoiceCost,
  OverrideContractorInvoiceCost,
  AddPictureUpload,
  UpdateClientInvoiceDetailStatus,
  UpdateContractorInvoiceDetailStatus,
  // AddVisitHealthAndSafetyProcess,
  // AddPictureUploadRequest,
  // UpdateVisitCostOverrides,
  // UpdateJobCostOverrides,
  AutoDeployEngineerRequest,
  AddCloseJobRequest,
  AddRequestForCancelJob,
  AssignEngineerRequest,
  AddEscalateJobRequest,
  UploadJobDocument,
  RemoveUploadJobDocument,
  UpdateJobPolicySchedule,
  UpdateJobDocumentMetadata,
  AutoDeployContractorRequest,
  RemoveEmergencyFromJob,
  UpdateJobOffsiteTime,
  UpdateGasSafetyForJob,
  UpdateQuestionModeJobDetails,
  AddComplaintDocuments,
  DeleteComplaintDocument,
  AddReopenComplaintRequest,
  AddCloseComplaintRequest,
  AddComplaintEscalateRequest,
  UpsertSIJobDelay,
  UpdateCCTVControlRunLog,
  UpdateCCTVControlLog,
  UpdateDatumDetails,
  UpdateTrialPitBoreholeLog,
  UpdateSITrialPitDetails,
  AddAbandonedTrialPitRequest, // obsolete
  AddUpdateWaterMainsTest,
  DeleteWaterMainsTest,
  AddSIProductVerificationRequest,
  UploadTrialPitDrawing,
  SaveWaterMainsTest,
  AddSIRevisitRequest,
  DeleteBoreholeLog,
  DeleteCCTVControlRunLog,
  UpdateMaterialCostRequest,
  AddUpdateDrainageReport,
  JobNotReadyToClose,
  AddAbandonedSIProductRequest,
  CompleteWaterMainsTestRecord,
  SaveOtherFeesDetail,
  AcceptProvisionedVisit,
  AcceptProvisionedUSVisit,
  AddCustomerAppointedTradespeopleRequest,
  AddCATInvoiceImage,
  AddMonitoringVisitInvoice,
  AddDeEscalateJobRequest,
  UpsertRepairEstimateRequest,
  AddReopenJobRequest,
}

export enum RecordType {
  Severity,
  PaymentReason,
  ResolutionCode,
  ContractorUnavailableReason,
  ComplaintType,
  DelayCode,
  SoftFixedEmergencyTitle,
  ReturnVisitType,
  NomineeRelation,
  JobCancellationReason,
  JobEscalationReason,
  RemoveEmergencyFromJobReason,
  AdditionalPaymentAuthorizationReason,
  AbandonVisitReason,
  ComplaintRootCause,
  ComplaintEscalationReason,
  ConcernsRaised,
  SIJobDelayReason,
  USJobDelayReason,
  AbandonTrialPitReason,
  AbandonCCTVReason,
  AbandonWaterMainsTestReason,
  AbandonDatumReason,
  JobReopenReason,
}

export enum SearchType {
  Everywhere = 'Everywhere',
  ContractorJobs = 'ContractorJobs',
  ClientJobs = 'ClientJobs',
}

export enum RequestProcessStatus {
  Pending = 0,
  Success = 1,
  Error = 2,
}

export interface IPreferredContractor {
  id: string
  description: string
}

export type PreferredContractor =
  | 'Unverified'
  | 'Approved'
  | 'Preferred'
  | 'Platinum'
  | 'Archived'
  | 'ApplicationPending'
  | 'ApplicationInProgress'
export const PreferredContractors: IPreferredContractor[] = [
  { id: 'Unverified', description: 'Unverified' },
  { id: 'Approved', description: 'Approved' },
  { id: 'Preferred', description: 'Preferred' },
  { id: 'Platinum', description: 'Platinum' },
  { id: 'Archived', description: 'Archived' },
  { id: 'ApplicationPending', description: 'Application Pending' },
  { id: 'ApplicationInProgress', description: 'Application In Progress' },
]

export enum AsbestosEffectLevel {
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum DocumentTemplateType {
  NewJobLogged = 1,
  ContractorAssigned = 2,
  JobCompleted = 3,
  EmergencyAccepted = 4,
  HelplineCompleted = 5,
  EngineerOnTheWay = 6,
  SIVisitBooked = 7,
  EngineerVisitNotification = 8,
  JobClosure = 9,
  JobCompletionRecommendationScore = 10,
  JobCompletionServiceQualityScore = 11,
  JobCompletionQualityComments = 12,
  SendQuestionModePortalLink = 14,
  CustomerAppointedTradespeople = 18,
  FollowOnWork = 19,
  DeploymentDelay = 20,
  PartRequestApproved = 21,
  SetETAOnReturnVisit = 22,
  WorkInProgress = 27,
  EmergencyRejected = 29,
  CancelOnMyWay = 31,
  FNOLEffortScore = 23,
  WIPEffortScore = 24,
  EffortScoreComments = 25,
}

export enum EngineerJobVisitStatus {
  Pending = 1,
  Cancelled = 2,
  Accepted = 3,
  Rejected = 4,
  OnTheWay = 5,
  RunningLate = 6,
  OnSite = 7,
  PolicyHolderAbsent = 8,
  Abandoned = 9,
  JobStarted = 10,
  Completed = 11,
  ReAssigned = 12,
}

export enum DynamicComponents {
  CallPickupRate = 1,
  CurrentCalls = 2,
  CurrentCallsInQueue = 3,
  LongestWait = 4,
  TotalOpenComplaint = 5,
  TotalCloseComplaint = 6,
  TotalOverdueAction = 7,
  SettlementPaidMTD = 8,
  TotalJobsLoggedInLast15Days = 9,
  Top5Emergency = 10,
  IncidentManagerTable = 11,
  OutstandingComplaintsTable = 12,
  JobHistoryTable = 13,
  UnlinkedPhoneCallsTable = 14,
  ManagerSupervisorTable = 15,
  UndeployedJobHistoryTable = 16,
  DashboardInsurerPanel = 17,
  InsurerPortalPanel = 18,
  DashboardContractorPanel = 19,
  ContractorCallsInQueue = 20,
}

export enum ChartType {
  Line,
  Bar,
  Pie,
}

export enum EngineerRequestType {
  Material = 1,
  Time = 2,
  ReturnVisit = 3,
  NewJob = 4,
  PolicyHolderNotPresent = 5,
  RejectVisit = 6,
  AbandonedVisit = 7,
  VisitComplete = 8,
  RunningLate = 9,
}

export enum EngineerRequestStatus {
  Pending = 1,
  Approved = 2,
  Reject = 3,
}

export interface ICountryName {
  id: CountryName
  description: string
}

export type CountryName = 'England' | 'NorthernIreland' | 'Scotland' | 'Wales'
export const CountryNames: ICountryName[] = [
  { id: 'England', description: 'England' },
  { id: 'NorthernIreland', description: 'Northern Ireland' },
  { id: 'Scotland', description: 'Scotland' },
  { id: 'Wales', description: 'Wales' },
]

export interface IDayOfWeekType {
  id: DayOfWeekType
  description: string
}

export type DayOfWeekType = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday'
export const DaysOfWeek: IDayOfWeekType[] = [
  { id: 'Monday', description: 'Monday' },
  { id: 'Tuesday', description: 'Tuesday' },
  { id: 'Wednesday', description: 'Wednesday' },
  { id: 'Thursday', description: 'Thursday' },
  { id: 'Friday', description: 'Friday' },
  { id: 'Saturday', description: 'Saturday' },
  { id: 'Sunday', description: 'Sunday' },
]

export enum IMRequestProcessStatus {
  Pending = 1,
  Assigned = 2,
  Completed = 3,
}

export enum EngineeringRequestCategory {
  NewJob = 1,
  PolicyHolderNotPresent = 2,
  RejectVisit = 3,
  AbandonedVisit = 4,
  Resource = 5,
  VisitComplete = 6,
  RunningLate = 7,
}

export enum EnquiryType {
  Default = 1,
  On = 2,
  Off = 3,
}

export enum JobFinancialSummaryWidget {
  ReadyForApproval,
  Disputed,
  ReadyForInvoice,
  Outstanding,
  ToReview,
}

export enum FinancialRequestStatus {
  Approve = 1,
  Reject = 2,
  ReCalculate = 3,
  ReviewComplete = 4,
}

export enum ContractorFinancialSummaryWidget {
  ReadyForInvoice,
  Disputed,
  Pending,
  ToReview,
}

export enum ContractorInvoiceStatus {
  Pending,
  Disputed,
  Accepted,
  Preapproved,
  ToReview,
}

export enum ClientInvoiceStatus {
  Pending,
  ReadyForApproval,
  Disputed,
  Accepted,
  ToReview,
  ExportedToBordereau,
}

export enum ComplaintContactPreferenceEnum {
  SMS = 1,
  Email = 2,
  Call = 3,
  Post = 4,
  Client = 5,
}

export enum SIProductType {
  Test = 1,
  Pit = 2,
  Other = 3,
  CCTV = 4,
  Datum = 5,
  Monitoring = 6,
  BulkPit = 7,
  WaterMains = 8,
  TestingOnly = 9,
}

export enum SIJobProductProgress {
  NotStartedYet = 1,
  InProgress = 2,
  Finished = 3,
  Abandoned = 4,
}

export type JobType = 'HE' | 'US' | 'SI'

export enum EscalationTypeEnum {
  All,
  Escalated,
  Standard,
}

export enum ReportStatus {
  New = 'New',
  BuildingReport = 'BuildingReport',
  ReportBuilt = 'ReportBuilt',
  ReportFailed = 'ReportFailed',
  ReportSent = 'ReportSent',
}

export enum UploadedDocumentTypeEnum {
  Other = 0,
  ReportCover = 1,
  SitePlan = 2,
  TrialPit = 3,
  Borehole = 4,
  LabSummary = 5,
  TreeRoots = 6,
  DrainageEstimate = 7,
  DrainagePack = 8,
  SIReport = 9,
}

export enum TestingDocumentTypeEnum {
  LabSummary = UploadedDocumentTypeEnum.LabSummary,
  TreeRoots = UploadedDocumentTypeEnum.TreeRoots,
}

export enum AutoDeploymentTypeEnum {
  NoAutodeployment = 1,
  AutodeployToEngineer = 2,
  AutodeployToContractor = 3,
}

export enum VulnerabilityLevelEnum {
  All,
  None,
  Low,
  Medium,
  High,
}

export enum WIPDashboardSortingTypeEnum {
  EtaStart,
  EtaFinish,
  SchemePriorityLowestToHighest,
  SchemePriorityHighestToLowest,
}

export type SiteDataRecordType = null | 'SIVisitTrialPitRecord' | 'CCTVControlLog'

export enum VisitTypeEnum {
  AllVisit,
  FirstVisit,
  ReturnVisit,
}

export enum CompanyType {
  PLC,
  LTD,
  Partnership,
  SoleTrader,
}

export enum PostVisitActionsTypeEnum {
  All = 'All',
  TrialPitOutstanding = 'Trial Pit Outstanding',
  CCTVOutstanding = 'CCTV Outstanding',
  DatumOutstanding = 'Datum Outstanding',
  WaterMainsOutstanding = 'Water Mains Outstanding',
  TreeRootsOutstanding = 'Tree Roots Outstanding',
  LabSummaryOustanding = 'Lab Summary Outstanding',
  Verified = 'Verified',
}

export enum NotificationType {
  SMS = 1,
  MissedCall = 2,
  ETAChanged = 3,
  MaterialRequestApproved = 4,
  EmergencyPendingAcceptReject = 5,
  BordereauReady = 6,
  NewReturnVisitAwaitingETA = 7,
  MaterialRequestWithoutPrice = 8,
}

export enum CommodityWorkType {
  Response = 0,
  Repair = 1,
}

export enum InvoiceStatus {
  Unpaid,
  Paid,
  PartlyPaid,
}

export enum USJobStatus {
  New = 0,
  Delay = 1,
  VisitBooked = 2,
  AwaitingReport = 3,
  AwaitingClosure = 4,
  Closed = 5,
}

export enum JobDateFilterType {
  TodayJobs,
  FutureJobs,
  NoETASetJobs,
}

export enum ContractorVisitTypeEnum {
  AllVisit,
  FirstVisit,
  ReturnVisit,
  VisitWithoutETA,
}

export enum ContractorTradeStatus {
  Approved = 'Approved',
  Preferred = 'Preferred',
  Platinum = 'Platinum',
}

export enum ETAFilterForMissedJob {
  AllOpenJobs,
  MissedJobs,
}

export enum InvoiceCostType {
  Labour = 1,
  Material = 2,
  Travel = 3,
  HandlingFee = 4,
}

export enum SIJobType {
  SIOnly = 'SIOnly',
  SIWithMonitoring = 'SIWithMonitoring',
  MonitoringOnly = 'MonitoringOnly',
}

export enum AssistedPayCaptureType {
  CardNumber = 'payment-card-number',
  ExpiryDate = 'expiration-date',
  CVC = 'security-code',
}

export enum AssistedPayAccessibility {
  Optional,
  Forced,
}

export enum AssistedPayMessageType {
  PaymentSid = 'PaymentSid',
  CardNumber = 'CardNumber',
  ExpiryDate = 'ExpiryDate',
  CVC = 'CVC',
  PaymentToken = 'PaymentToken',
  Cancelled = 'Cancelled',
}

export enum TemplateStatus {
  NotSet = 'NotSet',
  Online = 'Online',
  TemporaryOffline = 'TemporaryOffline',
  Deprecated = 'Deprecated',
}
