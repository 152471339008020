<template>
  <div class="policy-selector-content">
    <treeselect
      v-model="value"
      class="tree-select"
      :disable-branch-nodes="true"
      :show-count="true"
      placeholder="Select Policy..."
      aspect-ratio="1"
      :options="convertPoliciesIntoTree"
      :pre-selected-policy="preSelectedPolicy"
      @input="policySelected"
    >
      <label
        slot="option-label"
        slot-scope="{ node, count, labelClassName }"
        :class="labelClassName"
        style="height: 48px"
      >
        <div class="one-line">
          <pre class="result">{{ node.label }}</pre>
          <v-chip
            v-if="isDefaultPolicy(node)"
            color="secondary"
            class="white--text"
            style="font-weight: small"
            small
            disabled
          >
            Default
          </v-chip>
          <v-icon v-if="node.isRootNode">business</v-icon>
          <v-img
            v-else-if="!node.isLeaf && node.raw.info.LogoURL"
            :src="node.raw.info.LogoURL"
            :contain="true"
            position="right"
            max-height="30"
          ></v-img>
          <v-icon v-else-if="!node.isLeaf">branding_watermark</v-icon>
          <v-img
            v-else-if="node.isLeaf && node.raw.info.fileURL"
            :src="node.raw.info.fileURL"
            :contain="true"
            position="right"
            max-height="30"
          ></v-img>
        </div>
      </label>
    </treeselect>
    <v-alert
      v-if="!value && !(jobType === 'US')"
      :value="true"
      color="warning"
      icon="priority_high"
      outline
      class="pa-0 waring-maping"
      style="border: 0px !important"
    >
      Please select a Policy
    </v-alert>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { InsurerBrandList, SelectedPolicy } from '@/models/policy/PolicyLists'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PolicyTree from '@/models/policy/PolicyTree'
import { JobType } from '@/models/types'

@Component({
  name: 'PolicySelector',
  components: { Treeselect },
})
export default class PolicySelector extends Vue {
  // you can pass a pre selected or partially selected policy to this, will default to manually select.
  public components: { Treeselect }
  @Prop()
  public selectedPolicy: SelectedPolicy
  public value: any = null
  // error messages for the select
  @Prop()
  private errorMessages: string[]
  private treeSelectTree: PolicyTree[] = []
  @Prop()
  private policyTree: InsurerBrandList[]

  @Prop()
  private preSelectedPolicy: null | number
  @Prop() private jobType: JobType
  @Prop({ default: false }) private bindOnlyStarredPolicies: boolean

  private tmpSelection: SelectedPolicy = {
    insurer: null,
    brand: null,
    policy: null,
  }

  public setDefaultValues() {
    this.value = null
  }

  private mounted() {
    this.setValue()
  }

  private async setValue(): Promise<void> {
    if (this.preSelectedPolicy) {
      for (const tree of this.treeSelectTree) {
        const result = this.searchForPolicyId(tree, this.preSelectedPolicy)
        if (result.length > 0) {
          this.value = result
          break
        }
      }
    }
  }

  private get convertPoliciesIntoTree(): any[] {
    const completePolicyTree: PolicyTree[] = []

    this.policyTree.forEach((insurer) => {
      const insurerTree: PolicyTree = {
        id: 'insurer:' + insurer.id.toString(),
        label: insurer.name,
        children: [],
        info: insurer,
      }
      const secondLevelTree: PolicyTree[] = []

      const brands = insurer.brands || []

      if (brands.length !== 1 && brands[0].displayName !== 'Unbranded') {
        brands.forEach((brand) => {
          const brandTree: PolicyTree = {
            id: 'brand:' + insurer.id + '/' + brand.id,
            label: brand.displayName,
            children: [],
            info: brand,
          }
          const policiesTree: PolicyTree[] = []
          brand.policies.forEach((policy) => {
            const policySubTree: PolicyTree = {
              id: 'policy:' + insurer.id + '/' + brand.id + '/' + policy.id.toString(),
              label: policy.name,
              info: policy,
            }
            if ((this.bindOnlyStarredPolicies && policy.isStarred) || !this.bindOnlyStarredPolicies) {
              policiesTree.push(policySubTree)
            }
          })
          brandTree.children = policiesTree

          secondLevelTree.push(brandTree)
        })
      } else {
        // add policies to the second level if they are unbranded
        brands[0].policies.forEach((policy) => {
          const policySubTree: PolicyTree = {
            id: 'policy:' + insurer.id + '/UNBRANDED/' + policy.id.toString(),
            label: policy.name,
            info: policy,
          }
          if ((this.bindOnlyStarredPolicies && policy.isStarred) || !this.bindOnlyStarredPolicies) {
            secondLevelTree.push(policySubTree)
          }
        })
      }
      insurerTree.children = secondLevelTree
      completePolicyTree.push(insurerTree)
    })
    this.treeSelectTree = completePolicyTree
    return completePolicyTree
  }

  private isDefaultPolicy(node: any): boolean {
    if (node.isLeaf && node.parentNode.raw.info.FallBackStarPolicy && node.raw.info.id) {
      return node.parentNode.raw.info.FallBackStarPolicy === node.raw.info.id
    } else {
      return false
    }
  }

  private policySelected(Id: string) {
    const result = this.searchPolicy(Id)
    if (result && result.parents && result.res) {
      if (result.parents[0]) {
        let insurer: any | null = null
        let brand: any | null = null
        let policy: any | null = null

        if (result.parents[0].info) {
          insurer = result.parents[0].info
          this.tmpSelection.insurer = {
            id: insurer.id,
            name: insurer.name,
          }
        }
        if (result.parents.length > 1 && result.parents[1].info) {
          brand = result.parents[1].info
          this.tmpSelection.brand = {
            id: brand.id,
            name: brand.DisplayName,
            defaultPolicy: brand.FallBackStarPolicy,
          }
        }
        if (result.res.info) {
          policy = result.res.info
          this.tmpSelection.policy = {
            id: policy.id,
            name: policy.name,
          }
        }
        this.$emit('update:selectedPolicy', JSON.parse(JSON.stringify(this.tmpSelection)))
      }
    } else {
      this.$emit('update:selectedPolicy', undefined)
    }
  }
  private searchPolicy(Id: string): {
    res: null | PolicyTree
    parents: null | PolicyTree[]
  } {
    let res: null | PolicyTree = null
    let parents: null | PolicyTree[] = null
    for (const tree of this.treeSelectTree) {
      const searchOfInsurer = this.searchTree(tree, Id, [])
      if (searchOfInsurer.e) {
        res = searchOfInsurer.e
        parents = searchOfInsurer.p
        return { res, parents }
      }
    }

    return { res, parents }
  }
  private searchForPolicyId(element: PolicyTree, matchingId: number): string {
    if (element.info && element.info.id === matchingId) {
      return element.id
    } else if (element.children) {
      let result = ''
      for (const child of element.children) {
        result = this.searchForPolicyId(child, matchingId)
        if (result.length > 0) {
          break
        }
      }

      return result
    }
    return ''
  }
  private searchTree(
    element: PolicyTree,
    matchingId: string,
    parents: PolicyTree[]
  ): { e: PolicyTree | null; p: PolicyTree[] | null } {
    if (element.id === matchingId) {
      return { e: element, p: parents }
    } else if (element.children) {
      let result: { e: PolicyTree | null; p: PolicyTree[] | null } = {
        e: null,
        p: null,
      }
      for (const child of element.children) {
        result = this.searchTree(child, matchingId, parents.concat(element))
        if (result.e && result.p) {
          break
        }
      }

      return result
    }
    return { e: null, p: null }
  }
}
</script>

<style>
.one-line {
  justify-content: space-between;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vue-treeselect__control {
  background-color: transparent !important;
  border-radius: 0px !important;
  border-bottom-width: 0.5px !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0px;
}

.v-icon material-icons theme--light v-alert__icon {
  margin-right: 16px;
}

.tree-select {
  font-size: 16px;
  margin: 0;
  border-style: none;
  padding: 0px;
}
.vue-treeselect__input-container,
.vue-treeselect__single-value {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.v-alert__icon {
  margin-right: 0px;
}
.maping-alert {
  position: relative;
  margin-top: 5px;
}
.vue-treeselect__placeholder {
  padding: 0px !important;
  font-family: 'Noto Sans', Avenir, Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  height: 32px;
  font-weight: 400;
  text-align: start;
  text-indent: 0px;
}
.maping-alert >>> .alert .alert__icon.v-icon {
  width: 0px;
}

.maping-alert >>> .v-alert {
  border: 0px !important;
  position: relative;
  top: -5px;
}
.waring-maping {
  position: absolute !important;
  left: -10px;
  bottom: -30px;
}
.policy-selector-content {
  position: relative;
}
</style>
