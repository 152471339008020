<template>
  <div>
    <v-dialog v-model="confirmCancelDialog" width="500">
      <v-card>
        <v-card-text> Are you sure you want to cancel? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="brandColour" class="white--text" @click="confirmCancelDialog = false">No</v-btn>
          <v-btn color="error" class="white--text" @click="cancelInvoice"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-select
      v-model="selectedPaymentTerm"
      v-validate="'required'"
      label="Set Payment Terms"
      :items="paymentTermOptions"
      data-vv-scope="sendInvoiceValidator"
      required
      data-vv-name="Payment Terms"
      :error-messages="errors.collect('Payment Terms')"
    ></v-select>
    <p class="text-left font-weight-bold mt-3">Payment Script</p>
    <p class="text-left font-italic mb-3"
      >As we have been unable to take a card payment, I will now issue an invoice to the email address you have
      provided. The invoice can be paid securely using a debit or credit card, Apple Pay, or Google Pay. Please note
      that we may not be able to proceed with the scheduled work until the payment has been received. Are you happy for
      me to issue this invoice?</p
    >
    <input
      id="confirm-checkbox"
      v-validate="'required'"
      name="Confirmation"
      type="checkbox"
      class="confirm-checkbox required"
      :checked="confirmationSelected"
      data-vv-scope="sendInvoiceValidator"
      required
      data-vv-name="Confirmation"
    />
    <label for="confirm-checkbox" class="invoice-text">
      I confirm that I have read the above to the customer and have been authorized to send the invoice via email</label
    >
    <div v-if="errors.collect('Confirmation').length > 0" class="error-text red--text">
      {{ errors.collect('Confirmation')[0] }}
    </div>
    <div v-if="store.invoiceError" class="red--text text-center my-2">
      <p>{{ store.invoiceError.description }}</p>
      <p>{{ store.invoiceError.nextSteps }}</p>
    </div>

    <v-layout class="justify-end mt-2">
      <v-flex xs4 class="d-flex justify-end">
        <v-btn color="error" class="white--text" :disabled="store.isLoading" @click="confirmCancelDialog = true"
          >Cancel</v-btn
        >
        <v-btn
          :color="brandColour"
          class="white--text"
          :disabled="store.isLoading"
          :loading="store.isLoading"
          @click="onSendInvoicePress"
          >Send</v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { colours } from '@/common/themes'
import Job from '@/models/Job'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class PaymentInvoice extends Vue {
  @Prop() public callSid: string | null
  @Prop() private job: Job | null

  public store = useCustomerToPayStore()
  public paymentTermOptions = ['Today', 'Tomorrow', '7 days', '14 days', '30 days', '45 days', '60 days', '90 days']
  public selectedPaymentTerm = 'Tomorrow'
  public brandColour = colours.brand
  public confirmationSelected = false
  public confirmCancelDialog = false

  public async onSendInvoicePress() {
    const result = await this.$validator.validateAll('sendInvoiceValidator')

    if (!result || !this.job) return

    this.store.createInvoice(this.job, this.callSid)
  }

  public cancelInvoice() {
    this.confirmCancelDialog = false
    this.$emit('cancelInvoice')
  }
}
</script>

<style scoped>
.confirm-checkbox {
  vertical-align: middle;
}

.invoice-text {
  font-size: 0.75em;
  vertical-align: middle;
}

.error-text {
  font-size: 0.75em;
}

.prepend-label:deep(label) {
  order: -1;
  margin-right: 0.5em;
}
</style>
