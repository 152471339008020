import TimeLineCardModel from '@/models/TimeLineCardModel'
import Job from '@/models/Job'
import { TimeLineHelper } from './TimeLineHelper'
import Shared from '@/common/shared'
import moment, { Moment } from 'moment'
import { JobVisitStatus, EngineerJobVisitStatus, SIJobProductProgress, ReportStatus } from '@/common/enums'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import storeGetters from '@/storeGetters'
import EmailModel from '@/models/claim/EmailModel'
import ReportDetail from '@/models/ReportDetail'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import JobNoteModel from '@/models/claim/JobNoteModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import { useLaunchDarkly } from '@/plugins/launch-darkly'
import { useTimelineStore } from '@/pinia/timeline'
import { generateNonLegacyCard } from '@/lib/timeline'

class SITimeLineHelper extends TimeLineHelper {
  public generateCards(job: Job): TimeLineCardModel[] {
    const cards: TimeLineCardModel[] = []

    // fnol card
    cards.push(
      new TimeLineCardModel({
        title: 'New Job',
        colour: 'purple',
        icon: 'email',
        createdDate: job.createdAt,
        forJobId: job.id,
        cardBodyComponent: 'FNOLCard',
        detailComponent: 'FNOLPreview',
        cardFooterComponent: '',
      })
    )

    if (job.isIVSJob) {
      if (job.sIJobDetail && !job.sIJobDetail.jobPackage) {
        const ivsStatus = job.ivsDetail
          ? job.ivsDetail.status
          : SIJobProductProgress[SIJobProductProgress.NotStartedYet]
        cards.push(
          new TimeLineCardModel({
            title: 'IVS',
            status: ivsStatus,
            colour: 'blue',
            icon: 'videocam',
            createdDate: job.createdAt,
            forJobId: job.jobId,
            cardBodyComponent: 'IVSProcessCard',
            detailComponent:
              ivsStatus === SIJobProductProgress[SIJobProductProgress.Finished]
                ? 'IVSCompletePreview'
                : 'IVSProcessPreview',
            cardFooterComponent: '',
          })
        )
      }
      if (
        (job.ivsDetail && job.ivsDetail.status !== SIJobProductProgress[SIJobProductProgress.Finished]) ||
        (job.sIJobDetail && !job.sIJobDetail.jobPackage)
      ) {
        this.bindJobCompletionCards(job, cards)
        return cards
      } else if (job.ivsDetail && job.ivsDetail.status === SIJobProductProgress[SIJobProductProgress.Finished]) {
        cards.push(
          new TimeLineCardModel({
            title: 'IVS',
            status: job.ivsDetail.status,
            colour: 'blue',
            icon: 'videocam',
            createdDate: job.createdAt,
            forJobId: job.jobId,
            cardBodyComponent: 'IVSProcessCard',
            detailComponent: 'IVSProcessPreview',
            cardFooterComponent: '',
          })
        )
      }
    }

    cards.push(
      new TimeLineCardModel({
        title: 'Job Package',
        colour: 'blue',
        icon: 'description',
        createdDate: job.createdAt,
        forJobId: job.id,
        cardBodyComponent: 'JobPackageCard',
        cardFooterComponent: 'JobPackageFooter',
        detailComponent: 'JobPackagePreview',
        cardClass: '',
      })
    )

    // Job Note Card
    if (job.jobNotes && job.jobNotes.length > 0) {
      job.jobNotes.forEach((note: JobNoteModel) => {
        cards.push(this.bindJobNoteCard(job, note))
      })
    }

    if (!job.isTestingOnlyJob) {
      if (job.monitoringDetail) {
        cards.push(
          new TimeLineCardModel({
            title: 'Monitoring Detail',
            colour: 'blue',
            icon: 'description',
            createdDate: job.createdAt,
            forJobId: job.id,
            cardBodyComponent: 'MonitoringDetailCard',
            cardFooterComponent: '',
            detailComponent: 'MonitoringDetailPreview',
            cardClass: '',
          })
        )
      }

      if (this.isAllProductTypeMonitoring(job)) {
        return cards
      }

      job.emergencies
        .filter((e) => e.displayInTimeline)
        .forEach((emergency) => {
          const emergencyDate = new Date(emergency.createdAt.toString())
          // bind pending or cancelled trade appointed cards
          const appointedGeneralContractors: ContractorAppointedModel[] = job.contractorAppointed.filter(
            (c) => c.emergencyId === emergency.id && c.status !== JobVisitStatus[JobVisitStatus.Unavailable]
          )
          const sortedContractors = Shared.sortArrayByDate(appointedGeneralContractors, 'createdAt')
          sortedContractors.forEach((appointedContractor) => {
            if (
              appointedContractor.status !== JobVisitStatus[JobVisitStatus.AutoDeploying] ||
              this.isLegacyAutoDeploymentEnabled
            )
              cards.push(
                new TimeLineCardModel({
                  title: appointedContractor.companyName,
                  status: appointedContractor.status,
                  colour: appointedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled] ? 'red' : 'green',
                  icon: appointedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled] ? 'block' : 'check',
                  createdDate: appointedContractor.createdAt,
                  forJobId: appointedContractor.jobId,
                  forItemId: appointedContractor.id,
                  forEmergencyId: appointedContractor.emergencyId,
                  cardBodyComponent: 'TradeAppointedCard',
                  detailComponent: 'TradeAppointedPreview',
                  cardClass: '',
                })
              )
          })

          if (
            !appointedGeneralContractors.find(
              (c) =>
                c.status === JobVisitStatus[JobVisitStatus.Pending] ||
                c.status === JobVisitStatus[JobVisitStatus.Unavailable] ||
                c.status === JobVisitStatus[JobVisitStatus.InProgress] ||
                c.status === JobVisitStatus[JobVisitStatus.Completed] ||
                c.status === JobVisitStatus[JobVisitStatus.Accepted] ||
                c.status === JobVisitStatus[JobVisitStatus.AutoDeploying]
            ) &&
            !(job.cancellationReason || job.status === 'Cancelled' || job.status === 'Completed')
          ) {
            cards.push(
              new TimeLineCardModel({
                title: '',
                colour: 'yellow',
                icon: 'call',
                createdDate: this.getCallContractorCardDate(job, emergencyDate),
                forJobId: job.id,
                forItemId: '',
                forEmergencyId: emergency.id,
                cardBodyComponent: 'CallContractorCard',
                detailComponent: 'CallContractorPreview',
                cardClass: 'flatCard',
              })
            )
          }

          if (job.contractorAppointed.length) {
            cards.push(
              new TimeLineCardModel({
                title: 'Questions',
                colour: 'yellow',
                icon: 'forum',
                createdDate: this.getQuestionsCreatedDate(job),
                forJobId: job.jobId,
                forEmergencyId: emergency.id,
                cardBodyComponent: 'SiUsJobQuestionsCard',
                detailComponent: 'SiUsJobQuestionsPreview',
                cardFooterComponent: '',
              })
            )

            // VulnerabilityQA Card
            if (job.vulnerabilityQA && emergency.isEmergencyAccepted) {
              cards.push(
                new TimeLineCardModel({
                  title: 'Vulnerability Question',
                  colour: 'yellow',
                  icon: 'forum',
                  createdDate: this.getQuestionsCreatedDate(job).add(5, 'seconds'),
                  forJobId: job.vulnerabilityQA.jobId,
                  forItemId: job.vulnerabilityQA.id,
                  cardBodyComponent: 'VulnerabilityQACard',
                  detailComponent: 'VulnerabilityQAPreview',
                  cardFooterComponent: '',
                })
              )
            }
          }
          if (emergency.isEmergencyAccepted) {
            cards.push(
              new TimeLineCardModel({
                title: 'Enablement',
                colour: 'red',
                icon: 'flag',
                createdDate: this.getEnablementNotesDate(job),
                forJobId: job.jobId,
                forEmergencyId: emergency.id,
                cardBodyComponent: 'EnablementNotesCard',
                detailComponent: 'EnablementNotesPreview',
                cardFooterComponent: '',
              })
            )

            let showOtherCards: boolean = false
            if (job.enablementTriggeredAt !== null) {
              if (job.enablementCompletedAt !== null) {
                showOtherCards = true
              }
            } else {
              showOtherCards = true
            }

            if (showOtherCards) {
              // Show Confirm Date card when there is pending or accepted contractor available and all emergencies have been accepted
              job.contractorAppointed
                .filter((cad) => {
                  return (
                    cad.status === JobVisitStatus[JobVisitStatus.Pending] ||
                    cad.status === JobVisitStatus[JobVisitStatus.Accepted]
                  )
                })
                .forEach((contractor) => {
                  cards.push(
                    new TimeLineCardModel({
                      title: 'Confirm Date',
                      colour: 'green',
                      icon: 'info',
                      createdDate: this.getEnablementNotesDate(job).add(10, 'seconds'),
                      forJobId: job.id,
                      forEmergencyId: emergency.id,
                      forItemId: contractor.id,
                      status: contractor.status,
                      cardBodyComponent: 'ConfirmDateCard',
                      detailComponent: 'ConfirmDatePreview',
                    })
                  )
                })

              // bind Engineer Visit Detail
              if (job.engineerVisitDetail.length > 0) {
                job.engineerVisitDetail.forEach((element: EngineerVisitDetail) => {
                  if (
                    element.forEmergencyTypeId === emergency.typeId &&
                    element.forEmergencyDetailId === emergency.detailId
                  ) {
                    cards.push(this.bindEngineerVisitCard(element, emergency, job.jobType))
                  }

                  // bind site data card
                  if (element.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed]) {
                    cards.push(this.bindSiteDataCard(job, element))
                  }
                })
                const lastVisit = job.engineerVisitDetail[job.engineerVisitDetail.length - 1]
                if (
                  lastVisit.isEngineerReAttend ||
                  lastVisit.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed]
                ) {
                  const testingCardCreatedDate = moment(lastVisit.createdAt).add(5, 'seconds')
                  cards.push(this.bindTestingCard(job.id, testingCardCreatedDate))

                  const testingProgress = storeGetters.getTestingProgress(job.jobId)
                  if (!testingProgress || testingProgress.testingComplete) {
                    const reportCardCreatedDate = moment(lastVisit.createdAt).add(10, 'seconds')
                    cards.push(this.bindBuildReportCard(job.id, reportCardCreatedDate))
                  }
                }
              }
            }
          }
        })
    }

    if (job.isTestingOnlyJob) {
      const testingCardCreatedDate = moment(job.createdAt).add(5, 'seconds')
      cards.push(this.bindTestingCard(job.id, testingCardCreatedDate))

      const testingProgress = storeGetters.getTestingProgress(job.jobId)
      if (!testingProgress || testingProgress.testingComplete) {
        const reportCardCreatedDate = moment(job.createdAt).add(10, 'seconds')
        cards.push(this.bindBuildReportCard(job.id, reportCardCreatedDate))
      }
    }

    // Email Card
    if (job.Emails && job.Emails.length > 0) {
      job.Emails.forEach((email: EmailModel) => {
        cards.push(this.addEmailCardToList(email))
      })
    }

    if (job.siJobDelay) {
      cards.push(this.bindJobDelayCard(job, job.siJobDelay))
    }

    this.bindJobCompletionCards(job, cards)

    // Complaint Card
    if (job.complaint && job.complaint.length > 0) {
      job.complaint.forEach((complaint: ComplaintModel) => {
        cards.push(this.bindComplaintCard(job, complaint))
      })
    }

    // Customer To Pay Card
    const ldClient = useLaunchDarkly()
    if (job.customerToPay && job.customerToPay.length > 0 && !ldClient.variation('fnol-540-payments-timeline-card')) {
      job.customerToPay.forEach((element: CustomerToPayModel) => {
        if (element.emergencyId === job.emergencies[0].id) {
          cards.push(this.bindCustomerToPayCard(job.jobId, element))
        }
      })
    }

    // Add the non legacy timeline cards which are data driven.
    useTimelineStore().cards.forEach((card) => {
      cards.push(...generateNonLegacyCard(card))
    })

    return cards
  }

  public displaySelectedEmergencyCard(job: Job, card: any) {
    let result: boolean = false
    if (
      card.cardBodyComponent === 'FNOLCard' ||
      card.cardBodyComponent === 'CallContractorCard' ||
      card.cardBodyComponent === 'TradeAppointedCard' ||
      card.cardBodyComponent === 'JobPackageCard' ||
      card.cardBodyComponent === 'TestingCard' ||
      card.cardBodyComponent === 'ConfirmDateCard' ||
      card.cardBodyComponent === 'EngineerVisitCard' ||
      card.cardBodyComponent === 'EmailCard' ||
      card.cardBodyComponent === 'VulnerabilityQACard' ||
      card.cardBodyComponent === 'IVSProcessCard' ||
      card.cardBodyComponent === 'CustomerToPayCard' ||
      card.cardBodyComponent === 'JobNoteCard' ||
      card.cardBodyComponent === 'ComplaintCard' ||
      (card.cardBodyComponent === 'SiUsJobQuestionsCard' && job.contractorAppointed.length > 0)
    ) {
      result = true
    } else {
      if (card.cardBodyComponent === 'EnablementNotesCard') {
        const isEmergencyAccepted = job.emergencies.find((e) => e.isEmergencyAccepted)
        if (isEmergencyAccepted) {
          result = true
        }
      } else if (card.cardBodyComponent === 'SiteDataCard') {
        result = this.showSiteDataCard(job)
      } else {
        result = true
      }
    }
    return result
  }

  public showCompleteJobConfirmationCard(job: Job): boolean {
    if (job && job.status === 'Completed') {
      return false
    } else if (job && job.cancellationReason) {
      // check if job status is cancelled or cancellation reason is added
      return job.status === 'Cancelled' ? false : true
    } else {
      const reportDetail: ReportDetail | null = storeGetters.getReportDetail(job.jobId)
      return reportDetail &&
        (reportDetail.reportStatus === ReportStatus.ReportBuilt ||
          reportDetail.reportStatus === ReportStatus.ReportSent)
        ? true
        : false
    }
  }

  public getQuestionsCreatedDate(job: Job): Moment {
    const dates: moment.Moment[] = job.contractorAppointed
      .filter((c) => c.status !== JobVisitStatus[JobVisitStatus.Unavailable])
      .map((x) => x.createdAt)
    return moment(moment.max(dates)).add(10, 'seconds')
  }

  public getCallContractorCardDate(job: Job, emergencyDate: Date): Moment {
    if (job.contractorAppointed.length) {
      const dates: moment.Moment[] = job.contractorAppointed
        .filter((c) => c.status !== JobVisitStatus[JobVisitStatus.Unavailable])
        .map((x) => x.createdAt)
      return moment(moment.max(dates)).add(5, 'seconds')
    }
    return moment(emergencyDate).add(5, 'seconds')
  }

  public getEnablementNotesDate(job: Job): Moment {
    return this.getQuestionsCreatedDate(job).add(5, 'seconds')
  }

  public isAllProductTypeMonitoring(job: Job): boolean {
    if (job.sIJobDetail && job.sIJobDetail.jobPackage) {
      if (
        job.sIJobDetail.jobPackage.packageInfo &&
        job.sIJobDetail.jobPackage.packageInfo.productList &&
        job.sIJobDetail.jobPackage.packageInfo.productList.length
      ) {
        return false
      }
      if (job.sIJobDetail.jobPackage.bulkPitProducts && job.sIJobDetail.jobPackage.bulkPitProducts.length) {
        return false
      }
      if (job.sIJobDetail.jobPackage.extraProducts && job.sIJobDetail.jobPackage.extraProducts.length) {
        return false
      }
      return true
    }
    return false
  }

  // show site data card
  private showSiteDataCard(job: Job): boolean {
    if (job && job.engineerVisitDetail.length > 0) {
      let isSiteDataLoaded: boolean = false
      for (const visit of job.engineerVisitDetail) {
        if (job.siTrialPitDetail && job.siTrialPitDetail.length > 0) {
          if (job.siTrialPitDetail.findIndex((e) => e.forEngineerVisitDetailId === visit.id) !== -1) {
            isSiteDataLoaded = true
            break
          }
        } else if (job.cctvControlLog !== null) {
          if (job.cctvControlLog.forEngineerVisitDetailId === visit.id) {
            isSiteDataLoaded = true
            break
          }
        } else if (job.waterMainsTest !== null) {
          if (job.waterMainsTest.engineerVisitDetailId === visit.id) {
            isSiteDataLoaded = true
            break
          }
        } else if (job.datumControlLogs && job.datumControlLogs.length > 0) {
          if (job.datumControlLogs.findIndex((e) => e.engineerVisitDetailId === visit.id) !== -1) {
            isSiteDataLoaded = true
            break
          }
        }
      }
      return isSiteDataLoaded
    }
    return false
  }

  private bindTestingCard(jobId: string, createdAtDate: Moment) {
    return new TimeLineCardModel({
      title: 'Testing',
      colour: 'yellow',
      icon: 'scatter_plot',
      createdDate: createdAtDate,
      forJobId: jobId,
      cardBodyComponent: 'TestingCard',
      detailComponent: 'TestingPreview',
      cardFooterComponent: '',
    })
  }

  private bindBuildReportCard(jobId: string, createdAtDate: Moment) {
    return new TimeLineCardModel({
      title: 'Build Report',
      colour: 'green',
      icon: 'file_copy',
      createdDate: createdAtDate,
      forJobId: jobId,
      cardBodyComponent: 'BuildReportCard',
      detailComponent: 'BuildReportPreview',
      cardFooterComponent: '',
    })
  }

  private bindJobCompletionCards(job: Job, cards: TimeLineCardModel[]) {
    // add card to allow user to complete the job
    if (this.showCompleteJobConfirmationCard(job)) {
      cards.push(this.bindJobCompleteConfirmationCard(job))
    }

    // Job Complete Card
    if (job.status === 'Completed' || job.status === 'Cancelled') {
      cards.push(this.bindJobCompleteCard(job))
      // Job Financial Summary Card
      cards.push(this.bindJobFinancialSummaryCard(job))
    }
  }

  private get isLegacyAutoDeploymentEnabled(): boolean {
    const ldClient = useLaunchDarkly()
    return ldClient !== undefined ? !ldClient.variation('fnol-401-multiple-contractor-notifications') : false
  }
}

const siTimeLineHelper: SITimeLineHelper = new SITimeLineHelper()
export default siTimeLineHelper
