<template>
  <v-container v-if="twilio.inCall && !twilio.callAlert" class="wrapper px-2 py-2">
    <v-layout row>
      <h5>Current Call</h5>
    </v-layout>
    <v-layout row class="px-2 py-2">
      <v-layout column>
        <div class="text-right mr-2">Phone Number</div>
        <div class="text-right mr-2">Policy</div>
      </v-layout>
      <v-layout column>
        <div class="font-weight-bold">{{ twilio.callingLabel }}</div>
        <div class="font-weight-bold">{{ twilio.callPolicyName }}</div>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { useTwilioStore } from '@/pinia/twilio/twilio'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CurrentCallInfo extends Vue {
  public readonly twilio = useTwilioStore()
}
</script>

<style css scoped>
.wrapper {
  background: white;
  position: absolute;
  bottom: 44px;
  right: 140px;
  border: 3px solid grey;
  text-align: left;
  line-height: 1.75;
  padding: 0;
  min-width: 300px;
  width: auto;
  border-radius: 10px;
  z-index: 999;
}
</style>
