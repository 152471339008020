<template>
  <div
    v-bar="{ useScrollbarPseudo: true }"
    style="height: 100%"
    :class="activeCardIndex > -1 ? 'side-bar-active' : 'vb'"
  >
    <v-layout wrap class="pr-4 additional-chips">
      <template v-for="(card, i) in cardList">
        <TimeLineCard
          v-if="displaySelectedEmergencyCard(card)"
          :key="i"
          :model="card"
          :selected="activeCardIndex === i"
          @click.native="timeLineCardClicked(i)"
        >
          <template v-if="card.cardAdditionalChipsComponent" slot="additional-chips">
            <component
              :is="card.cardAdditionalChipsComponent"
              :job-id="card.forJobId"
              :emergency-id="card.forEmergencyId"
              :item-id="card.forItemId"
              @cardClassChanged="(nc) => cardClassChanged(i, nc)"
            />
          </template>
          <template slot="body">
            <component
              :is="card.cardBodyComponent"
              :job-id="card.forJobId"
              :emergency-id="card.forEmergencyId"
              :item-id="card.forItemId"
              :card-index="i"
              @cardClicked="timeLineCardClicked"
            />
          </template>
          <template v-if="card.cardFooterComponent" slot="footer">
            <component
              :is="card.cardFooterComponent"
              :job-id="card.forJobId"
              :emergency-id="card.forEmergencyId"
              :item-id="card.forItemId"
            />
          </template>
        </TimeLineCard>
      </template>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'
import store from '@/store'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import {
  JobVisitStatus,
  JobNoteType,
  EngineerJobVisitStatus,
  EngineeringRequestCategory,
  ReportStatus,
} from '@/common/enums'
import TimeLineCard from '@/components/TimeLineCard.vue'
import FNOLCard from '@/components/timeline/FNOLCard.vue'
import SoftFixCard from '@/components/timeline/SoftFixCard.vue'
import SoftFixFooter from '@/components/timeline/SoftFixFooter.vue'
import FNOLCardFooter from '@/components/timeline/FNOLCardFooter.vue'
import EmergencyQACard from '@/components/timeline/EmergencyQACard.vue'
import EmergencyQACardChip from '@/components/timeline/EmergencyQACardChip.vue'
import CustomerAvailabilityCard from '@/components/timeline/CustomerAvailabilityCard.vue'
import CallContractorCard from '@/components/timeline/CallContractorCard.vue'
import FailedToContactCallCard from '@/components/timeline/FailedToContactCallCard.vue'
import PictureUploadCard from '@/components/timeline/PictureUploadCard.vue'
import FinancialSummaryCard from '@/components/timeline/FinancialSummaryCard.vue'
import CustomerToPayCard from '@/components/timeline/CustomerToPayCard.vue'
import JobCompleteCard from '@/components/timeline/JobCompleteCard.vue'
import JobNoteCard from '@/components/timeline/JobNoteCard.vue'
import SMSCard from '@/components/timeline/SMSCard.vue'
import EmailCard from '@/components/timeline/EmailCard.vue'
import JobNoteFooter from '@/components/timeline/JobNoteFooter.vue'
import VulnerabilityQACard from '@/components/timeline/VulnerabilityQACard.vue'
import SiUsJobQuestionsCard from '@/components/timeline/SiUsJobQuestionsCard.vue'
import EnablementNotesCard from '@/components/timeline/EnablementNotesCard.vue'
import IVSProcessCard from '@/components/timeline/IVSProcessCard.vue'
import ComplaintCard from '@/components/timeline/ComplaintCard.vue'
import ComplaintCardFooter from '@/components/timeline/ComplaintCardFooter.vue'
import TradeAppointedCard from '@/components/timeline/TradeAppointedCard.vue'
import CallContractorDetailView from '@/components/timeline/CallContractorDetailView.vue'
import JobCompleteConfirmationCard from '@/components/timeline/JobCompleteConfirmationCard.vue'
import JobCompleteFooter from '@/components/timeline/JobCompleteFooter.vue'
import EngineerVisitCard from '@/components/timeline/EngineerVisitCard.vue'
import PolicyWideQACard from '@/components/timeline/PolicyWideQACard.vue'
import ConfirmDateCard from '@/components/timeline/ConfirmDateCard.vue'
import JobPackageCard from '@/components/timeline/JobPackageCard.vue'
import JobPackagePreview from '@/components/timeline/JobPackagePreview.vue'
import JobPackageFooter from '@/components/timeline/JobPackageFooter.vue'
import MonitoringDetailCard from '@/components/timeline/MonitoringDetailCard.vue'
import TestingCard from '@/components/timeline/TestingCard.vue'
import TestingPreview from '@/components/timeline/TestingPreview.vue'
import BuildReportCard from '@/components/timeline/BuildReportCard.vue'
import BuildReportPreview from '@/components/timeline/BuildReportPreview.vue'
import SiteDataCard from '@/components/timeline/SiteDataCard.vue'
import SiteDataPreview from '@/components/timeline/SiteDataPreview.vue'
import Job from '@/models/Job'
import TimeLineCardModel from '@/models/TimeLineCardModel'
import TimeSlot from '@/models/claim/TimeSlot'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import Emergency from '@/models/Emergency'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import CallContractorModel from '@/models/claim/CallContractorModel'
import TwilioController from '@/api/twilioController'
import heTimeLineHelper from './timeline/generators/HE'
import usTimeLineHelper from './timeline/generators/US'
import siTimeLineHelper from './timeline/generators/SI'
import JobDelayCard from '@/components/timeline/JobDelayCard.vue'
import JobDelayPreview from '@/components/timeline/JobDelayPreview.vue'
import DrainageReportCard from '@/components/timeline/DrainageReportCard.vue'
import OtherFeesDetailCard from '@/components/timeline/OtherFeesDetailCard.vue'
import JobFinancialSummaryCard from '@/components/timeline/JobFinancialSummaryCard.vue'
import CustomerAppointedTradespeopleCard from '@/components/timeline/CustomerAppointedTradespeopleCard.vue'
import ReportDetail from '../models/ReportDetail'
import JobEscalationCard from '@/components/timeline/JobEscalationCard.vue'
import RepairEstimatesCard from '@/components/timeline/RepairEstimatesCard.vue'
import JobReopenCard from '@/components/timeline/JobReopenCard.vue'
import ClientClaimSubmittedCard from '@/components/timeline/ClientClaimSubmitted/ClientClaimSubmittedCard.vue'
import AutoDeploymentCard from '@/components/timeline/AutoDeployment/AutoDeploymentCard.vue'
import { useTimelineStore } from '@/pinia/timeline'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import { useDeploymentStore } from '@/pinia/deployment'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import CustomerPaymentsCard from './timeline/CustomerToPay/CustomerPaymentsCard.vue'
import { useLaunchDarkly } from '@/plugins/launch-darkly'
import {
  addAutoDeploymentCard,
  addClientClaimSubmittedCard,
  addCustomerPaymentsCard,
  generateNonLegacyCard,
} from '@/lib/timeline'

@Component({
  components: {
    TimeLineCard,
    FNOLCard,
    SoftFixCard,
    SoftFixFooter,
    FNOLCardFooter,
    EmergencyQACard,
    EmergencyQACardChip,
    VulnerabilityQACard,
    CustomerAvailabilityCard,
    CallContractorCard,
    FailedToContactCallCard,
    PictureUploadCard,
    FinancialSummaryCard,
    CustomerToPayCard,
    JobCompleteCard,
    JobNoteCard,
    SMSCard,
    EmailCard,
    JobNoteFooter,
    ComplaintCard,
    ComplaintCardFooter,
    TradeAppointedCard,
    CallContractorDetailView,
    EngineerVisitCard,
    JobCompleteConfirmationCard,
    JobCompleteFooter,
    PolicyWideQACard,
    ConfirmDateCard,
    JobPackageCard,
    JobPackagePreview,
    JobPackageFooter,
    SiUsJobQuestionsCard,
    EnablementNotesCard,
    MonitoringDetailCard,
    TestingCard,
    TestingPreview,
    BuildReportCard,
    BuildReportPreview,
    IVSProcessCard,
    JobDelayCard,
    JobDelayPreview,
    SiteDataCard,
    SiteDataPreview,
    DrainageReportCard,
    OtherFeesDetailCard,
    CustomerAppointedTradespeopleCard,
    JobFinancialSummaryCard,
    JobEscalationCard,
    RepairEstimatesCard,
    JobReopenCard,
    ClientClaimSubmittedCard,
    AutoDeploymentCard,
    CustomerPaymentsCard,
  },
})
export default class TimeLine extends Vue {
  @Prop() private jobId: string
  @Prop() private recordId: string
  @Prop() private requestType: string
  @Prop() private recordType: string
  private cardList: TimeLineCardModel[] = []
  private activeCardIndex = -1
  private lastLoadedJobId = ''
  private todayDate: Date = new Date()
  private isEmergencyCountChange = false
  private loadedNewJob = false
  private isAppointContractorDialogOpen = false

  // call to clear the active card
  public clearActive(): void {
    this.loadedNewJob = false
    this.processIndexChange(-1)
  }

  public addCustomerToPayCard() {
    const caCard: TimeLineCardModel | undefined = this.cardList.find(
      (a: TimeLineCardModel) => a.cardBodyComponent === 'CustomerToPayCard' && a.forItemId === ''
    )
    if (caCard) {
      const index: number = this.cardList.indexOf(caCard)
      if (index !== this.activeCardIndex) {
        this.processIndexChange(index)
      }
    } else {
      if (this.job) {
        this.cardList.push(heTimeLineHelper.bindCustomerToPayCard(this.job.jobId, null))
        this.processIndexChange(this.cardList.length - 1)
      }
      this.setScrollToBottomOnAddCard()
      this.pauseCallRecording()
    }
  }

  public addComplaintCard() {
    const caCard: TimeLineCardModel | undefined = this.cardList.find(
      (a: TimeLineCardModel) => a.cardBodyComponent === 'ComplaintCard' && a.forItemId === ''
    )
    if (caCard) {
      const index: number = this.cardList.indexOf(caCard)
      if (index !== this.activeCardIndex) {
        this.processIndexChange(index)
      }
    } else {
      if (this.job) {
        switch (this.job.jobType) {
          case 'US':
            this.cardList.push(usTimeLineHelper.bindComplaintCard(this.job))
            break
          case 'SI':
            this.cardList.push(siTimeLineHelper.bindComplaintCard(this.job))
            break
          default:
            // HE
            this.cardList.push(heTimeLineHelper.bindComplaintCard(this.job))
            break
        }
        this.processIndexChange(this.cardList.length - 1)
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  public addJobDelayCard() {
    const cardIndex: number = this.cardList.findIndex((a: TimeLineCardModel) => a.cardBodyComponent === 'JobDelayCard')
    if (cardIndex !== -1) {
      // const index: number = this.cardList[cardIndex];
      if (cardIndex !== this.activeCardIndex) {
        this.processIndexChange(cardIndex)
      }
    } else {
      if (this.job) {
        this.cardList.push(siTimeLineHelper.bindJobDelayCard(this.job))
        this.processIndexChange(this.cardList.length - 1)
      }
    }
    this.setScrollToBottomOnAddCard()
  }

  public addOtherFeesDetailCard() {
    const caCard: TimeLineCardModel | undefined = this.cardList.find(
      (a: TimeLineCardModel) => a.cardBodyComponent === 'OtherFeesDetailCard'
    )
    if (caCard) {
      const index: number = this.cardList.indexOf(caCard)
      if (index !== this.activeCardIndex) {
        this.processIndexChange(index)
      }
    } else {
      if (this.job) {
        this.cardList.push(heTimeLineHelper.bindOtherFeesDetailCard(this.job))
        this.processIndexChange(this.cardList.length - 1)
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  private async created() {
    eventBus.$on('timelineCardsAdded', (type: string) => {
      if (type === 'CustomerPaymentsSummary') this.timelineCardAdded()
      else this.jobChanged()
    })

    await this.retrieveNonLegacyTimelineCards()

    this.jobChanged()
    // open specific timeline card, when job opened from dashboard
    this.openTimelineCardFromDashboard()
    // open contractor appointed card to show auto deployment is running
    if (this.job && this.job.jobType === 'HE') {
      const appointedContractor = this.job.contractorAppointed.find(
        (e) => e.status === JobVisitStatus[JobVisitStatus.AutoDeploying]
      )
      if (appointedContractor) {
        const index: number = this.cardList.findIndex((e) => e.forItemId === appointedContractor.id)
        if (index !== -1) {
          this.processIndexChange(index)
        }
      }
    }
  }

  private mounted() {
    eventBus.$on('makeCustomerToPay', () => {
      if (this.job) {
        this.addCustomerToPayCard()
      }
    })
    eventBus.$on('appointContractorDialogOpen', (isDialogOpen: boolean) => {
      if (this.job && this.job.jobType === 'HE') {
        this.isAppointContractorDialogOpen = isDialogOpen
      }
    })
    eventBus.$on('addJobDelayCard', () => {
      if (this.job && (this.job.jobType === 'SI' || this.job.jobType === 'US')) {
        this.addJobDelayCard()
      }
    })
    eventBus.$on('addOtherFeesDetail', () => {
      if (this.job && this.job.jobType === 'HE') {
        this.addOtherFeesDetailCard()
      }
    })
    eventBus.$on('showJobEscalateCard', (itemId) => {
      if (this.job && this.job.jobType === 'HE') {
        const index: number = this.cardList.findIndex(
          (a: TimeLineCardModel) => a.cardBodyComponent === 'JobEscalationCard' && a.forItemId === itemId
        )
        if (index !== -1 && index !== this.activeCardIndex) {
          this.processIndexChange(index)
        }
      }
    })
    eventBus.$on('startAutoDeployment', () => {
      // start auto deployment when agent drops a call
      if (
        this.job &&
        this.job.jobType === 'HE' &&
        this.job.helplinePolicy === null &&
        this.job.policyEnquiry === null &&
        this.job.status === 'Pending' &&
        this.activeCardIndex !== -1 &&
        this.cardList[this.activeCardIndex].cardBodyComponent !== 'CallContractorCard' &&
        heTimeLineHelper.showCustomerAvailabilityCard(this.job)
      ) {
        const card: TimeLineCardModel | undefined = this.cardList.find(
          (a: TimeLineCardModel) => a.cardBodyComponent === 'CallContractorCard'
        )
        if (heTimeLineHelper.showCallContractorCard(this.job, card)) {
          this.startAutoDeployment()
        }
      }
    })

    useDeploymentStore().$reset()
  }

  private cardClassChanged(index: number, newClass: string) {
    this.cardList[index].cardClass = newClass
  }

  private get job(): Job | null {
    return storeGetters.getJob(this.jobId)
  }

  private get reloadTimeLineRequestedAt(): moment.Moment | null {
    if (!this.job) {
      return null
    }
    return this.job.timeLineRebuildRequestedAt
  }

  private openTimelineCardFromDashboard() {
    if (this.requestType !== '' && this.recordId) {
      this.openEngineerVisitCard()
    } else if (this.requestType === '') {
      this.openComplaintCard()
    }
  }

  private openEngineerVisitCard() {
    if (this.job) {
      const engineerRequest: EngineerRequestModel | undefined = this.job.engineerRequest.find(
        (r) => r.id === this.recordId
      )
      if (engineerRequest) {
        let engineerVisitDetail: EngineerVisitDetail | undefined
        if (this.requestType === EngineeringRequestCategory[EngineeringRequestCategory.RejectVisit]) {
          engineerVisitDetail = this.job.engineerVisitDetail.find(
            (v) =>
              v.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Rejected] &&
              v.forEmergencyTypeId === engineerRequest.forEmergencyTypeId &&
              v.forEmergencyDetailId === engineerRequest.forEmergencyDetailId
          )
        } else {
          engineerVisitDetail = this.job.engineerVisitDetail.find(
            (v) =>
              v.status !== EngineerJobVisitStatus[EngineerJobVisitStatus.Cancelled] &&
              v.status !== EngineerJobVisitStatus[EngineerJobVisitStatus.ReAssigned] &&
              v.forEmergencyTypeId === engineerRequest.forEmergencyTypeId &&
              v.forEmergencyDetailId === engineerRequest.forEmergencyDetailId
          )
        }
        const id = engineerVisitDetail ? engineerVisitDetail.id : ''
        if (engineerVisitDetail) {
          const index: number = this.cardList.findIndex((e) => e.forItemId === id)
          if (index !== -1) {
            const getCardToOpen: TimeLineCardModel | null = this.cardList[index]
            if (getCardToOpen && getCardToOpen.detailComponent) {
              this.setCardToBeSelected(index, getCardToOpen)
            }
          }
        }
      }
    }
  }

  private openComplaintCard() {
    let selectedCard: TimeLineCardModel | undefined
    if (this.recordType && this.recordType === 'complaint') {
      selectedCard = this.cardList.find(
        (a: TimeLineCardModel) => a.cardBodyComponent === 'ComplaintCard' && a.forItemId === this.recordId
      )
    }
    if (selectedCard && selectedCard.detailComponent) {
      const index: number = this.cardList.indexOf(selectedCard)
      if (index > -1) {
        this.setCardToBeSelected(index, selectedCard)
      }
    }
  }

  private setCardToBeSelected(index: number, card: TimeLineCardModel) {
    setTimeout(() => {
      this.$emit('cardWithDetailActivated', card)
    }, 0)
    this.activeCardIndex = index
    this.setScrollToSpecificCard()
  }

  private setScrollToSpecificCard() {
    // set scroll to specific card when timeline is loaded from dashboard using partial job view
    const self = this
    setTimeout(() => {
      const containerForTimeLineCards: any = document.getElementsByClassName('additional-chips')[0]
      const activeTimeLineCard: any = document.getElementsByClassName('card-active')[0]
      if (containerForTimeLineCards && activeTimeLineCard) {
        containerForTimeLineCards.scrollTop = activeTimeLineCard.offsetTop - 15
      }
    }, 100)
  }

  private timeLineCardClicked(index: number): void {
    this.loadedNewJob = false
    if (this.activeCardIndex === index) {
      return
    }
    this.processIndexChange(index, true) // second parameter is for time line card clicked
  }

  // processes an index change, set to -1 to clear
  private processIndexChange(selectedIndex: number, isTimeLineCardClick = false): void {
    if (this.job) {
      if (this.job && this.job.jobType === 'HE' && this.isAppointContractorDialogOpen) {
        // if contractor appointed dialog is open, do not refersh the timeline
        return
      }
      // If not saved remove temp cards(CustomerToPayCard, ComplaintCard) from cardList when closed from TimeLinePreview.
      let cardToRemove: TimeLineCardModel | null = null
      if (
        this.activeCardIndex >= 0 &&
        (isTimeLineCardClick || selectedIndex === -1 || selectedIndex === this.activeCardIndex)
      ) {
        cardToRemove = this.cardList[this.activeCardIndex]
        if (cardToRemove) {
          const isComplaintCard: boolean = cardToRemove.cardBodyComponent === 'ComplaintCard'
          const isDelayCard: boolean = cardToRemove.cardBodyComponent === 'JobDelayCard'
          const isOtherFeesDetailCard: boolean = cardToRemove.cardBodyComponent === 'OtherFeesDetailCard'
          if (
            cardToRemove &&
            cardToRemove.forItemId === '' &&
            (isComplaintCard || isDelayCard || isOtherFeesDetailCard)
          ) {
            const cardIndex: number = isComplaintCard
              ? this.cardList.findIndex((e) => e.cardBodyComponent === 'ComplaintCard' && e.forItemId === '')
              : isDelayCard
              ? this.cardList.findIndex((e) => e.cardBodyComponent === 'JobDelayCard' && e.forItemId === '')
              : this.cardList.findIndex((e) => e.cardBodyComponent === 'OtherFeesDetailCard' && e.forItemId === '')
            // if closing the preview, just remove the timeline card
            if (selectedIndex === -1) {
              if (cardIndex !== -1) {
                this.activeCardIndex = -1
              }
              this.cardList.splice(this.cardList.indexOf(cardToRemove), 1)
            } else {
              // otherwise, show a dialog to confirm they want to leave complaint and if yes, remove the card
              if (isComplaintCard) {
                Shared.confirmationPopup.open(
                  'Are you sure you want to cancel complaint?',
                  '',
                  '',
                  'No',
                  'Yes',
                  this,
                  'removeComplaint',
                  selectedIndex
                )
              } else if (isDelayCard) {
                Shared.confirmationPopup.open(
                  'Are you sure you want to close delay?',
                  '',
                  '',
                  'No',
                  'Yes',
                  this,
                  'removeCardWithSelectedindex',
                  selectedIndex
                )
              } else {
                Shared.confirmationPopup.open(
                  'Are you sure you want to close other fees detail?',
                  '',
                  '',
                  'No',
                  'Yes',
                  this,
                  'removeCardWithSelectedindex',
                  selectedIndex
                )
              }
              return
            }
          }
        }
      }
      // null active card detail
      if (!(cardToRemove && cardToRemove.forItemId === '' && cardToRemove.cardBodyComponent === 'CustomerToPayCard')) {
        this.$emit('cardWithDetailActivated', null)
      }

      // update worker activity, while closing call contractor preview
      if (cardToRemove && cardToRemove.cardBodyComponent === 'CallContractorCard') {
        eventBus.$emit(
          'updateWorkerActivity',
          store.Instance.state.Environment.TwilioIdleActivitySid,
          'Idle',
          'Closed Call Contractor Card'
        )
      }

      if (cardToRemove && cardToRemove.forItemId === '' && cardToRemove.cardBodyComponent === 'CustomerToPayCard') {
        const cardIndex: number = this.cardList.findIndex((e) => e.cardBodyComponent === 'CustomerToPayCard')
        // if closing the preview, just remove the timeline card and resume recording
        if (
          selectedIndex === -1 ||
          useCustomerToPayStore().invoiceCreated ||
          useCustomerToPayStore().isTransactionCompleted
        ) {
          if (cardIndex !== -1) {
            this.activeCardIndex = -1
          }
          this.cardList.splice(this.cardList.indexOf(cardToRemove), 1)
          this.resumeCallRecording()
        } else {
          // otherwise, show a dialog to confirm they want to leave customer to pay and if yes, remove the card
          Shared.confirmationPopup.open(
            'Are you sure you want to cancel Customer to Pay?',
            '',
            '',
            'No',
            'Yes',
            this,
            'removeCustomerToPay',
            selectedIndex
          )
        }
      } else {
        // on closing current card detail preview, it should not open anything else.
        if (selectedIndex < 0 || this.activeCardIndex === selectedIndex) {
          this.activeCardIndex = -1
        } else {
          // If 'Find Contractor' or 'Failed To Contact CallCard' button is in last position then detail view should not open automatically for signalr calls
          if (!isTimeLineCardClick) {
            for (let index = selectedIndex; index > 0; index--) {
              if (
                this.cardList[index].cardBodyComponent === 'CallContractorCard' ||
                this.cardList[index].cardBodyComponent === 'FailedToContactCallCard'
              ) {
                selectedIndex--
              } else {
                break
              }
            }
          }
          const getCardToOpen: TimeLineCardModel | null = this.cardList[selectedIndex]
          if (getCardToOpen && getCardToOpen.detailComponent) {
            setTimeout(() => {
              this.$emit('cardWithDetailActivated', getCardToOpen)
            }, 0)
            this.activeCardIndex = selectedIndex
          }
        }
      }

      if (this.job.jobType === 'HE') {
        // if auto deployment of any emergency is running, show overlay
        this.checkIsAutoDeploymentRunning()
      }
    }
  }

  @Watch('job.emergencies')
  private emergencyCountChange(newValue: any, oldValue: any) {
    if (this.job && this.job.jobType === 'HE') {
      this.emergencyCountChangeHE(newValue, oldValue)
    }
    if (this.job && this.job.jobType === 'SI') {
      this.emergencyCountChangeSI(newValue, oldValue)
    }
    if (this.job && this.job.jobType === 'US') {
      this.emergencyStatusChangeUS()
    }
  }

  // This will only called for HE jobs
  private emergencyCountChangeHE(newValue: any, oldValue: any) {
    if (this.job && newValue.find((c) => c.jobId).jobId === oldValue.find((c) => c.jobId).jobId) {
      this.loadedNewJob = false
      this.isEmergencyCountChange = true
      this.jobChanged()
      this.setScrollToBottomOnAddCard()
      // if auto deployment is running - show auto deployment running card with map
      if (this.job.emergencies) {
        const emergency = this.job.emergencies.find((x) => x.autoDeploymentRunning)
        if (emergency) {
          const index = this.cardList.findIndex(
            (e) => e.forEmergencyId === emergency.id && e.cardBodyComponent === 'TradeAppointedCard'
          )
          if (index !== -1 && this.activeCardIndex !== index) {
            this.processIndexChange(index, true)
          }
        }
      }
    }
  }

  private emergencyCountChangeSI(newValue: any, oldValue: any) {
    if (this.job && newValue.find((c) => c.jobId).jobId === oldValue.find((c) => c.jobId).jobId) {
      this.loadedNewJob = false
      this.isEmergencyCountChange = true
      this.jobChanged()
      this.setScrollToBottomOnAddCard()
      // if auto deployment is running - show auto deployment running card with map
      if (this.job.emergencies) {
        const emergency = this.job.emergencies.find((x) => x.autoDeploymentRunning)
        if (emergency) {
          const index = this.cardList.findIndex(
            (e) => e.forEmergencyId === emergency.id && e.cardBodyComponent === 'TradeAppointedCard'
          )
          if (index !== -1 && this.activeCardIndex !== index) {
            this.processIndexChange(index, true)
          }
        }
      }
    }
  }

  private emergencyStatusChangeUS() {
    if (this.job && this.job.emergencies[this.job.emergencies.length - 1].isEmergencyAccepted) {
      this.jobChanged()
      this.setScrollToBottomOnAddCard()
      // find confirm date card and set as currently selected
      const index = this.cardList.findIndex((e) => e.cardBodyComponent === 'ConfirmDateCard')
      if (index !== -1 && this.activeCardIndex !== index) {
        this.processIndexChange(index, true)
      }
    }
  }

  @Watch('job.cancellationReason')
  private jobCancellationReasonChange() {
    if (this.job && (this.job.jobType === 'HE' || this.job.jobType === 'SI')) {
      this.jobCancellationReasonChangeHE()
    }
  }

  // This will only called for HE jobs
  private jobCancellationReasonChangeHE() {
    if (this.job && this.job.cancellationReason) {
      const previousActiveCardIndex: number = this.activeCardIndex
      this.jobChanged()
      if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'JobCompleteConfirmationCard') {
        this.processIndexChange(this.cardList.length - 1, true)
      } else {
        this.processIndexChange(previousActiveCardIndex)
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  @Watch('job.thirdPartyAppointedContractor')
  private thirdPartyAppointedContractorChange() {
    if (this.job && this.job.jobType === 'HE') {
      this.thirdPartyAppointedContractorChangeHE()
    }
  }

  // This will only called for HE jobs
  private thirdPartyAppointedContractorChangeHE() {
    this.loadedNewJob = false
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    this.processIndexChange(previousActiveCardIndex)
    this.setScrollToBottomOnAddCard()
  }

  @Watch('job.jobNotes.length')
  // This will be common for all the types
  private jobNoteCountChange() {
    this.loadedNewJob = false
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    // Existing preview should stay as it is on addition of jobnote Card
    if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'JobNoteCard') {
      this.processIndexChange(this.cardList.length - 1, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }

    this.setScrollToBottomOnAddCard()
  }

  @Watch('job.contractorAppointed')
  private jobContractorAppointedChange(newValue: ContractorAppointedModel[], oldValue: ContractorAppointedModel[]) {
    if (this.job && this.job.jobType === 'HE') {
      this.jobContractorAppointedChangeHE()
    } else if (this.job!.jobType === 'SI' || this.job!.jobType === 'US') {
      this.jobContractorAppointedChangeSI()
    }
  }

  // This will only called for HE jobs
  private jobContractorAppointedChangeHE() {
    if (this.job && this.job.contractorAppointed.length > 0) {
      // find unavailableContractor
      const unavailableContractor: ContractorAppointedModel =
        this.job.contractorAppointed[this.job.contractorAppointed.length - 1]
      if (unavailableContractor && unavailableContractor.status === JobVisitStatus[JobVisitStatus.Unavailable]) {
        // Do not refresh timeline when contractor status is changed to Unavailable.
        // add card for "FailedToContactCallCard".
        if (this.job && this.job.contractorAppointed.length > 0) {
          const emergency: Emergency | undefined = this.job.emergencies.find(
            (e) => e.id === unavailableContractor.emergencyId
          )
          if (emergency) {
            const unavailableContractors: ContractorAppointedModel[] | undefined = this.job.contractorAppointed.filter(
              (c) => c.emergencyId === emergency.id && c.status === JobVisitStatus[JobVisitStatus.Unavailable]
            )
            const sortedUnavailableContractors = Shared.sortArrayByDate(unavailableContractors, 'createdAt')
            if (unavailableContractors && unavailableContractors.length > 0) {
              // check for auto deployment -> find contractor appointed card with status not set as unavailable
              sortedUnavailableContractors.forEach((c) => {
                const caCardIndexWithOtherStatus: TimeLineCardModel | undefined = this.cardList.find(
                  (a: TimeLineCardModel) =>
                    a.cardBodyComponent === 'TradeAppointedCard' &&
                    a.forEmergencyId === emergency.id &&
                    a.forItemId === c.id
                )
                // if card found remove from timeline
                if (caCardIndexWithOtherStatus) {
                  this.cardList.splice(this.cardList.indexOf(caCardIndexWithOtherStatus), 1)
                  this.activeCardIndex = -1
                }
              })
              // check for auto deployment -> add call contractor card or bind existing card to timeline as timeline is not refreshed when contractor status is changed to Unavailable.
              if (this.job) {
                let isAutoDeploymentRunning = 0
                if (this.job && this.job.emergencies) {
                  isAutoDeploymentRunning = this.job.emergencies.filter((x) => x.autoDeploymentRunning).length
                }
                if (isAutoDeploymentRunning === 0) {
                  const callContractor: CallContractorModel | undefined = this.job.callContractors.find(
                    (a: CallContractorModel) => a.forEmergencyTypeId === emergency.typeId
                  )
                  const currentCallContractorIndex = this.cardList.findIndex(
                    (a: TimeLineCardModel) =>
                      a.cardBodyComponent === 'CallContractorCard' && a.forEmergencyId === emergency.id
                  )
                  const previousActiveCardIndex: number = this.activeCardIndex
                  if (currentCallContractorIndex !== -1) {
                    if (previousActiveCardIndex !== currentCallContractorIndex) {
                      this.processIndexChange(currentCallContractorIndex)
                    }
                  } else {
                    this.cardList.push(heTimeLineHelper.bindCallContractorCard(this.job, callContractor, emergency))
                    if (previousActiveCardIndex !== currentCallContractorIndex) {
                      this.processIndexChange(this.cardList.length - 1)
                    }
                  }
                }
              }
              // bind contractor unavailable card
              const caCard: TimeLineCardModel | undefined = this.cardList.find(
                (a: TimeLineCardModel) =>
                  a.cardBodyComponent === 'FailedToContactCallCard' && a.forEmergencyId === emergency.id
              )
              if (caCard) {
                // if "FailedToContactCallCard" card with emergency same as unavailableContractor exist then remove card and then push new card.
                this.cardList.splice(this.cardList.indexOf(caCard), 1)
              }
              this.cardList.push(heTimeLineHelper.bindFailedToContactCallCard(emergency, sortedUnavailableContractors))
              if (this.activeCardIndex === -1) {
                this.processIndexChange(this.cardList.length - 1)
              }
            }
          }
        }
      } else {
        const previousActiveCardIndex: number = this.activeCardIndex
        this.jobChanged()
        if (
          this.cardList[this.cardList.length - 1].cardBodyComponent === 'CallContractorCard' ||
          this.cardList[this.cardList.length - 1].cardBodyComponent === 'TradeAppointedCard'
        ) {
          this.processIndexChange(
            this.cardList.length - 1,
            this.cardList[this.cardList.length - 1].cardBodyComponent === 'TradeAppointedCard' ? true : false
          )
        } else {
          this.processIndexChange(previousActiveCardIndex)
        }
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  // This will only be called for SI jobs
  private jobContractorAppointedChangeSI() {
    if (this.job && this.job.contractorAppointed.length > 0) {
      // find unavailableContractor
      const unavailableContractor: ContractorAppointedModel =
        this.job.contractorAppointed[this.job.contractorAppointed.length - 1]
      if (unavailableContractor && unavailableContractor.status === JobVisitStatus[JobVisitStatus.Unavailable]) {
        // Do not refresh timeline when contractor status is changed to Unavailable.
        // add card for "FailedToContactCallCard".
        if (this.job && this.job.contractorAppointed.length > 0) {
          const emergency: Emergency | undefined = this.job.emergencies.find(
            (e) => e.id === unavailableContractor.emergencyId
          )
          if (emergency) {
            const unavailableContractors: ContractorAppointedModel[] | undefined = this.job.contractorAppointed.filter(
              (c) => c.emergencyId === emergency.id && c.status === JobVisitStatus[JobVisitStatus.Unavailable]
            )
            const sortedUnavailableContractors = Shared.sortArrayByDate(unavailableContractors, 'createdAt')
            if (unavailableContractors && unavailableContractors.length > 0) {
              // find contractor appointed card with status not set as unavailable
              sortedUnavailableContractors.forEach((c) => {
                const caCardIndexWithOtherStatus: TimeLineCardModel | undefined = this.cardList.find(
                  (a: TimeLineCardModel) =>
                    a.cardBodyComponent === 'TradeAppointedCard' &&
                    a.forEmergencyId === emergency.id &&
                    a.forItemId === c.id
                )
                // if card found remove from timeline
                if (caCardIndexWithOtherStatus) {
                  this.cardList.splice(this.cardList.indexOf(caCardIndexWithOtherStatus), 1)
                  this.activeCardIndex = -1
                }
              })
              // bind contractor unavailable card
              const caCard: TimeLineCardModel | undefined = this.cardList.find(
                (a: TimeLineCardModel) =>
                  a.cardBodyComponent === 'FailedToContactCallCard' && a.forEmergencyId === emergency.id
              )
              if (caCard) {
                // if "FailedToContactCallCard" card with emergency same as unavailableContractor exist then remove card and then push new card.
                this.cardList.splice(this.cardList.indexOf(caCard), 1)
              }
              this.cardList.push(siTimeLineHelper.bindFailedToContactCallCard(emergency, sortedUnavailableContractors))
            }
          }
        }
      } else {
        const previousActiveCardIndex: number = this.activeCardIndex
        this.jobChanged()
        if (
          this.cardList[this.cardList.length - 1].cardBodyComponent === 'CallContractorCard' ||
          this.cardList[this.cardList.length - 1].cardBodyComponent === 'TradeAppointedCard'
        ) {
          this.processIndexChange(
            this.cardList.length - 1,
            this.cardList[this.cardList.length - 1].cardBodyComponent === 'TradeAppointedCard' ? true : false
          )
        } else {
          this.processIndexChange(previousActiveCardIndex)
        }
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  @Watch('job.customerToPay.length')
  private jobCustomerAuthorizationChange(newValue: boolean, oldValue: boolean) {
    if (this.job) {
      this.jobCustomerAuthorizationChangeHE()
    }
  }

  // This will only called for HE jobs
  private jobCustomerAuthorizationChangeHE() {
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'CustomerToPayCard') {
      this.processIndexChange(this.cardList.length - 1, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }
  }

  @Watch('job.customerAvailability.timeSlot')
  private jobCustomerAvailabilityChange(newValue: TimeSlot[], oldValue: TimeSlot[]) {
    // when customer availabilty timeslots changed at once then call contractor card is shown on bottom so scroll goes to down
    this.jobChanged()
    if (oldValue && oldValue.length === 0) {
      this.setScrollToBottomOnAddCard()
    }
    const caCardIndex = this.cardList.findIndex(
      (a: TimeLineCardModel) => a.cardBodyComponent === 'CustomerAvailabilityCard'
    )
    if (caCardIndex > -1 && caCardIndex !== this.activeCardIndex) {
      this.processIndexChange(caCardIndex)
    }
  }

  @Watch('job.id')
  private jobIdChanged(newValue: string, oldValue: string) {
    if (this.job && this.job.jobType === 'HE') {
      if (newValue !== this.lastLoadedJobId) {
        this.loadedNewJob = true
        this.lastLoadedJobId = newValue
        const qaCardIndex: any = this.cardList.findIndex(
          (a: TimeLineCardModel) => a.cardBodyComponent === 'EmergencyQACard'
        )
        if (qaCardIndex) {
          this.processIndexChange(qaCardIndex)
          return
        }
      }
    }
  }

  @Watch('job.engineerVisitDetail')
  private onEngineerVisitDetailChange() {
    this.engineerVisitDetailChanges()
  }

  // This will be called for HE, US, SI jobs
  private engineerVisitDetailChanges() {
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'EngineerVisitCard') {
      this.processIndexChange(this.cardList.length - 1, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }
    this.setScrollToBottomOnAddCard()
  }

  @Watch('job.complaint.length')
  private jobComplaintsChange() {
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'ComplaintCard') {
      this.processIndexChange(this.cardList.length - 1, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }
  }

  @Watch('job.status')
  @Watch('job.cancellationReason')
  @Watch('job.contractorInvoiceDetail')
  @Watch('job.clientInvoiceDetails')
  private jobCompleteChange() {
    if (this.job && (this.job.jobType === 'HE' || this.job.jobType === 'US')) {
      if ((this.job.status === 'Completed' || this.job.status === 'Cancelled') && !this.job.helplinePolicy) {
        this.jobCompleteChangeHE()
      } else if (this.job.status === 'Pending') {
        // job is reopened and status is Pending - rebind timeline cards
        this.jobChanged()
        this.processIndexChange(this.cardList.length - 1, true)
      }
    } else if (this.job && this.job.jobType === 'SI') {
      if (this.job.status === 'Completed' || this.job.status === 'Cancelled') {
        this.jobCompleteChangeHE()
      }
    }
  }

  // This will only called for HE jobs
  private jobCompleteChangeHE() {
    this.jobChanged()
    const previousActiveCardIndex: number = this.activeCardIndex
    const index = this.cardList.findIndex((e) => e.cardBodyComponent === 'JobCompleteCard')
    if (index !== -1 && index !== previousActiveCardIndex) {
      this.processIndexChange(index, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }
    this.setScrollToBottomOnAddCard()
  }

  @Watch('job.claimPictures.length')
  private updateClaimPictures() {
    if (this.job && this.job.jobType === 'HE') {
      this.updateClaimPicturesHE()
    }
  }

  @Watch('job.removedEmergencies.length')
  private removedEmergencyChange() {
    this.jobChanged()
    const index = this.cardList.findIndex((e) => e.cardBodyComponent === 'FNOLCard')
    if (index !== -1 && this.activeCardIndex !== index) {
      this.processIndexChange(index, true)
    }
  }

  // This will only called for HE jobs
  private updateClaimPicturesHE() {
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'PictureUploadCard') {
      this.processIndexChange(this.cardList.length - 1, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }
    // (job.claimPictures) watch is called after getting thumbnail of claimPictures.So to open engineer visit card from engineer request dashboard, openEngineerVisitCard() is called here.
    this.openEngineerVisitCard()
    this.setScrollToBottomOnAddCard()
  }

  @Watch('job.enablementCompletedAt')
  private updateEnablementSI() {
    this.jobChanged()
  }

  @Watch('job.recommendationScore')
  @Watch('job.serviceQualityScore')
  @Watch('job.qualityComments')
  private onUpdatePromoterScore() {
    this.jobChanged()
    const index = this.cardList.findIndex((e) => e.cardBodyComponent === 'JobCompleteCard')
    if (index !== -1 && this.activeCardIndex !== index) {
      this.processIndexChange(index, true)
    }
  }

  @Watch('job.ivsDetail')
  @Watch('job.sIJobDetail.jobPackage')
  private updateIVSDetailSI() {
    if (this.cardList[this.activeCardIndex].cardBodyComponent === 'SiteDataCard') {
      this.jobChanged()
      const index = this.cardList.findIndex((e) => e.cardBodyComponent === 'SiteDataCard')
      if (index !== -1) {
        this.processIndexChange(index, true)
      }
      return
    }
    this.jobChanged()
    this.processIndexChange(this.cardList.length - 1, true)
    this.setScrollToBottomOnAddCard()
  }

  @Watch('job.drainageReport')
  private drainageReportDetailChange() {
    if (this.job && this.job.jobType === 'US') {
      const previousActiveCardIndex: number = this.activeCardIndex
      this.jobChanged()
      if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'DrainageReportCard') {
        this.processIndexChange(this.cardList.length - 1, true)
      } else {
        this.processIndexChange(previousActiveCardIndex)
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  @Watch('job.customerAppointedTradespeople')
  private customerAppointedTradespeopleChange() {
    if (this.job && this.job.jobType === 'HE') {
      const previousActiveCardIndex: number = this.activeCardIndex
      this.jobChanged()
      if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'CustomerAppointedTradespeopleCard') {
        this.processIndexChange(this.cardList.length - 1, true)
      } else {
        this.processIndexChange(previousActiveCardIndex)
      }
    }
  }

  @Watch('job.siJobDelay')
  @Watch('job.usJobDelay')
  private jobDelayChange() {
    const previousActiveCardIndex: number = this.activeCardIndex
    this.jobChanged()
    if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'JobDelayCard') {
      this.processIndexChange(this.cardList.length - 1, true)
    } else {
      this.processIndexChange(previousActiveCardIndex)
    }
  }

  @Watch('job.cctvControlLog')
  @Watch('job.cctvControlLog.isCCTVDrainageVerified')
  private cctvControlLogDetailChange() {
    if (this.job && this.job.jobType === 'US') {
      if (this.cardList[this.activeCardIndex].cardBodyComponent === 'SiteDataCard') {
        this.jobChanged()
        const index = this.cardList.findIndex((e) => e.cardBodyComponent === 'SiteDataCard')
        if (index !== -1) {
          this.processIndexChange(index, true)
        }
        return
      }
      this.jobChanged()
      this.processIndexChange(this.cardList.length - 1, true)
      this.setScrollToBottomOnAddCard()
    }
  }

  @Watch('job.repairEstimates')
  private repairEstimateDetailChange() {
    if (this.job && this.job.jobType === 'US') {
      const previousActiveCardIndex: number = this.activeCardIndex
      this.jobChanged()
      if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'RepairEstimatesCard') {
        this.processIndexChange(this.cardList.length - 1, true)
      } else {
        this.processIndexChange(previousActiveCardIndex)
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  private timelineCardAdded() {
    useTimelineStore().cards.forEach((card) => {
      this.cardList.push(...generateNonLegacyCard(card))
    })
  }

  @Watch('reloadTimeLineRequestedAt')
  @Watch('job')
  private jobChanged() {
    if (storeGetters.getJobRefreshRequested()) {
      storeMutations.setJobRefreshRequested(false)
      return
    }
    if (!this.job) {
      this.cardList = []
      this.processIndexChange(-1)
      return
    }

    if (this.job.id !== this.lastLoadedJobId) {
      this.loadedNewJob = true
      this.lastLoadedJobId = this.job.id
    }
    const currentOpenedCard = this.cardList[this.activeCardIndex]

    // build up cards
    let cards: TimeLineCardModel[] = []

    switch (this.job.jobType) {
      case 'US':
        cards = usTimeLineHelper.generateCards(this.job)
        break
      case 'SI':
        cards = siTimeLineHelper.generateCards(this.job)
        break
      default:
        // HE case
        cards = heTimeLineHelper.generateCards(this.job)
        break
    }
    // sort them by created, with FNOL fixed at front
    // set to cardList so will display
    this.cardList = cards.sort((a, b) => {
      // special case for FNOL Card
      // it is always first, so if you compare FNOL to another, place it before
      // if you compare another to FNOL, place it after
      if (a.cardBodyComponent === 'FNOLCard') {
        return -1
      } else if (b.cardBodyComponent === 'FNOLCard') {
        return 1
      }

      // special case for Monitoring Detail Card
      // it should always be last, so if you compare monitoring to another, place it after
      // if you compare another to monitoring, place it before
      // place JobCompleteConfirmationCard and JobCompleteCard at last for SI job
      if (
        a.cardBodyComponent === 'MonitoringDetailCard' ||
        (this.job &&
          this.job.jobType === 'SI' &&
          (a.cardBodyComponent === 'JobCompleteConfirmationCard' ||
            a.cardBodyComponent === 'JobCompleteCard' ||
            a.cardBodyComponent === 'JobFinancialSummaryCard')) ||
        a.cardBodyComponent === 'JobReopenCard'
      ) {
        return 1
      } else if (b.cardBodyComponent === 'MonitoringDetailCard') {
        return -1
      }

      const firstCreatedDate = moment(a.createdDate)
      const secondCreatedDate = moment(b.createdDate)

      return firstCreatedDate.isBefore(secondCreatedDate) ? -1 : firstCreatedDate.isAfter(secondCreatedDate) ? 1 : 0
    })

    if (this.job.jobType === 'HE') {
      this.setCardPreviewHE(currentOpenedCard)
    } else {
      // otherwise next card should be opened
      this.processIndexChange(this.cardList.length - 1)
    }
    this.setScrollToBottomOnAddCard()
  }

  @Watch('siTestingCompleteStatus')
  private updateTestingCompleteSI() {
    this.jobChanged()
  }

  private get siTestingCompleteStatus(): boolean {
    if (!this.jobId) {
      return false
    }
    const progress = storeGetters.getTestingProgress(this.jobId)
    return !progress || progress.testingComplete
  }

  @Watch('getIsReportGenerated')
  private siReportDetailStatusChange() {
    if (this.job && this.job.status !== 'Completed' && this.getIsReportGenerated) {
      this.jobChanged()
    }
  }

  private get getIsReportGenerated(): boolean {
    if (!this.jobId) {
      return false
    }
    const reportDetail: ReportDetail | null = storeGetters.getReportDetail(this.jobId)
    return reportDetail &&
      (reportDetail.reportStatus === ReportStatus.ReportBuilt || reportDetail.reportStatus === ReportStatus.ReportSent)
      ? true
      : false
  }

  // This will only called for HE job
  private setCardPreviewHE(currentOpenedCard) {
    if (this.loadedNewJob || this.isEmergencyCountChange) {
      // check If current emergency is accepted then customer availability card should be shown
      const isEmergencyQACard =
        this.job &&
        this.cardList[this.activeCardIndex] &&
        this.job.emergencies.find((c) => c.id === this.cardList[this.activeCardIndex].forEmergencyId)
      const currentQACardIsAccepted =
        isEmergencyQACard &&
        (isEmergencyQACard.isEmergencyAccepted ||
          (isEmergencyQACard.repudiationDescription !== '' && isEmergencyQACard.repudiationDescription !== null))

      if (
        isEmergencyQACard &&
        (isEmergencyQACard.isEmergencyAccepted === true ||
          (isEmergencyQACard.isEmergencyAccepted === false && isEmergencyQACard.repudiationDescription))
      ) {
        if (currentQACardIsAccepted && isEmergencyQACard && currentOpenedCard.cardBodyComponent === 'EmergencyQACard') {
          // set active card after updation of cards for opening availability card
          this.activeCardIndex = this.cardList.findIndex((a) => a.forEmergencyId === isEmergencyQACard.id)
          if (
            this.showCustomerAvailabilityCard &&
            (isEmergencyQACard.isEmergencyAccepted === true ||
              (isEmergencyQACard.isEmergencyAccepted === false && isEmergencyQACard.ctpCompletedForRejectedEmergency))
          ) {
            this.showCardPreview('CustomerAvailabilityCard')
            return
          } else {
            this.processIndexChange(this.cardList.length - 1)
          }
        }
      }

      // new job was loaded, select the latest question card item in timeline
      const qaCards: TimeLineCardModel[] = this.cardList.filter(
        (a: TimeLineCardModel) => a.cardBodyComponent === 'EmergencyQACard'
      )
      if (qaCards && qaCards.length) {
        const card: TimeLineCardModel | undefined = qaCards.pop()
        if (card && this.loadedNewJob) {
          // job loaded, select the latest question card item in timeline
          const index: number = this.cardList.indexOf(card)
          this.processIndexChange(index)
        } else {
          if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'CallContractorCard') {
            return
          }
          // if auto deployment completed with no response, auto select last success card
          if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'FailedToContactCallCard') {
            const failedToContactCallCard = this.cardList[this.cardList.length - 1]
            const currentCallContractorIndex = this.cardList.findIndex(
              (a: TimeLineCardModel) =>
                a.cardBodyComponent === 'CallContractorCard' &&
                a.forEmergencyId === failedToContactCallCard.forEmergencyId
            )
            const previousActiveCardIndex: number = this.activeCardIndex
            if (previousActiveCardIndex !== currentCallContractorIndex) {
              this.processIndexChange(currentCallContractorIndex)
            }
            this.checkIsAutoDeploymentRunning()
          } else {
            this.processIndexChange(this.cardList.length - 1)
          }
        }
      } else {
        this.processIndexChange(this.cardList.length - 1)
      }
      this.isEmergencyCountChange = false
    } else {
      this.processIndexChange(-1)
    }
  }

  private showCardPreview(cardBodyComponent: string) {
    const caCard: TimeLineCardModel | undefined = this.cardList.find(
      (a: TimeLineCardModel) => a.cardBodyComponent === cardBodyComponent
    )
    if (caCard) {
      const index: number = this.cardList.indexOf(caCard)
      this.processIndexChange(index)
    } else {
      this.processIndexChange(-1)
    }
  }

  private displaySelectedEmergencyCard(card: any): boolean {
    if (this.job) {
      switch (this.job.jobType) {
        case 'US':
          return usTimeLineHelper.displaySelectedEmergencyCard(this.job, card)
        case 'SI':
          return siTimeLineHelper.displaySelectedEmergencyCard(this.job, card)
        default:
          // HE case
          return heTimeLineHelper.displaySelectedEmergencyCard(this.job, card)
      }
    }
    return false
  }

  // show customer availability card on close of question preview and if any availability is set
  private get showCustomerAvailabilityCard(): boolean {
    if (this.job) {
      switch (this.job.jobType) {
        case 'US':
          break
        case 'SI':
          break
        default:
          // HE case
          return heTimeLineHelper.showCustomerAvailabilityCard(this.job)
      }
    }
    return false
  }

  private setScrollToBottomOnAddCard() {
    // scroll to down on any update in cards
    // new card is added to timeline (or on job page load) then scroll bar should go to bottom
    setTimeout(() => {
      const containerForTimeLineCards: any = document.getElementsByClassName('additional-chips')[0]
      if (containerForTimeLineCards) {
        containerForTimeLineCards.scrollTop =
          containerForTimeLineCards.scrollHeight - containerForTimeLineCards.offsetHeight
      }
    }, 100)
  }

  // This will only called for HE jobs
  private pauseCallRecording(): void {
    if (store.Instance.state.CurrentCallSid) {
      TwilioController.PauseRecording(store.Instance.state.CurrentCallSid, true)
        .then((paused) => {
          if (paused) {
            eventBus.$emit('showTwilioSnackbar', 'Recording paused for Customer to Pay', true)
          }
        })
        .catch((error) => {
          eventBus.$emit('showTwilioSnackbar', 'Error pausing recording for Customer to Pay process.', false)
        })
    }
  }

  // This will only called for HE jobs
  private resumeCallRecording(): void {
    if (store.Instance.state.CurrentCallSid) {
      TwilioController.PauseRecording(store.Instance.state.CurrentCallSid, false)
        .then((paused) => {
          if (!paused) {
            eventBus.$emit('clearTwilioSnackbar')
          }
        })
        .catch((error) => {
          eventBus.$emit('showTwilioSnackbar', 'Error resuming recording for Customer to Pay process.', false)
        })
    }
  }

  private removeCustomerToPay(selectedIndex): void {
    // remove the CTP card
    this.removeCardFromTimeline(selectedIndex)
    // customer to pay card removed from timeline, resume call recording
    this.resumeCallRecording()
  }

  private removeComplaint(selectedIndex): void {
    this.removeCardFromTimeline(selectedIndex)
  }

  private removeCardWithSelectedindex(selectedIndex): void {
    this.removeCardFromTimeline(selectedIndex)
  }

  private removeCardFromTimeline(selectedIndex): void {
    this.$emit('cardWithDetailActivated', null)
    this.cardList.splice(this.activeCardIndex, 1)
    // open the selected card, if there is one, otherwise do not open any cards
    if (selectedIndex < 0 || this.activeCardIndex === selectedIndex) {
      this.activeCardIndex = -1
    } else {
      const getCardToOpen: TimeLineCardModel | null = this.cardList[selectedIndex]
      if (getCardToOpen && getCardToOpen.detailComponent) {
        setTimeout(() => {
          this.$emit('cardWithDetailActivated', getCardToOpen)
        }, 0)
        this.activeCardIndex = selectedIndex
      }
    }
  }

  // This will only called for HE jobs
  private checkIsAutoDeploymentRunning() {
    let isAutoDeploymentRunning = 0
    if (this.job && this.job.emergencies) {
      isAutoDeploymentRunning = this.job.emergencies.filter((x) => x.autoDeploymentRunning).length
    }
    if (isAutoDeploymentRunning > 0) {
      eventBus.$emit('autoDeploymentStarted', true)
    } else {
      eventBus.$emit('autoDeploymentStarted', false)
    }
  }

  @Watch('job.jobEscalationDetails.length')
  private jobEscalationDetailsChange() {
    if (this.job && this.job.jobType === 'HE') {
      const previousActiveCardIndex: number = this.activeCardIndex
      this.jobChanged()
      if (this.cardList[this.cardList.length - 1].cardBodyComponent === 'JobEscalationCard') {
        this.processIndexChange(this.cardList.length - 1, true)
      } else {
        this.processIndexChange(previousActiveCardIndex)
      }
      this.setScrollToBottomOnAddCard()
    }
  }

  private startAutoDeployment() {
    // find and click call contractor card
    const index: number = this.cardList.findIndex(
      (a: TimeLineCardModel) => a.cardBodyComponent === 'CallContractorCard'
    )
    if (index !== -1 && index !== this.activeCardIndex) {
      this.processIndexChange(index, true)
    }
  }

  public addClientClaimCompletedCard(cardId: string) {
    const card = this.cardList.find((a: TimeLineCardModel) => a.forItemId === cardId)
    if (card) {
      const index: number = this.cardList.indexOf(card)
      if (index !== this.activeCardIndex) {
        this.processIndexChange(index)
      }
    } else {
      const newCard = addClientClaimSubmittedCard(cardId)
      if (newCard) {
        this.cardList.push(newCard)
        this.processIndexChange(this.cardList.length - 1)
        this.setScrollToBottomOnAddCard()
      }
    }
  }

  public addAutoDeploymentCard(cardId: string) {
    const card = this.cardList.find((a: TimeLineCardModel) => a.forItemId === cardId)
    if (card) {
      const index: number = this.cardList.indexOf(card)
      if (index !== this.activeCardIndex) {
        this.processIndexChange(index)
      }
    } else {
      const newCard = addAutoDeploymentCard(cardId)
      if (newCard) {
        this.cardList.push(newCard)
        this.processIndexChange(this.cardList.length - 1)
        this.setScrollToBottomOnAddCard()
      }
    }
  }

  public addCustomerPaymentsCard(cardId: string) {
    const ldClient = useLaunchDarkly()
    if (!ldClient.variation('fnol-540-payments-timeline-card')) return

    const card = this.cardList.find((a: TimeLineCardModel) => a.forItemId === cardId)
    if (card) {
      const index: number = this.cardList.indexOf(card)
      if (index !== this.activeCardIndex) {
        this.processIndexChange(index)
      }
    } else {
      const newCard = addCustomerPaymentsCard(cardId)
      if (newCard) {
        this.cardList.push(newCard)
        this.processIndexChange(this.cardList.length - 1)
        this.setScrollToBottomOnAddCard()
      }
    }
  }

  public timelineCardEvents: { [key: string]: (...args) => void | null } = {
    ClientClaimSubmitted: this.addClientClaimCompletedCard,
    AutoDeploymentCreated: this.addAutoDeploymentCard,
    CustomerPaymentsSummary: this.addCustomerPaymentsCard,
  }

  private async retrieveNonLegacyTimelineCards(): Promise<boolean> {
    try {
      await useTimelineStore().retrieveTimelineCards(this.jobId)
    } catch (error) {
      this.$store.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar(`Failed to retrieve additional timeline cards for job ${this.jobId}`, 0, true)
      )
    }
    return false
  }
}
</script>

<style>
/* Left side Card */
.timeline-cards .text-gray {
  color: #ababab;
}
.timeline-cards .title-text {
  position: relative;
  cursor: pointer;
  transition: all 0.3s linear;
  margin-bottom: 10px;
  width: 100%;
}
.timeline-cards .title-text.active {
  width: 104%;
}
.timeline-cards .right-arrow:after {
  content: 'keyboard_arrow_right';
  font-family: 'Material Icons';
  font-size: 2.5rem;
  position: absolute;
  color: #999999;
  right: 5px;
  top: 15px;
}
.timeline-cards .title-text h3 {
  background: #f5f5f6;
  padding-right: 30px !important;
  min-height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.timeline-cards .chip-btn {
  position: static;
  display: inline-block;
  width: 100%;
}
.timeline-cards .chip-btn .v-chip {
  background: #009688;
}
.timeline-cards {
  padding-left: 114px;
  transition: 0.3s all linear;
}
.timeline-cards > .v-card,
.timeline-cards > div > .v-card {
  position: relative;
}
.timeline-cards .left-bar {
  width: 1rem;
  left: -2.9rem;
  bottom: -3.4rem;
  top: 1.2rem;
  position: absolute;
  background: #b4b4b4;
}
.timeline-cards > .v-card:last-child .left-bar {
  display: none;
}
.timeline-cards .arrow {
  position: absolute;
  left: -0.5rem;
  top: 1.9rem;
  border: 7px solid black;
  box-sizing: border-box;
  width: 0;
  height: 0;
  box-shadow: -2px 3px 2px 0 rgba(204, 204, 204, 0.4);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-color: transparent transparent #f5f5f6 #f5f5f6;
}
.timeline-cards .date-text {
  color: #999999;
  position: absolute;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 2rem;
  left: -114px;
  font-style: normal;
  top: 17px;
  right: 2rem;
}
.timeline-cards > .v-card .timeline-icon i,
.timeline-cards > div > .v-card .timeline-icon i {
  content: 'email';
  width: 3rem;
  height: 3rem;
  left: -3.9rem;
  top: 1.1rem;
  background: #b4b4b4;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #ffffff;
  text-align: center;
  color: #ffffff;
  box-shadow: none;
  font-family: 'Material Icons';
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  z-index: 1;
}
.purple-bar.v-card .left-bar,
.timeline-cards > div > .purple-bar.v-card .timeline-icon i {
  background: #3f51b5;
}
.yellow-bar.v-card .left-bar,
.timeline-cards > div > .yellow-bar.v-card .timeline-icon i {
  background: #fcc02f;
}
.red-bar.v-card .left-bar,
.timeline-cards > div > .red-bar.v-card .timeline-icon i {
  background: #f44336;
}
.green-bar.v-card .left-bar,
.timeline-cards > div > .green-bar.v-card .timeline-icon i {
  background: #4caf50;
}
.blue-bar.v-card .left-bar,
.timeline-cards > div > .blue-bar.v-card .timeline-icon i {
  background: #2196f3;
}
.timeline-cards:last-child > div > .v-card .left-bar {
  display: none;
}
/*Scrollbar Scroller*/
.page-content-side.vb > .vb-dragger {
  right: 8px;
}
.side-bar-active > .vb-dragger {
  display: none !important;
}
.title-text h3 {
  font-size: 14px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.additional-chips .status-icon {
  position: absolute;
  right: 30px;
  top: 13px;
}
/* desktop-xlarge */
@media all and (min-width: 1601px) and (max-width: 1920px) {
  /*Left Side card change font size in screen 1600 below*/
  .title-text h3 .chip-btn .v-chip {
    font-size: 12px;
  }
  .title-text h3 {
    font-size: 14px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
@media all and (min-width: 841px) and (max-width: 1280px) and (max-aspect-ratio: 4/3) {
  /*Left Side card change font size in screen 1100 below*/
  .chip-btn {
    float: none;
    position: static;
    display: inline-block;
    width: 100%;
  }
  .chip-btn .v-chip {
    margin-left: 0px;
  }
}
/* Responsive cards*/
@media (max-width: 1279px) {
  .timeline-cards {
    padding-left: 95px;
  }
  .timeline-cards .date-text {
    left: -83px;
    width: 46px;
  }
}
</style>
