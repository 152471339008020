<template>
  <div class="pa-2 mb-2 text-center black--text">
    <PaymentDetails :job="job" />
    <Payment :job="job" />
  </div>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import { Component } from 'vue-property-decorator'
import eventBus from '@/common/bus'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import storeGetters from '@/storeGetters'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import Shared from '@/common/shared'
import Payment from '@/components/CustomerToPay/Payment.vue'
import PaymentDetails from '@/components/CustomerToPay/PaymentDetails.vue'

@Component({
  components: { Payment, PaymentDetails },
})
export default class PaymentPreview extends TimeLineItemBase {
  public isCompleted = false
  public store = useCustomerToPayStore()

  public get ansaStripeEnabled(): boolean {
    return !!this.$ld.variation('fnol-558-ansa-stripe-account')
  }

  private mounted() {
    this.store.isPaymentConfirmed = false
    if (this.itemId) {
      this.store.customerToPay = storeGetters.getCustomerToPayByItemId(this.jobId, this.itemId)
    } else {
      this.store.customerToPay = new CustomerToPayModel(this.jobId)

      if (this.job) {
        this.store.customerToPay.statementDescription =
          this.job.jobType === 'US' && this.ansaStripeEnabled ? 'ANSA UK' : 'CET Structures'
        this.store.customerToPay.cardHolderName = this.job.clientFullName
        this.store.customerToPay.cardHolderEmail = this.job.email
        this.store.customerToPay.billingAddress.line1 = this.job.addressLine1
        this.store.customerToPay.billingAddress.line2 = this.job.addressLine2
        this.store.customerToPay.billingAddress.city = this.job.addressLine3
        this.store.customerToPay.billingAddress.state = this.job.addressLine4
        this.store.customerToPay.billingAddress.postcode = this.job.postCode
      }
    }

    eventBus.$on(
      'escalateCTPResponse',
      (escalateCTPResponseModel: EscalateCTPResponseModel, amountNeedToPay: number, emergencyId: string) => {
        this.store.escalateCtpResponseModel = escalateCTPResponseModel
        this.store.escalatedCtpAmount = amountNeedToPay ? Number(Shared.getFormatedCurrency(amountNeedToPay, true)) : 0
        this.store.customerToPay.amount = this.store.escalatedCtpAmount
        this.store.customerToPay.emergencyId = emergencyId
      }
    )
    eventBus.$on('paymentOnClosingJob', (amountNeedToPay: number) => {
      this.store.escalatedCtpAmount = amountNeedToPay ? Number(Shared.getFormatedCurrency(amountNeedToPay, true)) : 0
      this.store.customerToPay.amount = this.store.escalatedCtpAmount
    })
    eventBus.$on(
      'triggerCTPForPolicyChange',
      (escalateCTPResponseModel: EscalateCTPResponseModel, amountNeedToPay: number) => {
        this.store.escalateCtpResponseModel = escalateCTPResponseModel
        this.store.escalatedCtpAmount = amountNeedToPay ? Number(Shared.getFormatedCurrency(amountNeedToPay, true)) : 0
        this.store.customerToPay.amount = this.store.escalatedCtpAmount
      }
    )
  }
}
</script>
