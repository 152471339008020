<template>
  <v-layout class="peril-repeat" wrap>
    <v-flex v-if="addEmergencyIndex > 0" xs12>
      <v-spacer class="primary"></v-spacer>
    </v-flex>
    <v-flex v-if="value" xs12>
      <v-layout wrap>
        <v-flex xs6 pr-2>
          <v-autocomplete
            v-model="value.emergencyTypeId"
            v-validate="'required'"
            :items="emergencyTypes"
            item-value="emergencyId"
            item-text="description"
            label="Emergency Type"
            class="required emergency-type"
            required
            data-vv-name="EmergencyType"
            :error-messages="validationMessage('EmergencyType')"
            :disabled="disabled"
            name="EmergencyType"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pl-2>
          <v-autocomplete
            v-model="value.emergencyDetailId"
            v-validate="'required'"
            :items="emergencyDetailsFiltered"
            item-value="emergencyDetailId"
            item-text="description"
            label="Emergency Detail"
            class="required emergency-detail"
            required
            data-vv-name="EmergencyDetail"
            :error-messages="validationMessage('EmergencyDetail')"
            :disabled="disabled"
            name="EmergencyDetail"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs6 pr-2>
          <v-select
            v-model="value.tradeId"
            v-validate="'required'"
            :items="trades"
            item-value="tradeId"
            item-text="description"
            label="Trade"
            class="required trade-type"
            required
            data-vv-name="Trade"
            :error-messages="validationMessage('Trade')"
            :disabled="disabled || (tradesAutoSelect && !isUserSupervisor)"
            @change="tradeIdChanged"
          ></v-select>
        </v-flex>
        <v-flex xs6 pl-2>
          <DateTimePicker
            ref="dateTimePicker"
            :date-time.sync="getFirstNoticedAtUtc"
            :is-static-location="false"
            place-holder-text="First Noticed"
            :max-date="maxFirstNoticedAtDate"
            :max-time="maxFirstNoticedAtTime"
            :is-current-time="true"
            :is-validation-required="true"
            :show-present-time-icon="true"
            :disabled="disabled"
          />
        </v-flex>
      </v-layout>
      <v-alert
        v-if="
          momentEffectiveDate &&
          getFirstNoticedAtUtc &&
          policyEffectiveWarningEnabled &&
          momentEffectiveDate > getFirstNoticedAtUtc
        "
        :value="true"
        color="red"
        icon="priority_high"
        outline
        class="mb-5 mt-0 mx-2"
        name="PolicyEffectiveError"
      >
        Repudiate as claim is before effective date.
      </v-alert>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DateTimePicker from '@/components/DateTimePicker.vue'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import AddEmergency from '@/models/requests/AddEmergency'
import moment from 'moment'
import PolicyHolderController from '@/api/policyHolderController'
import TradeModel from '@/models/policyHolder/TradeModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import Store from '@/store'
import eventBus from '@/common/bus'
@Component({
  name: 'AddHEEmergency',
  components: {
    DateTimePicker,
  },
})
export default class AddHEEmergency extends Vue {
  @Prop() public value: AddEmergency // do not change name, this is required for v-model databinding to work!
  @Prop() public addEmergencyIndex: number // use for event prop bindings for showing date picker
  @Prop() public disabled: boolean
  @Prop() public effectiveDate: Date | null

  private maxFirstNoticedAtDate = ''
  private emergencyDetails: EmergencyDetailModel[] = []
  private trades: TradeModel[] = []
  private tradesAutoSelect = false
  private maxFirstNoticedAtTime = ''
  private tempMaxFirstNoticedAtTime = '' // temp variable to store max time if today's date is selected

  public get policyEffectiveWarningEnabled(): boolean {
    return !!this.$ld.variation('sfi-764-policy-effective-date-advice')
  }

  public resetAddEmergency() {
    const dateTimePickerSub = this.$refs.dateTimePicker as DateTimePicker
    setTimeout(() => {
      this.$validator.errors.items = []
      if (dateTimePickerSub !== undefined) {
        dateTimePickerSub.$validator.errors.items = []
      }
    }, 0)
  }

  private async created() {
    this.maxFirstNoticedAtDate = moment().format(DateTimePicker.DATE_FORMAT)
    await this.getTradeList()
    this.getEmergencyDetails()
    if (this.emergencyTypes.length === 0) {
      this.setEmergencies()
    }
  }

  private getEmergencyDetails(): void {
    PolicyHolderController.GetEmergencyDetails().then((res: EmergencyDetailModel[]) => {
      this.emergencyDetails = res.filter((e) => !e.isDeleted)
    })
  }

  private async getTradeList() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      await PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          this.trades = res.filter((e) => e.jobType === 'HE' && !e.isDeleted)
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      this.trades = trades.filter((e) => e.jobType === 'HE' && !e.isDeleted)
    }
  }

  private mounted() {
    this.maxFirstNoticedAtDate = moment().format(DateTimePicker.DATE_FORMAT)
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies().filter((e) => !e.isDeleted)
  }

  private setEmergencies() {
    PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  private get emergencyDetailsFiltered(): EmergencyDetailModel[] {
    if (!this.value.emergencyTypeId) {
      return this.emergencyDetails
    }

    const emergencyDetails = this.emergencyDetails.filter((e) => e.typeId === this.value.emergencyTypeId)

    if (emergencyDetails && emergencyDetails.length === 1) {
      // auto-select emergency if has only one record
      this.value.emergencyDetailId = emergencyDetails[0].emergencyDetailId
    }

    return emergencyDetails
  }

  @Watch('value.emergencyTypeId')
  private emergencyTypeIdChanged() {
    const item = this.emergencyTypes.find((e) => e.emergencyId === this.value.emergencyTypeId)
    this.tradesAutoSelect = false
    this.value.tradeId = undefined
    this.tradeIdChanged(this.value.tradeId)
    this.value.emergencyTypeDesc = item ? item.description : ''
    this.value.fileURL = item ? item.fileURL : ''
    this.value.emergencyDetailId = null
  }

  @Watch('value.emergencyDetailId')
  private emergencyDetailIdChanged() {
    const item = this.emergencyDetails.find((e) => e.emergencyDetailId === this.value.emergencyDetailId)
    this.value.emergencyDetailDesc = item ? item.description : ''

    if (item && item.tradeId) {
      this.value.tradeId = item.tradeId
      this.tradeIdChanged(this.value.tradeId)
      this.tradesAutoSelect = true
    } else {
      this.value.tradeId = undefined
      this.tradeIdChanged(this.value.tradeId)
      this.tradesAutoSelect = false
    }
  }

  private tradeIdChanged(newTradeId: number | undefined) {
    const item = this.trades.find((e) => e.tradeId === newTradeId)
    this.value.tradeDesc = item ? item.description : ''
  }

  @Watch('value')
  private emergencyUpdated() {
    this.$emit('input', this.value)
  }

  @Watch('value.firstNoticedAtUtc')
  private dateValueChanged() {
    if (this.getFirstNoticedAtUtc) {
      this.tempMaxFirstNoticedAtTime = moment().format(DateTimePicker.TIME_FORMAT)
      this.maxFirstNoticedAtTime =
        moment(new Date()).format(DateTimePicker.DATE_FORMAT) ===
        moment(this.getFirstNoticedAtUtc).format(DateTimePicker.DATE_FORMAT)
          ? this.tempMaxFirstNoticedAtTime
          : ''
    }
  }

  private get getFirstNoticedAtUtc(): moment.Moment | null {
    return this.value.firstNoticedAtUtc
  }

  private set getFirstNoticedAtUtc(newValue: moment.Moment | null) {
    this.value.firstNoticedAtUtc = newValue
  }

  private get momentEffectiveDate(): moment.Moment | null {
    if (this.effectiveDate) return moment(this.effectiveDate)
    return null
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private get isUserSupervisor(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Finance' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager')
    )
  }
}
</script>

<style scoped>
.policy-logo img {
  width: 100px;
  border: 1px solid #bdbbbb;
  padding: 0px 15px;
}
.peril-repeat >>> .v-messages__wrapper .v-messages__message {
  padding-bottom: 5px;
}
</style>
