import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import TwilioController from '@/api/twilioController'
import { Workspace } from 'twilio-taskrouter'
import { getLogger } from 'loglevel'

const logger = getLogger('@simplifi/twilio-client')

export const useTwilioWorkspace = defineStore('twilio-workspace', () => {
  const initialized = ref<boolean>(false)
  const instance = ref<Workspace>()

  async function init() {
    if (initialized.value) {
      return
    }

    logger.debug('workspace:init')

    const workspaceToken = await TwilioController.GetWorkspaceAccessToken()
    instance.value = new Workspace(workspaceToken)

    initialized.value = true
  }

  return {
    init,
    initialized,
    instance: computed(() => {
      if (!instance.value) {
        throw new Error('Attempted to access workspace before init.')
      }
      return instance.value
    }),
  }
})
