<template>
  <div>
    <div>{{ cardText }}</div>
    <v-chip v-if="card && card.data['stripeAccount']" class="ml-0 mt-3D">{{ card.data['stripeAccount'] }}</v-chip>
  </div>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import TimelineCardModel from '@/models/general/timeline'
import { useTimelineStore } from '@/pinia/timeline'
import Component from 'vue-class-component'

@Component
export default class CustomerPaymentsCard extends TimeLineItemBase {
  public get card(): TimelineCardModel | null {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || null
  }

  public get cardText(): string {
    if (!this.card) {
      return 'No payments found for this job'
    }
    return 'Summary of payments taken by card and via invoice initiated within this claim'
  }
}
</script>
