<template>
  <v-btn
    color="#0070F3"
    class="action-button white--text"
    :small="false"
    :disabled="disabled"
    @click="$emit('click')"
    >{{ isSet ? 'Update' : 'Capture' }}</v-btn
  >
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AgentAssistRetrievalButton extends Vue {
  @Prop() public disabled: boolean
  @Prop() public isSet: boolean
}
</script>

<style scoped>
.action-button {
  font-size: 11px;
  size: 24;
  margin: 0;
}
</style>
