import { render, staticRenderFns } from "./JobEscalationCard.vue?vue&type=template&id=047d85d0&scoped=true"
import script from "./JobEscalationCard.vue?vue&type=script&lang=ts"
export * from "./JobEscalationCard.vue?vue&type=script&lang=ts"
import style0 from "./JobEscalationCard.vue?vue&type=style&index=0&id=047d85d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.9_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_lodas_njaui5j7ox37wcdpl467s5x2le/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047d85d0",
  null
  
)

export default component.exports