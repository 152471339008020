import { AxiosError } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import eventBus from '@/common/bus'
import NotificationModel from '@/models/notification/NotificationModel'

export default class NotificationsController {
  public static async GetNotifications(): Promise<NotificationModel[]> {
    const res = await CallCentreApi.get<NotificationModel[]>('notifications')
    return res.status >= 200 && res.status < 300 && res.data ? res.data : []
  }

  public static async AddUserToNotificationsForJob(jobId: string): Promise<boolean> {
    try {
      const { status } = await CallCentreApi.post(`notifications/job/${jobId}`)
      return status >= 200 && status < 300
    } catch (err) {
      eventBus.$emit('errorHandler', err as AxiosError, false)
      return false
    }
  }

  public static async RemoveUserFromNotificationsForJob(jobId: string): Promise<boolean> {
    try {
      const { status } = await CallCentreApi.delete(`notifications/job/${jobId}`)
      return status >= 200 && status < 300
    } catch (err) {
      eventBus.$emit('errorHandler', err as AxiosError, false)
      return false
    }
  }
}
