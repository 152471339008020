import TimelineCardModel from '@/models/general/timeline'
import TimeLineCardModel from '@/models/TimeLineCardModel'
import { useTimelineStore } from '@/pinia/timeline'
import { useLaunchDarkly } from '@/plugins/launch-darkly'
import moment from 'moment'

const timelineCardEvents: { [key: string]: (cardId: string) => TimeLineCardModel | null } = {
  ClientClaimSubmitted: addClientClaimSubmittedCard,
  AutoDeploymentCreated: addAutoDeploymentCard,
  CustomerPaymentsSummary: addCustomerPaymentsCard,
}

export function generateNonLegacyCard(card: TimelineCardModel) {
  const cards: TimeLineCardModel[] = []

  const addCardFn = timelineCardEvents[card.type]
  if (addCardFn) {
    const cardToAdd = addCardFn(card.id)
    if (cardToAdd) cards.push(cardToAdd)
  }

  return cards
}

export function addCustomerPaymentsCard(cardId: string): TimeLineCardModel | null {
  const ldClient = useLaunchDarkly()
  if (!ldClient.variation('fnol-540-payments-timeline-card')) return null

  const newCard = useTimelineStore().cards.find((x) => x.type === 'CustomerPaymentsSummary' && x.id === cardId)
  if (!newCard) return null

  return new TimeLineCardModel({
    title: newCard.title,
    colour: 'green',
    icon: 'message',
    createdDate: moment(newCard.createdAt),
    forJobId: newCard.jobId,
    forItemId: newCard.id,
    cardBodyComponent: 'CustomerPaymentsCard',
    detailComponent: 'CustomerPaymentsCardPreview',
    cardClass: '',
    cardFooterComponent: undefined,
    forEmergencyId: newCard.data['emergencyId'],
  })
}

export function addClientClaimSubmittedCard(cardId: string): TimeLineCardModel | null {
  const newCard = useTimelineStore().cards.find((x) => x.type === 'ClientClaimSubmitted' && x.id === cardId)
  if (!newCard) {
    return null
  }

  return new TimeLineCardModel({
    title: newCard.title,
    colour: 'green',
    icon: 'message',
    createdDate: moment(newCard.createdAt),
    forJobId: newCard.jobId,
    forItemId: newCard.id,
    cardBodyComponent: `ClientClaimSubmittedCard`,
    detailComponent: `ClientClaimSubmittedPreview`,
    cardClass: '',
    cardFooterComponent: undefined,
  })
}

export function addAutoDeploymentCard(cardId: string): TimeLineCardModel | null {
  const newCard = useTimelineStore().cards.find((x) => x.type === 'AutoDeploymentCreated' && x.id === cardId)
  if (!newCard) {
    return null
  }

  return new TimeLineCardModel({
    title: newCard.title,
    colour: 'green',
    icon: 'message',
    createdDate: moment(newCard.createdAt),
    forJobId: newCard.jobId,
    forItemId: newCard.id,
    cardBodyComponent: `AutoDeploymentCard`,
    detailComponent: `AutoDeploymentCardPreview`,
    cardClass: '',
    cardFooterComponent: undefined,
    forEmergencyId: newCard.data['emergencyId'],
  })
}
