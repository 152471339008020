<template>
  <v-snackbar
    v-show="twilio.callAlert && !twilio.callAlert.isCallFromHari"
    v-model="snackbar"
    class="user-call-notification"
    :timeout="0"
    multi-line
    top
    right
    auto-height
    color="white"
  >
    <v-layout row wrap>
      <v-flex xs8>
        <p class="mb-1">
          <b class="subheadingfont">
            {{ twilio.callAlert ? twilio.callAlert.clientDetails : '' }}
          </b>
        </p>
        <p class="mb-1" :style="getColorStyle">
          <b>{{ twilio.callAlert ? twilio.callAlert.incomingAlertReason : '' }}</b>
        </p>
        <p class="mb-1 body-1 text-green">
          {{ twilio.callAlert ? twilio.callAlert.policyDetails : '' }}
          <v-icon v-if="twilio.callAlert && twilio.callAlert.isVerified" small color="green">check_circle</v-icon>
        </p>
        <p class="mb-1">
          {{ twilio.callAlert ? twilio.callAlert.additionalDetails : '' }}
        </p>
      </v-flex>
      <v-flex class="xs4">
        <img :src="twilio.callAlert ? twilio.callAlert.policyLogoUrl : ''" class="img-responsive" />
      </v-flex>
      <v-flex xs12 class="text-xs-right mt-2">
        <v-btn
          v-if="twilio.callAlert && twilio.callAlert.incomingAlertType === 'Callback Request'"
          fab
          color="green"
          class="call-vibration"
          style="border-radius: 10px"
          @click.native="initiateCallback"
        >
          Start Call
        </v-btn>
        <v-btn
          v-if="twilio.callAlert && twilio.callAlert.incomingAlertType !== 'Callback Request'"
          fab
          color="green"
          class="call-vibration"
          @click.native="onCallReceived"
        >
          <v-icon>call</v-icon>
        </v-btn>
      </v-flex>
      <v-flex v-if="twilio.callAlert && twilio.callAlert.isVerified" xs12 mt-2>
        <span class="auto-dpa">Customer has completed Auto DPA</span>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { useTwilioStore } from '@/pinia/twilio/twilio'
import eventBus from '@/common/bus'

@Component
export default class IncomingCallTaskAlertV2 extends Vue {
  public readonly twilio = useTwilioStore()
  private snackbar = true

  private get getColorStyle() {
    return 'color:' + this.$vuetify.theme.secondary + ';'
  }

  private onCallReceived() {
    eventBus.$emit('answerIncomingCall')
  }

  private initiateCallback() {
    eventBus.$emit('initiateCustomerCallback')
  }
}
</script>

<style scoped>
.user-call-notification >>> .snack__content .v-btn {
  margin: 0px;
  margin-left: 8px;
  padding: 7px;
}
.user-call-notification {
  color: #000;
  margin-top: 35px;
  margin-right: 100px;
}
.user-call-notification >>> .snack__content {
  padding-bottom: 16px;
}
.user-call-notification >>> .snack__wrapper {
  max-width: 430px;
}
.img-responsive {
  max-height: 80px;
  max-width: 80px;
  width: auto;
  height: auto;
  float: right;
}
.call-vibration {
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.auto-dpa {
  background: lightgreen;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 13px;
}
</style>
