<template>
  <v-flex xs12 class="px-4 pb-3">
    <span v-if="!store.isSendInvoice">
      <AgentAssistTextField
        label="Card number"
        :detail="details.cardNumber"
        :disabled="retrievalDisabled"
        @retrieve="retrieveCardNumber"
      />
      <AgentAssistTextField
        label="Expiry date"
        :detail="details.expiryDate"
        :disabled="retrievalDisabled"
        @retrieve="retrieveExpiryDate"
      />
      <AgentAssistTextField label="CVC" :detail="details.cvc" :disabled="retrievalDisabled" @retrieve="retrieveCVC" />
      <div v-if="twilioError" class="red--text text-center my-2"
        ><p>{{ twilioError.description }}</p> <p>{{ twilioError.nextSteps }}</p></div
      >
      <div v-if="!store.paymentError" class="text-right">
        <v-btn :disabled="store.isLoading" color="error" class="mr-0 btn-payment white--text" @click="cancel">
          Cancel
        </v-btn>
        <v-btn
          :color="brandColour"
          class="mr-0 btn-payment white--text"
          :disabled="retrievalIncomplete || store.isLoading"
          :loading="store.isLoading"
          @click="completeRetrieval"
        >
          Capture Payment
        </v-btn>
      </div>
      <div v-else>
        <div class="red--text text-center my-2"
          ><p>{{ store.paymentError.description }}</p> <p>{{ store.paymentError.nextSteps }}</p></div
        >
        <div class="text-right">
          <v-btn color="error" class="mr-0 btn-payment white--text" @click="retry"> Retry </v-btn>
          <v-btn
            v-if="store.paymentError.enablePaymentLink"
            :color="brandColour"
            class="mr-0 btn-payment white--text"
            @click="onInvoiceClick"
          >
            Invoice
          </v-btn>
        </div>
      </div>
    </span>
    <PaymentInvoice v-else :job="job" :call-sid="customerCallSid" @cancelInvoice="cancel" />
  </v-flex>
</template>

<script lang="ts">
import TwilioController from '@/api/twilioController'
import { AssistedPayCaptureType } from '@/common/enums'
import AgentAssistSignalRWatcher from './AgentAssistSignalRWatcher'
import AgentAssistCardDetail from '@/models/twilio/assistedPay/AgentAssistCardDetail'
import TwilioUpdateDTO from '@/api/models/assistedPay/TwilioUpdateDTO'
import Vue from 'vue'
import { FormatDate, FormatPaymentCard, FormatCVC } from '@/common/StringFormatting'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AgentAssistTextField from './AgentAssistTextField.vue'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import PaymentsController from '@/api/payments-controller'
import TwilioPaymentMethodDTO from '@/api/models/assistedPay/TwilioPaymentMethodDTO'
import Job from '@/models/Job'
import PaymentInvoice from '../PaymentInvoice.vue'
import { colours } from '@/common/themes'
import { TwilioPayError } from '@/models/customerToPay/TwilioPayError'

@Component({
  components: { AgentAssistTextField, PaymentInvoice },
})
export default class AgentAssist extends Vue {
  @Prop() public callSid: string
  @Prop() private job: Job | null
  public details = {
    cardNumber: new AgentAssistCardDetail({
      format: FormatPaymentCard,
      type: AssistedPayCaptureType.CardNumber,
    }),
    expiryDate: new AgentAssistCardDetail({
      format: FormatDate,
      type: AssistedPayCaptureType.ExpiryDate,
    }),
    cvc: new AgentAssistCardDetail({
      format: FormatCVC,
      type: AssistedPayCaptureType.CVC,
    }),
  }
  public twilioError: TwilioPayError | null = null
  public brandColour = colours.brand

  private customerCallSid: string | null = null
  private signalRWatcher: AgentAssistSignalRWatcher | null = null
  private detailBeingUpdated: AgentAssistCardDetail | null = null
  public store = useCustomerToPayStore()

  public get retrievalIncomplete() {
    return !this.details.cardNumber.isSet || !this.details.expiryDate.isSet || !this.details.cvc.isSet
  }
  public get retrievalDisabled() {
    return !!this.detailBeingUpdated || !this.store.paymentSid || this.store.paymentError || this.store.isLoading
  }

  private mounted() {
    if (!this.store.isPaymentConfirmed) {
      this.initiate()
    }
  }

  private async destroyed() {
    if (this.store.paymentSid && this.customerCallSid && !this.store.isPaymentConfirmed && this.job) {
      await PaymentsController.Cancel({
        callSid: this.customerCallSid,
        paymentSid: this.store.paymentSid,
        idempotencyKey: this.store.idempotencyKey,
        jobId: this.job.id,
      })
    }

    this.disconnectSignalR()
  }

  private async initiate() {
    const callSidResponse = await TwilioController.RetrieveCustomerCallSid(this.callSid)
    if (callSidResponse.callSid && this.job) {
      this.customerCallSid = callSidResponse.callSid
      this.initiateAgentAssist()
    } else {
      this.twilioError = {
        description: 'Unable to retrieve customer call SID.',
        nextSteps: 'You must be on a call to capture a payment.',
      }
    }
  }

  public async retrieveCardNumber() {
    if (this.store.paymentSid && this.customerCallSid && this.job) {
      this.clearErrorMessage()
      const res = await this.details.cardNumber.update(
        this.customerCallSid,
        this.store.paymentSid,
        this.store.idempotencyKey,
        this.job.id
      )
      this.updateProgressState(res, this.details.cardNumber)
    }
  }

  public async retrieveExpiryDate() {
    if (this.store.paymentSid && this.customerCallSid && this.job) {
      this.clearErrorMessage()
      const res = await this.details.expiryDate.update(
        this.customerCallSid,
        this.store.paymentSid,
        this.store.idempotencyKey,
        this.job.id
      )
      this.updateProgressState(res, this.details.expiryDate)
    }
  }

  public async retrieveCVC() {
    if (this.store.paymentSid && this.customerCallSid && this.job) {
      this.clearErrorMessage()
      const res = await this.details.cvc.update(
        this.customerCallSid,
        this.store.paymentSid,
        this.store.idempotencyKey,
        this.job.id
      )
      this.updateProgressState(res, this.details.cvc)
    }
  }

  private async updateProgressState(success: boolean, detail: AgentAssistCardDetail) {
    if (!success) {
      this.twilioError = {
        description: 'Unable to start retrieving details.',
        nextSteps: 'Please try again.',
      }
      return
    }

    this.detailBeingUpdated = detail
  }

  public async cancel() {
    if (this.store.paymentSid && this.customerCallSid && !this.store.isPaymentConfirmed && this.job) {
      await PaymentsController.Cancel({
        callSid: this.customerCallSid,
        paymentSid: this.store.paymentSid,
        idempotencyKey: this.store.idempotencyKey,
        jobId: this.job.id,
      })
    }

    this.details.cardNumber.reset()
    this.details.expiryDate.reset()
    this.details.cvc.reset()
    this.store.reset()
    this.detailBeingUpdated = null
    this.clearErrorMessage()
  }

  public async completeRetrieval() {
    if (this.store.paymentSid && this.customerCallSid && this.job) {
      this.clearErrorMessage()
      const res = await PaymentsController.Complete({
        callSid: this.customerCallSid,
        paymentSid: this.store.paymentSid,
        idempotencyKey: this.store.idempotencyKey,
        jobId: this.job.id,
      })
      if (!res) {
        this.twilioError = { description: 'Unable to complete assisted payment.', nextSteps: 'Please try again.' }
        return
      }

      this.store.isLoading = true
    }
  }

  public clearErrorMessage() {
    this.twilioError = null
  }

  public onInvoiceClick() {
    useCustomerToPayStore().isSendInvoice = true
  }

  private handleTwilioUpdate(error: TwilioPayError | null, payload: TwilioUpdateDTO) {
    if (error) {
      this.twilioError = error
      if (this.detailBeingUpdated) {
        this.detailBeingUpdated.reset()
        this.detailBeingUpdated = null
      }
    } else {
      if (this.detailBeingUpdated && this.detailBeingUpdated.type === payload.captureType) {
        this.detailBeingUpdated.setValue(payload.newValue)
        if (!payload.partialResult) {
          this.detailBeingUpdated.isSet = true
          this.detailBeingUpdated = null
        }
      }
    }
  }

  private async handlePaymentCompleted(error: TwilioPayError | null, payload: TwilioPaymentMethodDTO) {
    if (!payload.isSuccessful || !this.customerCallSid || !this.job) {
      this.twilioError = error ?? {
        description: 'There was an error processing the payment.',
        nextSteps: 'Please try again.',
      }
      this.store.isLoading = false
    } else {
      this.store.confirmPayment(this.customerCallSid, payload.paymentMethodId, this.job)
      this.disconnectSignalR()
      this.$emit('resumeCallRecording')
    }
  }

  public async retry() {
    if (this.job && this.customerCallSid) {
      this.details.cardNumber.reset()
      this.details.expiryDate.reset()
      this.details.cvc.reset()
      this.clearErrorMessage()

      this.initiateAgentAssist()
    }
  }

  private disconnectSignalR() {
    if (this.signalRWatcher) {
      this.signalRWatcher.off('updateReceived', this.handleTwilioUpdate)
      this.signalRWatcher.off('paymentMethodReceived', this.handlePaymentCompleted)
      this.signalRWatcher.disconnect()
    }
  }

  private async initiateAgentAssist() {
    if (this.customerCallSid && this.job) {
      await useCustomerToPayStore().initiatePayment(this.customerCallSid, this.job)

      if (this.store.paymentInitiated) {
        this.signalRWatcher = new AgentAssistSignalRWatcher()
        this.signalRWatcher.on('updateReceived', this.handleTwilioUpdate)
        this.signalRWatcher.on('paymentMethodReceived', this.handlePaymentCompleted)
      } else {
        this.twilioError = { description: 'Unable to start assisted payment.', nextSteps: 'Please send an invoice.' }
      }
    }
  }
}
</script>
