import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import EventEmitter from 'events'
import TwilioUpdateDTO from '@/api/models/assistedPay/TwilioUpdateDTO'
import { logger } from '@/plugins/datadog'
import TwilioPaymentMethodDTO from '@/api/models/assistedPay/TwilioPaymentMethodDTO'
import { PaymentErrorResponse } from '@/api/models/customerToPay/payment-error-response'

const UPDATE_RECEIVED_HANDLER = 'UpdateReceived'
const PAYMENT_COMPLETED_HANDLER = 'PaymentCompleted'

export default class AssistedPaySignalRWatcher extends EventEmitter {
  public constructor() {
    super()
    this.signalRHub = new SignalRHubConnection('TwilioAssistedPay')
    this.connect()
  }

  public disconnect() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private signalRHub: SignalRHubConnection

  private connect() {
    this.signalRHub.addHandler(
      UPDATE_RECEIVED_HANDLER,
      (error: PaymentErrorResponse | null, payload: TwilioUpdateDTO) => {
        logger.info(`${UPDATE_RECEIVED_HANDLER}HandlerTriggered`)
        this.emit('updateReceived', error, payload)
      }
    )

    this.signalRHub.addHandler(
      PAYMENT_COMPLETED_HANDLER,
      (error: PaymentErrorResponse | null, payload: TwilioPaymentMethodDTO) => {
        logger.info(`${PAYMENT_COMPLETED_HANDLER}HandlerTriggered`)
        this.emit('paymentMethodReceived', error, payload)
      }
    )

    this.signalRHub.connect()
  }
}
