<template>
  <div>
    <v-menu
      v-model="showDialpad"
      offset-y
      :close-on-content-click="false"
      :nudge-width="250"
      :max-width="250"
      :z-index="999"
      absolute
      left
      content-class="dialpad-box"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          large
          flat
          :class="twilio.workerInQueue || twilio.inCall ? '' : 'offQueue'"
          v-on="on"
          @click.native="dialpadClick"
        >
          <v-badge overlap :color="twilio.inCall ? 'green' : 'secondary'">
            <v-icon v-if="!twilio.workerInQueue && twilio.inCall" slot="badge" small>call</v-icon>
            <v-icon v-else-if="twilio.workerInQueue" slot="badge" small>call_end</v-icon>
            <v-avatar flat :size="34">
              <v-icon v-if="twilio.workerInQueue" dark>dialpad</v-icon>
              <v-tooltip v-else bottom>
                <template #activator="{ on }">
                  <v-icon dark v-on="on">dialpad</v-icon>
                </template>
                <span>
                  {{ twilio.workerNotInQueueReason ?? 'Not Connected' }}
                </span>
              </v-tooltip>
            </v-avatar>
          </v-badge>
          <span class="line-arrow secondary"></span>
          <span class="n-arrow"></span>
        </v-btn>
      </template>
      <v-card v-show="!twilio.workerInWrapUp" class="dialpad">
        <v-container
          py-2
          px-0
          fluid
          :class="
            twilio.inCall && !callForward && !showDialpadInCall
              ? 'call-started'
              : '' && showDialpadInCall
              ? ' show-dialpad'
              : ''
          "
        >
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <PhoneNumber
                :passed-phone-number="phoneNumber"
                :add-digits="addDigits"
                class="twilio-phonenumber"
                @phoneNumberUpdated="onPhoneNumberUpdated"
                @digits-added="onDigitsAdded"
              />
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
          <v-layout row wrap text-xs-center>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('1')">1</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('2')">2</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('3')">3</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('4')">4</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('5')">5</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('6')">6</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('7')">7</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('8')">8</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('9')">9</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('*')">*</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn
                v-mouse-hold="zeroHoldConfig"
                icon
                large
                class="dialpad-add-icon"
                @click.native="sendDigit(showPlus ? '+' : '0')"
              >
                <template v-if="showPlus">+</template>
                <template v-else>
                  0
                  <v-icon small>add</v-icon>
                </template>
              </v-btn>
            </v-flex>
            <v-flex xs4>
              <v-btn icon large @click.native="sendDigit('#')">#</v-btn>
            </v-flex>
            <v-flex xs4>
              <v-tooltip v-if="showDialpadInCall" bottom>
                <template #activator="{ on }">
                  <v-btn icon large v-on="on" @click.native="showDialpadInCall = false">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                </template>
                <span>Back to call</span>
              </v-tooltip>
              <v-tooltip v-if="callForward && !transferCallSid" bottom>
                <template #activator="{ on }">
                  <v-btn icon large class="green white--text" v-on="on" @click.native="backToOngoingCall">
                    <img src="@/assets/images/icons/call/return-to-call.svg" alt="" />
                  </v-btn>
                </template>
                <span>Return to Call</span>
              </v-tooltip>
              <v-tooltip v-if="callForward && transferCallSid" bottom>
                <template #activator="{ on }">
                  <v-btn icon large class="green white--text" v-on="on" @click.native="endForwardCall">
                    <img src="@/assets/images/icons/call/return-to-call.svg" alt="" />
                  </v-btn>
                </template>
                <span>Return to Call</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs4>
              <v-btn
                v-show="!callForward"
                icon
                large
                :color="!phoneNumber || showDialpadInCall ? 'grey' : 'green' + ' white--text'"
                :disabled="!phoneNumber || showDialpadInCall || makingCallInProgress"
                :class="!phoneNumber || showDialpadInCall ? 'grey' : 'green' + ' white--text'"
                @click.native="makeCall"
              >
                <v-icon>call</v-icon>
              </v-btn>
              <v-btn
                v-show="callForward && !transferCallSid"
                icon
                large
                :color="!phoneNumber ? 'grey' : 'blue' + ' white--text'"
                :disabled="!phoneNumber || callForwardingDisabled"
                @click.native="makeForwardCall"
              >
                <v-icon>call</v-icon>
              </v-btn>
              <v-tooltip v-show="callForward && transferCallSid" bottom>
                <template #activator="{ on }">
                  <v-btn icon large :color="'orange white--text'" v-on="on" @click.native="backToOngoingCall">
                    <img src="@/assets/images/icons/call/warm-transfer.svg" alt="" />
                  </v-btn>
                </template>
                <span>Warm Transfer</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs4>
              <v-tooltip v-show="callForward && transferCallSid" bottom>
                <template #activator="{ on }">
                  <v-btn icon large class="red" v-on="on" @click.native="coldTransfer">
                    <img src="@/assets/images/icons/call/cold-transfer.svg" alt="" />
                  </v-btn>
                </template>
                <span>Cold Transfer</span>
              </v-tooltip>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="call-counter">
            <v-flex xs12 text-xs-right>
              <v-tooltip v-if="showReturnToQueueButton" bottom>
                <template #activator="{ on }">
                  <v-btn icon medium color="green white--text" v-on="on" @click.native="returnCallerToQueue">
                    <img src="@/assets/images/icons/call/return-to-queue.svg" alt="" />
                  </v-btn>
                </template>
                <span>Return To Queue</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon medium color="darkgrey white--text" v-on="on" @click.native="showDialpadInCall = true">
                    <v-icon>dialpad</v-icon>
                  </v-btn>
                </template>
                <span>Dialpad</span>
              </v-tooltip>
            </v-flex>
            <v-flex xs12>
              <div class="label-counter text-xs-center white--text">
                <div class="mb-2 number">{{ twilio.callingLabel }}</div>
                <div class="mb-2">{{ twilio.callPolicyName }}</div>
                <div class="mb-2">
                  <v-icon color="white" small>timer</v-icon>
                  &nbsp;
                  <Counter ref="twilioCallCounter"></Counter>
                </div>
                <div v-show="twilio.callOnHold" class="mb-2">On Hold</div>
                <div class="mb-2">Dialling</div>
              </div>
            </v-flex>
            <v-flex xs12 text-xs-center>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon large color="red white--text" v-on="on" @click.native="callEnd">
                    <v-icon>call_end</v-icon>
                  </v-btn>
                </template>
                <span>End Call</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon large color="orange white--text" v-on="on" @click.native="holdButtonClicked">
                    <v-icon v-show="!twilio.callOnHold">pause_circle_outline</v-icon>
                    <v-icon v-show="twilio.callOnHold">pause_circle_filled</v-icon>
                  </v-btn>
                </template>
                <span>Hold</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    large
                    color="grey white--text"
                    :class="twilio.callMuted ? 'text--lighten-1' : 'darken-2 text--lighten-3'"
                    v-on="on"
                    @click.native="muteButtonClicked"
                  >
                    <v-icon>keyboard_voice</v-icon>
                  </v-btn>
                </template>
                <span>{{ twilio.callMuted ? 'Unmute' : 'Mute' }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn icon large color="blue white--text" v-on="on" @click.native="forwardButtonClicked">
                    <v-icon>phone_forwarded</v-icon>
                  </v-btn>
                </template>
                <span>Forward</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <v-card v-show="twilio.workerInWrapUp" absolute top right>
        <v-container py-2 px-0 fluid>
          <v-layout row wrap text-xs-left>
            <v-flex xs12 py-2 px-3>
              <b class="secondary--text">
                <v-icon color="secondary" small>timer</v-icon>
                <span class="countdown">
                  {{ twilio.workerWrapUpCountdown }}
                </span>
              </b>
              <span>Wrap-up time remaining</span>
            </v-flex>
            <v-flex xs12 text-xs-center>
              <v-btn small color="success" @click="returnToQueue">
                <v-icon>group_add</v-icon>
                &nbsp; Return to Queue
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-menu>
    <!-- link phone call with job dialog -->
    <v-dialog
      v-if="phonecallAssignDialog"
      v-model="phonecallAssignDialog"
      content-class="v-dialog--scrollable"
      max-width="650"
      persistent
    >
      <PhoneCallJobsAssign
        :job-item-list="jobList"
        :unlinked-phone-call="unlinkedPhoneCallDetail"
        :is-data-loaded="isDataLoaded"
        :is-via-twillio="true"
        @onJobAssignClose="closeJobAssignDialog"
        @linkPhoneCallWithJob="updateCallDetail"
      ></PhoneCallJobsAssign>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import TwilioController from '@/api/twilioController'
import * as Twilio from '@twilio/voice-sdk'
import { ConferenceOptions } from 'twilio-taskrouter'
import Store from '@/store'
import StoreMutations from '@/storeMutations'
import PhoneNumber from '@/components/twilio/PhoneNumber.vue'
import Worker from '@/models/twilio/Worker'
import SearchItemModel from '@/models/policyHolder/SearchItemModel'
import OneClickPolicyHolderModel from '@/models/policyHolder/OneClickPolicyHolderModel'
import eventBus from '@/common/bus'
import Counter from '@/components/twilio/Counter.vue'
import type { MouseHoldConfig } from '@/directives/mouseHold'
import '@/directives/mouseHold'
import type CallParameters from '@/models/twilio/CallParameters'
import type UserModel from '@/models/user/UserModel'
import StoreGetters from '@/storeGetters'
import PhoneCallJobsAssign from '@/components/PhoneCallJobsAssign.vue'
import type JobHeader from '@/models/JobHeader'
import UnlinkedPhoneCallsModel from '@/models/claim/UnlinkedPhoneCallsModel'
import { useTwilioStore } from '@/pinia/twilio/twilio'
import { getLogger } from 'loglevel'
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors'

@Component({
  components: {
    PhoneNumber,
    Counter,
    PhoneCallJobsAssign,
  },
})
export default class TwilioClientV2 extends Vue {
  private readonly logger = getLogger('@simplifi/twilio-client')

  private readonly twilio = useTwilioStore()

  public readonly zeroHoldConfig: MouseHoldConfig = {
    held: () => {
      this.showPlus = true
    },
    released: () => {
      this.showPlus = false
    },
  }

  // These two are used for some ridiculous bi-directional data binding.
  public addDigits = ''
  public phoneNumber = ''

  public callForward = false
  public makingCallInProgress = false

  public showDialpad = false
  public showDialpadInCall = false

  private callParameters: CallParameters | null = null
  public callForwardingDisabled = false

  public transferCallSid = ''

  public showReturnToQueueButton = false

  public showPlus = false

  // link phone call with job
  public phonecallAssignDialog = false
  public jobList: JobHeader[] = []
  public isDataLoaded = false
  public unlinkedPhoneCallDetail: UnlinkedPhoneCallsModel = new UnlinkedPhoneCallsModel()

  private hasSupervisorJoinedConferenceCall = false

  public onPhoneNumberUpdated(phoneNumber: string): void {
    this.phoneNumber = phoneNumber
  }

  public onDigitsAdded(): void {
    this.addDigits = ''
  }

  public async makeCall(): Promise<void> {
    const call = await this.twilio.makeCall({
      to: this.callParameters?.to ?? this.phoneNumber,
      jobId: this.callParameters?.jobId,
      contractorId: this.callParameters?.contractorId,
      emergencyId: this.callParameters?.emergencyId,
    })

    this.makingCallInProgress = true
    ;(this.$refs.twilioCallCounter as Counter).startCounter()

    call.on('accept', this.onCallAccepted)
    this.onCallConnect()
  }

  public async holdButtonClicked(): Promise<void> {
    await this.twilio.toggleCallHold()
  }

  public async callEnd() {
    await this.twilio.endCall()
  }

  public sendDigit(digit: string): void {
    if ((this.showDialpadInCall || (this.callForward && this.transferCallSid)) && this.twilio.call) {
      this.twilio.call.sendDigits(digit)
    } else {
      this.addDigits += digit
    }
  }

  public dialpadClick(): void {
    this.showDialpad = true
  }

  public muteButtonClicked(): void {
    this.twilio.toggleCallMute()
  }

  public async answerCall(): Promise<void> {
    const callInfo = await this.twilio.answerCall()

    ;(this.$refs.twilioCallCounter as Counter).startCounter()

    if (callInfo.insurerId && callInfo.policyId && callInfo.callType !== 'ComplaintCall' && !callInfo.jobId) {
      const phSearch = new SearchItemModel()
      phSearch.insurer = callInfo.insurerId
      phSearch.policy_id = callInfo.policyId
      eventBus.$emit('openHEWizardForPH', phSearch)
    } else if (callInfo.jobId) {
      this.$router.push({
        name: 'job',
        params: {
          jobId: callInfo.jobId,
        },
      })
    }
    // filters the manual policy picker when policyscheduleid is known
    if (callInfo.policyScheduleId && callInfo.callType !== 'ComplaintCall' && !callInfo.jobId) {
      eventBus.$emit('policyScheduleIdHEFilter', callInfo.policyScheduleId)
    }
    if (callInfo.callType === 'FNOL' && callInfo.passToThirdParty === true) {
      // the task is an FNOL task and came through as 'pass to 3rd party', show the returnCallerToQueue button
      this.showReturnToQueueButton = true
    }
  }

  public async initiateCallback(): Promise<void> {
    if (this.twilio.reservation?.status === 'pending') {
      await this.twilio.reservation.accept()

      // get the phone number to call from the task
      if (this.twilio.reservation.task.attributes.callbackPhoneNumber) {
        this.phoneNumber = this.twilio.reservation.task.attributes.callbackPhoneNumber
      } else {
        eventBus.$emit(
          'errorHandler',
          `Error initiating callback for task ${this.twilio.reservation.task.sid}, missing callback number. Task Attributes: ${this.twilio.reservation.task.attributes}.`
        )
        return
      }
      this.callParameters = null

      const call = await this.twilio.makeCall({
        to: this.phoneNumber,
        taskSid: this.twilio.reservation.task.sid,
        policyName: this.twilio.reservation.task.attributes.policyName,
      })

      call.on('accept', this.onCallAccepted)

      this.onCallConnect()
      ;(this.$refs.twilioCallCounter as Counter).startCounter()
      this.twilio.clearCallAlert()

      if (this.twilio.reservation.task.attributes.jobId) {
        this.$router.push({
          name: 'job',
          params: {
            jobId: this.twilio.reservation.task.attributes.jobId,
          },
        })
      }
      if (
        this.twilio.reservation.task.attributes.callType === 'FNOL' &&
        this.twilio.reservation.task.attributes.passToThirdParty === true
      ) {
        // the task is an FNOL task and came through as 'pass to 3rd party', show the returnCallerToQueue button
        this.showReturnToQueueButton = true
      }
    }
  }

  public async returnToQueue() {
    if (!this.twilio.call) {
      await this.twilio.returnWorkerToQueue()
    }
  }

  public async forwardButtonClicked(): Promise<void> {
    if (!this.twilio.callOnHold) {
      await this.twilio.toggleCallHold()
    }
    this.callForward = true
  }

  public async makeForwardCall(): Promise<void> {
    if (!this.twilio.call) {
      throw new Error('Attempted to forward call without an active connection.')
    }

    // get the current call sid, the new number selected and pass them to the api
    this.callForwardingDisabled = true
    setTimeout(() => {
      this.callForwardingDisabled = false
    }, 1000)

    if (this.twilio.call.parameters.CallSid && this.phoneNumber) {
      try {
        this.transferCallSid = await TwilioController.AddNewParticipantToConference(
          this.twilio.call.parameters.CallSid,
          this.phoneNumber
        )
      } catch (error) {
        eventBus.$emit('errorHandler', error)
      }
    }
  }

  public async endForwardCall(): Promise<void> {
    if (!this.twilio.call) {
      throw new Error('Attempted to end forward call without an active connection.')
    }

    if (this.twilio.call.parameters.CallSid && this.transferCallSid) {
      try {
        await TwilioController.RemoveParticipantFromConference(
          this.twilio.call.parameters.CallSid,
          this.transferCallSid
        )
        this.transferCallSid = ''
        this.backToOngoingCall()
      } catch (error) {
        eventBus.$emit('errorHandler', error)
      }
    }
  }

  public async backToOngoingCall(): Promise<void> {
    if (!this.twilio.callOnHold) {
      throw new Error('Attempted to return to ongoing call, but call is not on hold.')
    }

    await this.twilio.toggleCallHold()
    this.callForward = false
    this.transferCallSid = ''
  }

  public async coldTransfer(): Promise<void> {
    if (!this.twilio.callOnHold) {
      throw new Error('Attempted to return to ongoing call, but call is not on hold.')
    }

    const callSid = this.twilio.call?.parameters.CallSid
    if (!callSid) {
      throw new Error('Attempted to transfer call without call sid')
    }

    await this.twilio.toggleCallHold()

    this.transferCallSid = ''
    this.twilio.device.disconnectAll()
    await TwilioController.HandleAgentLeavingConference(callSid)
    await this.twilio.setWorkerActivity(Store.Instance.state.Environment.TwilioWrapUpActivitySid, 'Cold transfer')
  }

  public async returnCallerToQueue(): Promise<void> {
    if (!this.twilio.device || !this.twilio.call || !this.twilio.reservation) {
      throw new Error('Attempted to return caller to queue without an active connection.')
    }

    const callSid = this.twilio.call.parameters.CallSid
    const taskSid = this.twilio.reservation.task.sid
    this.showReturnToQueueButton = false

    try {
      await TwilioController.ReturnCallerToQueue(callSid, taskSid)
      this.twilio.device.disconnectAll()
    } catch (error) {
      eventBus.$emit('errorHandler', error)
      this.showReturnToQueueButton = true // Allow them to try pressing the button again?
    }
  }

  public closeJobAssignDialog(callSid?: string) {
    this.isDataLoaded = false
    this.jobList = []
    this.phonecallAssignDialog = false
  }

  public updateCallDetail(jobId: string, callSid: string) {
    TwilioController.UpdateCallDetail(callSid, jobId)
      .then((res: boolean) => {
        if (res) {
          this.closeJobAssignDialog(callSid)
        }
      })
      .catch(() => {
        eventBus.$emit('errorHandler', 'Error updating call detail, please try again', true)
      })
  }

  public async mounted() {
    await this.twilio.init()
    this.setUpTwilioClientDevice()

    // register for the events
    eventBus.$on('callIconClick', (params: CallParameters) => {
      if (params.to) {
        setTimeout(() => {
          this.showDialpad = true
          this.phoneNumber = params.to
          this.callParameters = params
        }, 0)
      }
    })

    eventBus.$on('supervisorJoinedConferenceCall', () => {
      this.hasSupervisorJoinedConferenceCall = true
    })

    eventBus.$on('answerIncomingCall', () => this.answerCall())
    eventBus.$on('initiateCustomerCallback', () => this.initiateCallback())
  }

  private async setUpTwilioClientDevice(): Promise<void> {
    this.twilio.device.on('incoming', (call: Twilio.Call) => {
      this.logger.debug('device:incoming')
      // accept the incoming connection and start two-way audio
      this.twilio.setCall(call)
      call.accept(this.twilio.audioConfig)
      this.setUpTwilioConnection()
      this.onCallConnect()
    })
  }

  private async onCallAccepted(call: Twilio.Call): Promise<void> {
    if (!call) {
      return
    }

    this.logger.debug('call:accepted')

    if (call.parameters.CallSid) {
      StoreMutations.updateCurrentCallSid(call.parameters.CallSid)
      eventBus.$emit('sendInitialDataToWizard')
    }
    if (call.parameters.From) {
      await this.$store.dispatch('updatePolicyScheduleFromDeliveryNumber', call.parameters.From)
    }
  }

  private async onCallConnect() {
    if (!this.twilio.call) {
      return
    }

    this.logger.debug('call:connected')

    this.twilio.clearCallAlert()
    this.makingCallInProgress = false

    // If the worker is idle when making an outbound call, update it to Busy
    // Workers can't have their status changed while there are pending reservations.
    // When connecting an incoming call, this callback happens before the reservation is accepted.
    if (this.twilio.worker.activity.name === 'Idle' && !this.twilio.reservation) {
      await this.twilio.setWorkerActivity(Store.Instance.state.Environment.TwilioBusyActivitySid, 'Call connected')
    }

    this.twilio.call.on('disconnect', this.onCallDisconnect)
    this.twilio.call.on('reconnecting', this.onCallReconnecting)
    this.twilio.call.on('reconnected', this.onCallReconnected)
  }

  private async onCallReconnecting(twilioError: TwilioError): Promise<void> {
    this.logger.debug('call:reconnecting')
    const errorMessage = `Attempting to reconnect call due to an error: ${twilioError}`

    eventBus.$emit('errorHandler', errorMessage)
  }

  private async onCallReconnected(): Promise<void> {
    this.logger.debug('call:reconnected')
    this.$emit('clearTwilioSnackbar')
  }

  private async onCallDisconnect(): Promise<void> {
    this.logger.debug('call:disconnected')

    // call has ended
    // reset the controls
    this.showDialpadInCall = false
    this.callForward = false
    this.transferCallSid = ''
    this.twilio.clearCall()
    this.showReturnToQueueButton = false

    // make sure the phone number field is cleared
    this.phoneNumber = ''

    const callSid = StoreGetters.getCurrentCallSid()
    // trigger auto deployment while agent ends a call
    if (this.twilio.reservation?.task.attributes.callType === 'FNOL') {
      TwilioController.TriggerAutoDeployment(callSid)
        .then((res: boolean) => {
          if (res) {
            // trigger auto deployment
            eventBus.$emit('startAutoDeployment')
          }
        })
        .catch(() => {
          eventBus.$emit('errorHandler', 'Error triggering contractor auto deployment, please try again', true)
        })
    }

    // make sure the current reservation, alert and call are cleared
    this.twilio.clearReservation()
    this.twilio.clearCall()

    // supervisor has joined conference call - popup to link phone call with job should not be displayed
    if (!this.hasSupervisorJoinedConferenceCall) {
      try {
        const res = await TwilioController.VerifyCallDetailForLinkJob(callSid)
        if (Array.isArray(res) && res.length >= 0) {
          // call not linked with job
          // open popup to link phone call with job or mark as noise
          this.unlinkedPhoneCallDetail.callSid = callSid
          this.jobList = res as JobHeader[]
          this.phonecallAssignDialog = true
          this.isDataLoaded = true
          setTimeout(() => {
            // popup should only be available for 15 seconds
            this.closeJobAssignDialog(callSid)
          }, this.linkPhoneCallTimeout)
        }
      } catch {
        eventBus.$emit('errorHandler', 'Error verifying call detail, please try again', true)
      }
    } else {
      this.hasSupervisorJoinedConferenceCall = false
    }

    // clear current call sid in the store
    StoreMutations.updateCurrentCallSid('')
    eventBus.$emit('sendInitialDataToWizard')    
    StoreMutations.clearCurrentCallPolicySchedule()

    // reset the counter
    this.clearCounter()
    this.$emit('clearTwilioSnackbar')

    // if the worker is Busy after making the outbound call, put them in wrap up
    if (this.twilio.worker.activity.name === 'Busy') {
      await this.twilio.setWorkerActivity(Store.Instance.state.Environment.TwilioWrapUpActivitySid, 'Call disconnected')
    }
  }

  private setUpTwilioConnection(): void {
    if (this.twilio.call) {
      this.twilio.call.on('accept', this.onCallAccepted)
      this.twilio.call.on('warning', (warningName) => {
        this.logger.warn('call:warning', warningName)
        eventBus.$emit('errorHandler', warningName)
        let errorMessage = ''
        switch (warningName) {
          case 'low-mos':
          case 'high-rtt':
          case 'high-jitter':
          case 'high-packet-loss':
            errorMessage = 'Poor call quality conditions detected. You may experience degraded call quality.'
            break
          case 'constant-audio-input-level':
            errorMessage = 'Your call is muted or there are issues with your microphone.'
            break
          case 'constant-audio-output-level':
            errorMessage = 'There are issues with your speakers.'
            break
          default:
            errorMessage = 'Error connecting phone, warning ' + warningName
        }
        this.$emit('showTwilioSnackbar', errorMessage, false)
      })

      this.twilio.call.on('warning-cleared', (warningName) => {
        this.logger.warn('call:warning-cleared', warningName)
        // hide the warning, if there is one
        this.$emit('clearTwilioSnackbar')
      })

      this.twilio.call.on('error', (error) => {
        this.logger.error('call:error', error)
        eventBus.$emit('errorHandler', error)
      })
    }
  }

  private clearCounter(): void {
    if (this.$refs.twilioCallCounter) {
      ;(this.$refs.twilioCallCounter as Counter).clearCounter()
    }
  }

  private get users(): UserModel[] {
    return StoreGetters.getUsers()
  }

  private get linkPhoneCallTimeout(): number {
    return Store.Instance.state.Environment.LinkPhoneCallTimeout
  }
}
</script>

<style type="text/css" scoped>
.dialpad-box {
  right: 80px !important;
  left: auto !important;
  top: 58px !important;
  position: fixed !important;
}
.dialpad >>> .contact-number .v-input__control .v-input__slot:after,
.dialpad >>> .contact-number .v-input__control .v-input__slot:before {
  opacity: 0 !important;
}
.dialpad,
.dialpad >>> .v-btn {
  font-size: 24px;
}
.dialpad-add-icon {
  position: relative;
}
.dialpad-add-icon >>> .v-icon {
  position: absolute;
  bottom: -11px;
  left: 0px;
  right: 0px;
  font-size: 11px !important;
}
.call-counter {
  font-size: 16px;
  transition: all 0.2s linear;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  transform: scale(0, 0);
  background-color: rgba(31, 31, 31, 0.9);
  opacity: 0;
  visibility: hidden;
}
.call-started .call-counter {
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
.call-forward .dialpad {
  left: 0px;
  opacity: 1;
}
.show-dialpad .dialpad {
  left: 0px;
  opacity: 1;
}
.call-counter .number {
  font-size: 20px;
}
.call-counter > .flex:nth-child(2) {
  display: flex;
  min-height: 63%;
}
.call-counter .label-counter {
  margin: auto;
}
.n-arrow {
  transition: all 0.3s linear;
  border-color: transparent;
  border-bottom-color: #fff;
  border-style: dashed dashed solid;
  border-width: 0 8.5px 8.5px;
  position: absolute;
  left: 13px;
  top: 50px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.v-menu__activator.v-menu__activator--active .n-arrow {
  top: 31px;
  z-index: 1;
  opacity: 1;
  visibility: visible;
}

.v-list__tile__sub-title {
  font-size: 12px;
}

.offQueue .line-arrow {
  position: absolute;
  height: 3px;
  width: 34px;
  transform: rotate(45deg);
  top: 13px;
}

.internal-phone-number.twilio-phonenumber {
  padding-left: 0px !important;
}
.internal-phone-number.twilio-phonenumber >>> .selectedCountry {
  max-width: 50px;
  display: inline-block;
  position: static;
  padding-left: 8px !important;
  vertical-align: top;
}
.internal-phone-number.twilio-phonenumber >>> .selectedCountry .input-group__selections {
  display: inline-block;
  position: relative;
  top: 5px;
}
.internal-phone-number.twilio-phonenumber >>> .contact-number .v-text-field label {
  top: 5px !important;
  left: 2px;
  font-size: 15px;
}
.internal-phone-number.twilio-phonenumber >>> .contact-number .v-text-field {
  padding-right: 30px;
}
.internal-phone-number >>> .validation {
  right: 27px !important;
  top: 6px !important;
}
.internal-phone-number.twilio-phonenumber >>> .backspace-icon {
  display: block;
  position: absolute;
  right: 0px;
  top: 10px;
}
.internal-phone-number.twilio-phonenumber >>> .contact-number {
  position: inherit;
}
</style>
