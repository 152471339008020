import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { InitiatePaymentResponse } from './models/customerToPay/initiate-payment-response'
import { ConfirmPaymentRequest } from './models/customerToPay/confirm-payment-request'
import { ConfirmPaymentResponse } from './models/customerToPay/confirm-payment-response'
import { CreateInvoiceResponse } from './models/customerToPay/create-invoice-response'
import { CreateInvoiceRequest } from './models/customerToPay/create-invoice-request'
import { InitiatePaymentRequest } from './models/customerToPay/initiate-payment-request'
import { CapturePaymentRequest } from './models/customerToPay/capture-payment-request'
import { CompletePaymentRequest } from './models/customerToPay/complete-payment-request'
import { CancelPaymentRequest } from './models/customerToPay/cancel-payment-request'
import { PaymentSummary } from './models/customerToPay/payment-summary'

export default class PaymentsController {
  public static async Initiate(request: InitiatePaymentRequest): Promise<InitiatePaymentResponse | null> {
    const res = await CallCentreApi.post<InitiatePaymentResponse>('payments/initiate', request)
    return res.status === 200 ? res.data : null
  }

  public static async Capture(request: CapturePaymentRequest): Promise<boolean> {
    const res = await CallCentreApi.put('payments/capture', request)
    return res.status === 204
  }

  public static async Complete(request: CompletePaymentRequest): Promise<boolean> {
    const res = await CallCentreApi.post('payments/complete', request)
    return res.status === 204
  }

  public static async Cancel(request: CancelPaymentRequest): Promise<boolean> {
    const res = await CallCentreApi.post('payments/cancel', request)
    return res.status === 204
  }

  public static async Confirm(request: ConfirmPaymentRequest): Promise<ConfirmPaymentResponse | null> {
    const { status, data } = await CallCentreApi.post<ConfirmPaymentResponse>('payments/confirm', request)
    return status === 200 ? data : null
  }

  public static async CreateInvoice(request: CreateInvoiceRequest): Promise<CreateInvoiceResponse | null> {
    const { status, data } = await CallCentreApi.post<CreateInvoiceResponse>('payments/invoice', request)
    return status === 200 ? data : null
  }

  public static async GetJobPayments(jobId: string): Promise<PaymentSummary[]> {
    const { status, data } = await CallCentreApi.get<PaymentSummary[]>(`payments/${jobId}`)
    return status === 200 ? data : []
  }
}
