import { MutationTree } from 'vuex'
import store, { State } from '@/store'
import Job from '@/models/Job'
import Emergency from '@/models/Emergency'
import EmergencyQA from '@/models/EmergencyQA'
import { WeatherForecastModel } from '@/models/WeatherForecastModel'
import { Vue } from 'vue-property-decorator'
import moment from 'moment'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import CallContractorModel from '@/models/claim/CallContractorModel'
import CallLogModel from '@/models/claim/CallLogModel'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import JobNoteModel from '@/models/claim/JobNoteModel'
import SMSModel from '@/models/claim/SMSModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import UserModel from '@/models/user/UserModel'
import PolicyEnquiry from '@/models/claim/PolicyEnquiry'
import ComplaintModel from '@/models/claim/ComplaintModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import MasterRecord from '@/models/MasterRecord'
import ThirdPartyAppointedContractor from '@/models/claim/ThirdPartyAppointedContractor'
import SoftFixedEmergency from './models/claim/SoftFixedEmergencyModel'
import TradeModel from './models/policyHolder/TradeModel'
import SessionDetail from './models/auth/SessionDetail'
import InsurerModel from './models/policy/InsurerModel'
import ClientTemplateModel from './models/client/ClientTemplateModel'
import EngineerVisitDetail from './models/claim/EngineerVisitDetailModel'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import BoilerBreakdownQA from './models/claim/BoilerBreakdownQA'
import InsurerPhoneNumberModel from './models/client/InsurerPhoneNumberModel'
import ContractorInvoiceDetailModel from './models/claim/ContractorInvoiceDetailModel'
import ClientInvoiceDetailModel from './models/claim/ClientInvoiceDetailModel'
import HelplinePolicyModel from './models/claim/HelplinePolicyModel'
import VisitHealthAndSafetyProcess from './models/claim/VisitHealthAndSafetyProcessModel'
import SubsidenceConsultantModel from '@/models/SubsidenceConsultantModel'
import ClaimManagementCompanyModel from '@/models/ClaimManagementCompanyModel'
import { Worker } from '@/components/dashboard/worker'
import ProductQA from '@/models/ProductQA'
import ReportDetail from '@/models/ReportDetail'
import MonitoringDetail from '@/models/siteInvestigation/MonitoringDetail'
import MonitoringVisitLog from '@/models/siteInvestigation/MonitoringVisitLog'
import TestingProgressDetail from './models/siteInvestigation/TestingProgressDetail'
import EmailModel from './models/claim/EmailModel'
import IVSDetailModel from '@/models/IVSDetailModel'
import SIVisitTrialPit from './models/siteInvestigation/SIVisitTrialPit'
import CCTVControlLog from './models/siteInvestigation/CCTVControlLog'
import DatumControlLog from './models/siteInvestigation/DatumControlLog'
import WaterMainsTest from './models/siteInvestigation/WaterMainsTest'
import TrialPitDrawingModel from './models/siteInvestigation/TrialPitDrawingModel'
import DrainageReportModel from './models/claim/DrainageReportModel'
import CustomerAppointedTradespeople from './models/claim/CustomerAppointedTradespeople'
import JobDelay from '@/models/JobDelay'
import JobEscalationDetail from './models/claim/JobEscalationDetailModel'
import RepairEstimateModel from './models/undergroundServices/RepairEstimateModel'
import { EnvironmentSecrets } from './models/environment/environment-secrets'
import eventBus from './common/bus'
import TemplateCookieRecord from './models/cookies/template-cookie-record'
import TradeDelay from './models/delay/TradeDelay'
import ContractorModel from './models/contractor/ContractorModel'
import { PostcodeRegion } from './models/contractor/RegionsConfiguration'
import PolicySchedule from './models/policySchedule/policySchedule'

export const mutationTree: MutationTree<State> = {
  setSessionDetail: (s: State, sd: SessionDetail) => {
    s.SessionDetail = sd
  },
  clearSessionDetail: (s: State) => {
    s.SessionDetail = new SessionDetail()
  },
  setUsers: (s: State, r: UserModel[]) => {
    s.Users = r
  },
  setEmergencies: (s: State, r: EmergencyModel[]) => {
    s.Emergencies = r
  },
  setPolicyRouteToMarketList: (s: State, r: PolicyRouteToMarketModel[]) => {
    s.PolicyRouteToMarketList = r
  },
  setJob: (s: State, j: Job) => {
    Vue.set(s.Jobs, j.id, j)
  },
  setJobRefreshRequested: (s: State, requested: boolean) => {
    s.JobRefreshRequested = requested
  },
  updateLastAddedNewJobAt: (s: State) => {
    s.LastAddedNewJobAt = moment()
  },
  toggleEmergencyVisiblity: (s: State, payload: [string, string]) => {
    const j = s.Jobs[payload[0]]
    if (j) {
      const emergency = j.emergencies.find((e) => e.id === payload[1])
      if (emergency) {
        emergency.displayInTimeline = !emergency.displayInTimeline
      }
    }
  },
  updateJobTopLevelProperties: (s: State, job: Job) => {
    const existingItem = s.Jobs[job.jobId]
    if (!existingItem) {
      return
    }
    Object.assign(existingItem, job)
  },
  addOrReplaceEmergency: (s: State, emergency: Emergency) => {
    if (!s.Jobs[emergency.jobId]) {
      return
    }
    if (!s.Jobs[emergency.jobId].emergencies) {
      s.Jobs[emergency.jobId].emergencies = []
    }
    const i = s.Jobs[emergency.jobId].emergencies.findIndex((e) => e.id === emergency.id)
    if (i > -1) {
      s.Jobs[emergency.jobId].emergencies.splice(i, 1)
    }
    s.Jobs[emergency.jobId].emergencies.push(emergency)
  },
  addOrReplaceEmergencyQA: (s: State, emergencyQA: EmergencyQA) => {
    if (!s.Jobs[emergencyQA.jobId]) {
      return
    }
    if (!s.Jobs[emergencyQA.jobId].emergenciesQAs) {
      s.Jobs[emergencyQA.jobId].emergenciesQAs = []
    }
    const i = s.Jobs[emergencyQA.jobId].emergenciesQAs.findIndex((e) => e.id === emergencyQA.id)
    if (i > -1) {
      s.Jobs[emergencyQA.jobId].emergenciesQAs.splice(i, 1)
    }
    s.Jobs[emergencyQA.jobId].emergenciesQAs.push(emergencyQA)
  },
  addOrReplaceProductQA: (s: State, productQA: ProductQA) => {
    if (!s.Jobs[productQA.jobId]) {
      return
    }
    if (!s.Jobs[productQA.jobId].productQAs) {
      s.Jobs[productQA.jobId].productQAs = []
    }
    const i = s.Jobs[productQA.jobId].productQAs.findIndex((e) => e.id === productQA.id)
    if (i > -1) {
      s.Jobs[productQA.jobId].productQAs.splice(i, 1)
    }
    s.Jobs[productQA.jobId].productQAs.push(productQA)
  },
  addOrReplaceHealthAndSafetyQA: (s: State, healthAndSafetyQA: HealthAndSafetyQAModel) => {
    if (!s.Jobs[healthAndSafetyQA.jobId]) {
      return
    }
    if (!s.Jobs[healthAndSafetyQA.jobId].healthAndSafetyQAs) {
      s.Jobs[healthAndSafetyQA.jobId].healthAndSafetyQAs = []
    }
    const i = s.Jobs[healthAndSafetyQA.jobId].healthAndSafetyQAs.findIndex((e) => e.id === healthAndSafetyQA.id)
    if (i > -1) {
      s.Jobs[healthAndSafetyQA.jobId].healthAndSafetyQAs.splice(i, 1)
    }
    s.Jobs[healthAndSafetyQA.jobId].healthAndSafetyQAs.push(healthAndSafetyQA)
  },
  addOrReplaceVulnerabilityQA: (s: State, vulnerabilityQA: VulnerabilityQAModel) => {
    if (!s.Jobs[vulnerabilityQA.jobId]) {
      return
    }
    s.Jobs[vulnerabilityQA.jobId].vulnerabilityQA = vulnerabilityQA
  },
  addOrReplaceIVSDetail: (s: State, ivsDetail: IVSDetailModel) => {
    if (!s.Jobs[ivsDetail.jobId]) {
      return
    }
    s.Jobs[ivsDetail.jobId].ivsDetail = ivsDetail
  },
  addOrReplaceCallContractor: (s: State, callContractor: CallContractorModel) => {
    if (!s.Jobs[callContractor.jobId]) {
      return
    }
    if (!s.Jobs[callContractor.jobId].callContractors) {
      s.Jobs[callContractor.jobId].callContractors = []
    }
    const i = s.Jobs[callContractor.jobId].callContractors.findIndex((e) => e.id === callContractor.id)
    if (i > -1) {
      s.Jobs[callContractor.jobId].callContractors.splice(i, 1)
    }
    s.Jobs[callContractor.jobId].callContractors.push(callContractor)
  },
  addOrReplaceCallLog: (s: State, callLog: CallLogModel) => {
    if (!s.Jobs[callLog.jobId]) {
      return
    }
    if (!s.Jobs[callLog.jobId].callLogs) {
      s.Jobs[callLog.jobId].callLogs = []
    }
    const i = s.Jobs[callLog.jobId].callLogs.findIndex((e) => e.id === callLog.id)
    if (i > -1) {
      s.Jobs[callLog.jobId].callLogs.splice(i, 1)
    }
    s.Jobs[callLog.jobId].callLogs.push(callLog)
  },
  addOrReplaceClaimPicture: (s: State, claimPicture: PictureUploadModel) => {
    if (!s.Jobs[claimPicture.jobId]) {
      return
    }
    if (!s.Jobs[claimPicture.jobId].claimPictures) {
      s.Jobs[claimPicture.jobId].claimPictures = []
    }
    const i = s.Jobs[claimPicture.jobId].claimPictures.findIndex((e) => e.id === claimPicture.id)
    if (i > -1) {
      s.Jobs[claimPicture.jobId].claimPictures.splice(i, 1)
    }
    s.Jobs[claimPicture.jobId].claimPictures.push(claimPicture)
  },
  addOrReplaceCustomerAvailability: (s: State, customerAvailability: CustomerAvailabilityModel) => {
    if (!s.Jobs[customerAvailability.jobId]) {
      return
    }
    s.Jobs[customerAvailability.jobId].customerAvailability = customerAvailability ? customerAvailability : null
  },
  addOrReplaceFinancialSummary: (s: State, financialSummary: FinancialSummaryModel) => {
    if (!s.Jobs[financialSummary.jobId]) {
      return
    }
    if (!s.Jobs[financialSummary.jobId].financialSummary) {
      s.Jobs[financialSummary.jobId].financialSummary = financialSummary
    }
  },
  addOrReplaceCustomerToPay: (s: State, customerToPay: CustomerToPayModel) => {
    if (!s.Jobs[customerToPay.jobId]) {
      return
    }
    if (!s.Jobs[customerToPay.jobId].customerToPay) {
      s.Jobs[customerToPay.jobId].customerToPay = []
    }
    const i = s.Jobs[customerToPay.jobId].customerToPay.findIndex((e) => e.id === customerToPay.id)
    if (i > -1) {
      s.Jobs[customerToPay.jobId].customerToPay.splice(i, 1)
    }
    s.Jobs[customerToPay.jobId].customerToPay.push(customerToPay)
  },
  addOrReplaceEngineerRequest: (s: State, engineerRequest: EngineerRequestModel) => {
    if (!s.Jobs[engineerRequest.jobId]) {
      return
    }
    if (!s.Jobs[engineerRequest.jobId].engineerRequest) {
      s.Jobs[engineerRequest.jobId].engineerRequest = []
    }
    const i = s.Jobs[engineerRequest.jobId].engineerRequest.findIndex((e) => e.id === engineerRequest.id)
    if (i > -1) {
      s.Jobs[engineerRequest.jobId].engineerRequest.splice(i, 1)
    }
    s.Jobs[engineerRequest.jobId].engineerRequest.push(engineerRequest)
  },
  setWeatherForcast: (s: State, payload: [string, WeatherForecastModel]) => {
    if (!s.Jobs[payload[0]]) {
      return
    }
    s.Jobs[payload[0]].weatherForecast = payload[1]
  },
  updateValidationErrorMessage: (s: State, payload: [string, string, string]) => {
    if (!s.Jobs[payload[0]]) {
      return
    }
    if (!s.Jobs[payload[0]].emergenciesQAs) {
      return
    }
    const qa = s.Jobs[payload[0]].emergenciesQAs.find((e) => e.id === payload[1])
    if (!qa) {
      return
    }
    qa.__validationErrorMessage = payload[2]
  },
  updateProductValidationErrorMessage: (s: State, payload: [string, string, string]) => {
    if (!s.Jobs[payload[0]]) {
      return
    }
    if (!s.Jobs[payload[0]].productQAs) {
      return
    }
    const qa = s.Jobs[payload[0]].productQAs.find((p) => p.id === payload[1])
    if (!qa) {
      return
    }
    qa.__validationErrorMessage = payload[2]
  },
  triggerTimeLineRebuild: (s: State, jobId: string) => {
    const job = s.Jobs[jobId]
    if (!job) {
      return
    }
    job.__timeLineRebuildRequestedAt = moment()
  },
  addOrReplaceJobNote: (s: State, jobNote: JobNoteModel) => {
    if (!s.Jobs[jobNote.jobId]) {
      return
    }
    if (!s.Jobs[jobNote.jobId].jobNotes) {
      s.Jobs[jobNote.jobId].jobNotes = []
    }
    const i = s.Jobs[jobNote.jobId].jobNotes.findIndex((e) => e.id === jobNote.id)
    if (i > -1) {
      s.Jobs[jobNote.jobId].jobNotes.splice(i, 1)
    }
    s.Jobs[jobNote.jobId].jobNotes.push(jobNote)
  },
  addOrReplaceJobSmsText: (s: State, sms: SMSModel) => {
    if (!s.Jobs[sms.jobId]) {
      return
    }
    if (!s.Jobs[sms.jobId].SmsTexts) {
      s.Jobs[sms.jobId].SmsTexts = []
    }
    const i = s.Jobs[sms.jobId].SmsTexts.findIndex((e) => e.id === sms.id)
    if (i > -1) {
      s.Jobs[sms.jobId].SmsTexts.splice(i, 1)
    }
    s.Jobs[sms.jobId].SmsTexts.push(sms)
  },
  addOrReplaceJobEmail: (s: State, email: EmailModel) => {
    if (!s.Jobs[email.jobId]) {
      return
    }
    if (!s.Jobs[email.jobId].Emails) {
      s.Jobs[email.jobId].Emails = []
    }
    const i = s.Jobs[email.jobId].Emails.findIndex((e) => e.id === email.id)
    if (i > -1) {
      s.Jobs[email.jobId].Emails.splice(i, 1)
    }
    s.Jobs[email.jobId].Emails.push(email)
  },
  addOrReplaceComplaint: (s: State, complaint: ComplaintModel) => {
    if (!s.Jobs[complaint.jobId]) {
      return
    }
    if (!s.Jobs[complaint.jobId].complaint) {
      s.Jobs[complaint.jobId].complaint = []
    }
    const i = s.Jobs[complaint.jobId].complaint.findIndex((e) => e.id === complaint.id)
    if (i > -1) {
      s.Jobs[complaint.jobId].complaint.splice(i, 1)
    }
    s.Jobs[complaint.jobId].complaint.push(complaint)
  },
  addOrReplaceContractorAppointedDetail: (s: State, contractorAppointed: ContractorAppointedModel) => {
    if (!s.Jobs[contractorAppointed.jobId]) {
      return
    }
    if (!s.Jobs[contractorAppointed.jobId].contractorAppointed) {
      s.Jobs[contractorAppointed.jobId].contractorAppointed = []
    }
    const i = s.Jobs[contractorAppointed.jobId].contractorAppointed.findIndex((e) => e.id === contractorAppointed.id)
    if (i > -1) {
      s.Jobs[contractorAppointed.jobId].contractorAppointed.splice(i, 1)
    }
    s.Jobs[contractorAppointed.jobId].contractorAppointed.push(contractorAppointed)
  },
  addOrReplacePolicyEnquiry: (s: State, policyEnquiry: PolicyEnquiry) => {
    if (!s.Jobs[policyEnquiry.jobId]) {
      return
    }
    s.Jobs[policyEnquiry.jobId].policyEnquiry = policyEnquiry
  },
  updateCurrentCallSid: (s: State, currentCallSid: string) => {
    if (currentCallSid) {
      s.CurrentCallSid = currentCallSid
    } else {
      s.CurrentCallSid = ''
    }
  },
  updateCurrentCallPolicySchedule: async (s: State, policySchedule: PolicySchedule | null) => {
    s.CurrentCallClientId = policySchedule !== null && policySchedule.clientId !== null ? policySchedule.clientId : ''
    s.CurrentCallPolicySchedule = policySchedule
  },
  clearCurrentCallPolicySchedule: (s: State) => {
    s.CurrentCallClientId = ''
    s.CurrentCallPolicySchedule = null
  },
  setDelayCodes: (s: State, r: MasterRecord[]) => {
    s.DelayCodes = r
  },
  addOrReplaceThirdPartyContractorAppointedDetail: (s: State, contractorAppointed: ThirdPartyAppointedContractor) => {
    if (!s.Jobs[contractorAppointed.jobId]) {
      return
    }
    s.Jobs[contractorAppointed.jobId].thirdPartyAppointedContractor = contractorAppointed
  },
  addOrReplaceSoftFixedEmergencyDetail: (s: State, softFixedEmergency: SoftFixedEmergency) => {
    if (!s.Jobs[softFixedEmergency.jobId]) {
      return
    }
    s.Jobs[softFixedEmergency.jobId].softFixedEmergency.push(softFixedEmergency)
  },
  setTrades: (s: State, r: TradeModel[]) => {
    s.Trades = r
  },
  setInsurerList: (s: State, r: InsurerModel[]) => {
    s.Insurers = r
  },
  setInsurerPortalList: (s: State, r: ClientTemplateModel[]) => {
    s.InsurersPortals = r
  },

  addOrReplaceEngineerVisitDetail: (s: State, engineerVisitDetail: EngineerVisitDetail) => {
    if (!s.Jobs[engineerVisitDetail.jobId]) {
      return
    }
    if (!s.Jobs[engineerVisitDetail.jobId].engineerVisitDetail) {
      s.Jobs[engineerVisitDetail.jobId].engineerVisitDetail = []
    }
    const i = s.Jobs[engineerVisitDetail.jobId].engineerVisitDetail.findIndex((e) => e.id === engineerVisitDetail.id)
    if (i > -1) {
      s.Jobs[engineerVisitDetail.jobId].engineerVisitDetail.splice(i, 1)
    }
    s.Jobs[engineerVisitDetail.jobId].engineerVisitDetail.push(engineerVisitDetail)
  },
  addOrReplaceAccessNote: (s: State, accessNote: AccessNoteModel) => {
    if (!s.Jobs[accessNote.jobId]) {
      return
    }
    s.Jobs[accessNote.jobId].accessNote = accessNote
  },
  setPostCodeDistricts: (s: State, r: any[]) => {
    s.PostCodeDistricts = r
  },
  addOrReplaceBoilerBreakdownQA: (s: State, boilerBreakdownQA: BoilerBreakdownQA) => {
    if (!s.Jobs[boilerBreakdownQA.jobId]) {
      return
    }
    if (!s.Jobs[boilerBreakdownQA.jobId].boilerBreakdownQA) {
      s.Jobs[boilerBreakdownQA.jobId].boilerBreakdownQA = []
    }
    const i = s.Jobs[boilerBreakdownQA.jobId].boilerBreakdownQA.findIndex((e) => e.id === boilerBreakdownQA.id)
    if (i > -1) {
      s.Jobs[boilerBreakdownQA.jobId].boilerBreakdownQA.splice(i, 1)
    }
    s.Jobs[boilerBreakdownQA.jobId].boilerBreakdownQA.push(boilerBreakdownQA)
  },
  addOrReplaceContractorInvoiceDetail: (s: State, invoiceDetail: ContractorInvoiceDetailModel) => {
    if (!s.Jobs[invoiceDetail.jobId]) {
      return
    }
    if (!s.Jobs[invoiceDetail.jobId].contractorInvoiceDetail) {
      s.Jobs[invoiceDetail.jobId].contractorInvoiceDetail = []
    }
    const i = s.Jobs[invoiceDetail.jobId].contractorInvoiceDetail.findIndex((e) => e.id === invoiceDetail.id)
    if (i > -1) {
      s.Jobs[invoiceDetail.jobId].contractorInvoiceDetail.splice(i, 1)
    }
    s.Jobs[invoiceDetail.jobId].contractorInvoiceDetail.push(invoiceDetail)
  },
  setCustomersPhoneNumber: (s: State, r: InsurerPhoneNumberModel[]) => {
    s.CustomersPhoneNumber = r
  },
  addOrReplaceClientInvoiceDetail: (s: State, invoiceDetail: ClientInvoiceDetailModel) => {
    if (!s.Jobs[invoiceDetail.jobId]) {
      return
    }
    if (!s.Jobs[invoiceDetail.jobId].clientInvoiceDetails) {
      s.Jobs[invoiceDetail.jobId].clientInvoiceDetails = []
    }
    const i = s.Jobs[invoiceDetail.jobId].clientInvoiceDetails.findIndex((e) => e.id === invoiceDetail.id)
    if (i > -1) {
      s.Jobs[invoiceDetail.jobId].clientInvoiceDetails.splice(i, 1)
    }
    s.Jobs[invoiceDetail.jobId].clientInvoiceDetails.push(invoiceDetail)
  },
  addOrReplaceHelplinePolicy: (s: State, helplinePolicy: HelplinePolicyModel) => {
    if (!s.Jobs[helplinePolicy.jobId]) {
      return
    }
    s.Jobs[helplinePolicy.jobId].helplinePolicy = helplinePolicy
  },
  setNomineeRelations: (s: State, r: MasterRecord[]) => {
    s.NomineeRelations = r
  },
  addOrReplaceVisitHealthAndSafetyProcess: (s: State, visitHealthAndSafetyProcess: VisitHealthAndSafetyProcess) => {
    if (!s.Jobs[visitHealthAndSafetyProcess.jobId]) {
      return
    }
    if (!s.Jobs[visitHealthAndSafetyProcess.jobId].visitHealthAndSafetyProcess) {
      s.Jobs[visitHealthAndSafetyProcess.jobId].healthAndSafetyQAs = []
    }
    const i = s.Jobs[visitHealthAndSafetyProcess.jobId].visitHealthAndSafetyProcess.findIndex(
      (e) => e.id === visitHealthAndSafetyProcess.id
    )
    if (i > -1) {
      s.Jobs[visitHealthAndSafetyProcess.jobId].visitHealthAndSafetyProcess.splice(i, 1)
    }
    s.Jobs[visitHealthAndSafetyProcess.jobId].visitHealthAndSafetyProcess.push(visitHealthAndSafetyProcess)
  },
  removePolicyWideQA: (s: State, payload: [string, string]) => {
    if (!s.Jobs[payload[0]]) {
      return
    }
    if (!s.Jobs[payload[0]].emergenciesQAs) {
      s.Jobs[payload[0]].emergenciesQAs = []
    }
    const i = s.Jobs[payload[0]].emergenciesQAs.findIndex((e) => e.id === payload[1])
    if (i > -1) {
      s.Jobs[payload[0]].emergenciesQAs.splice(i, 1)
    }
  },
  setSubsidenceConsultants: (s: State, r: SubsidenceConsultantModel[]) => {
    s.SubsidenceConsultants = r
  },
  setClaimManagementCompanies: (s: State, r: ClaimManagementCompanyModel[]) => {
    s.ClaimManagementCompanies = r
  },
  setWorkers: (s: State, r: Worker[]) => {
    s.Workers = r
  },
  setContractorRegistrationStatus: (s: State, status: string) => {
    if (status) {
      s.ContractorRegistrationStatus = status
    } else {
      s.ContractorRegistrationStatus = ''
    }
  },
  addOrReplaceReportDetail: (s: State, reportDetail: ReportDetail) => {
    if (!s.Jobs[reportDetail.jobId]) {
      return
    }
    const i = s.ReportDetailRecords.findIndex((r) => r.jobId === reportDetail.jobId)
    if (i > -1) {
      s.ReportDetailRecords.splice(i, 1)
    }
    s.ReportDetailRecords.push(reportDetail)
  },
  setMonitoringDetail: (s: State, monitoringDetail: MonitoringDetail) => {
    if (!s.Jobs[monitoringDetail.jobId]) {
      return
    }
    if (monitoringDetail.monitoringVisitLogs && monitoringDetail.monitoringVisitLogs.length) {
      monitoringDetail.monitoringVisitLogs = monitoringDetail.monitoringVisitLogs.map(
        (vl) => new MonitoringVisitLog(vl)
      )
    }
    s.Jobs[monitoringDetail.jobId].monitoringDetail = monitoringDetail
  },
  addOrReplaceTestingProgress: (s: State, tpd: TestingProgressDetail) => {
    if (!s.Jobs[tpd.jobId]) {
      return
    }
    const i = s.TestingProgressDetails.findIndex((t) => t.jobId === tpd.jobId)
    if (i > -1) {
      s.TestingProgressDetails.splice(i, 1)
    }
    s.TestingProgressDetails.push(tpd)
  },
  removeJobDocument: (s: State, payload: [string, string, string]) => {
    switch (payload[2]) {
      case 'PictureUpload':
        const pictureIndex = s.Jobs[payload[0]].claimPictures.findIndex((e) => e.id === payload[1])
        if (pictureIndex !== -1) {
          s.Jobs[payload[0]].claimPictures.splice(pictureIndex, 1)
        }
        break
      case 'EmergencyDetail':
        const emergencyIndex = s.Jobs[payload[0]].emergencies.findIndex((e) => e.id === payload[1])
        if (emergencyIndex !== -1) {
          s.Jobs[payload[0]].emergencies.splice(emergencyIndex, 1)
        }
        break
      case 'EmergencyQA':
        const qaIndex = s.Jobs[payload[0]].emergenciesQAs.findIndex((e) => e.id === payload[1])
        if (qaIndex !== -1) {
          s.Jobs[payload[0]].emergenciesQAs.splice(qaIndex, 1)
        }
        break
      case 'HealthAndSafetyQA':
        const hsQAIndex = s.Jobs[payload[0]].healthAndSafetyQAs.findIndex((e) => e.id === payload[1])
        if (hsQAIndex !== -1) {
          s.Jobs[payload[0]].healthAndSafetyQAs.splice(hsQAIndex, 1)
        }
        break
      case 'RepairEstimate':
        const repairEstimateIndex = s.Jobs[payload[0]].repairEstimates.findIndex((e) => e.id === payload[1])
        if (repairEstimateIndex !== -1) {
          s.Jobs[payload[0]].repairEstimates.splice(repairEstimateIndex, 1)
        }
        break
      default:
        break
    }
  },
  setEnquiryCategories: (s: State, r: MasterRecord[]) => {
    s.EnquiryCategories = r
  },
  setSIJobDelay: (s: State, siJobDelay: JobDelay) => {
    if (!s.Jobs[siJobDelay.jobId]) {
      return
    }
    s.Jobs[siJobDelay.jobId].siJobDelay = siJobDelay
  },
  setUSJobDelay: (s: State, usJobDelay: JobDelay) => {
    if (!s.Jobs[usJobDelay.jobId]) {
      return
    }
    s.Jobs[usJobDelay.jobId].usJobDelay = usJobDelay
  },
  addOrReplaceTrialPitDetail: (s: State, siVisitTrialPit: SIVisitTrialPit) => {
    if (!s.Jobs[siVisitTrialPit.jobId]) {
      return
    }
    const i = s.Jobs[siVisitTrialPit.jobId].siTrialPitDetail.findIndex((e) => e.id === siVisitTrialPit.id)
    if (i > -1) {
      s.Jobs[siVisitTrialPit.jobId].siTrialPitDetail.splice(i, 1)
    }
    s.Jobs[siVisitTrialPit.jobId].siTrialPitDetail.push(siVisitTrialPit)
  },
  addOrReplaceCCTVControlLogDetail: (s: State, cctvControlLog: CCTVControlLog) => {
    if (!s.Jobs[cctvControlLog.jobId]) {
      return
    }
    s.Jobs[cctvControlLog.jobId].cctvControlLog = cctvControlLog
  },
  addOrReplaceDatumControlLogDetail: (s: State, datumControlLog: DatumControlLog) => {
    if (!s.Jobs[datumControlLog.jobId]) {
      return
    }
    const i = s.Jobs[datumControlLog.jobId].datumControlLogs.findIndex((e) => e.id === datumControlLog.id)
    if (i > -1) {
      s.Jobs[datumControlLog.jobId].datumControlLogs.splice(i, 1)
    }
    s.Jobs[datumControlLog.jobId].datumControlLogs.push(datumControlLog)
  },
  addOrReplaceWaterMainsTestDetail: (s: State, waterMainsTest: WaterMainsTest) => {
    if (!s.Jobs[waterMainsTest.jobId]) {
      return
    }
    s.Jobs[waterMainsTest.jobId].waterMainsTest = waterMainsTest
  },
  addOrReplaceTrialPitDrawing: (s: State, trialPitDrawing: TrialPitDrawingModel) => {
    if (!s.Jobs[trialPitDrawing.jobId]) {
      return
    }
    if (!s.Jobs[trialPitDrawing.jobId].trialPitDrawing) {
      s.Jobs[trialPitDrawing.jobId].trialPitDrawing = []
    }
    const i = s.Jobs[trialPitDrawing.jobId].trialPitDrawing.findIndex((e) => e.id === trialPitDrawing.id)
    if (i > -1) {
      s.Jobs[trialPitDrawing.jobId].trialPitDrawing.splice(i, 1)
    }
    s.Jobs[trialPitDrawing.jobId].trialPitDrawing.push(trialPitDrawing)
  },
  addOrReplaceDrainageReport: (s: State, drainageReport: DrainageReportModel) => {
    if (!s.Jobs[drainageReport.jobId]) {
      return
    }
    s.Jobs[drainageReport.jobId].drainageReport = drainageReport
  },
  addOrReplaceCustomerAppointedTradespeople: (
    s: State,
    customerAppointedTradespeople: CustomerAppointedTradespeople
  ) => {
    if (!s.Jobs[customerAppointedTradespeople.jobId]) {
      return
    }
    if (!s.Jobs[customerAppointedTradespeople.jobId].customerAppointedTradespeople) {
      s.Jobs[customerAppointedTradespeople.jobId].customerAppointedTradespeople = []
    }
    const i = s.Jobs[customerAppointedTradespeople.jobId].customerAppointedTradespeople.findIndex(
      (e) => e.id === customerAppointedTradespeople.id
    )
    if (i > -1) {
      s.Jobs[customerAppointedTradespeople.jobId].customerAppointedTradespeople.splice(i, 1)
    }
    s.Jobs[customerAppointedTradespeople.jobId].customerAppointedTradespeople.push(customerAppointedTradespeople)
  },
  addOrReplaceJobEscalationDetails: (s: State, jobEscalationDetail: JobEscalationDetail) => {
    if (!s.Jobs[jobEscalationDetail.jobId]) {
      return
    }
    if (!s.Jobs[jobEscalationDetail.jobId].jobEscalationDetails) {
      s.Jobs[jobEscalationDetail.jobId].jobEscalationDetails = []
    }
    const i = s.Jobs[jobEscalationDetail.jobId].jobEscalationDetails.findIndex((e) => e.id === jobEscalationDetail.id)
    if (i > -1) {
      s.Jobs[jobEscalationDetail.jobId].jobEscalationDetails.splice(i, 1)
    }
    s.Jobs[jobEscalationDetail.jobId].jobEscalationDetails.push(jobEscalationDetail)
  },
  addOrReplaceRepairEstimateDetail: (s: State, repairEstimate: RepairEstimateModel) => {
    if (!s.Jobs[repairEstimate.jobId]) {
      return
    }
    if (!s.Jobs[repairEstimate.jobId].repairEstimates) {
      s.Jobs[repairEstimate.jobId].repairEstimates = []
    }
    const i = s.Jobs[repairEstimate.jobId].repairEstimates.findIndex((e) => e.id === repairEstimate.id)
    if (i > -1) {
      s.Jobs[repairEstimate.jobId].repairEstimates.splice(i, 1)
    }
    s.Jobs[repairEstimate.jobId].repairEstimates.push(repairEstimate)
  },
  setEnvironmentSecrets: (state: State, secrets: EnvironmentSecrets) => {
    Object.keys(secrets).forEach((key) => {
      if (!secrets[key] || !secrets[key].length) {
        const msg = `App settings error - ${key} must not be null. Please contact support`
        eventBus.$emit('appSettingsError', msg)
      }
      state.Environment[key] = secrets[key]
    })
    eventBus.$emit('setupInitialSettings')
  },
  setTemplateCookieRecord: (state: State, templateCookieRecord: TemplateCookieRecord) => {
    state.TemplateCookieRecord = templateCookieRecord
  },
  setTradeDelays: (state: State, tradeDelays: TradeDelay[]) => {
    state.TradeDelays = tradeDelays
  },
  setTradeDelay: (state: State, tradeDelay: TradeDelay) => {
    const index = state.TradeDelays.findIndex((x) => x.id === tradeDelay.id)
    if (index !== -1) {
      state.TradeDelays[index] = tradeDelay
    } else {
      state.TradeDelays.push(tradeDelay)
    }
  },
  setContractors: (state: State, contractors: ContractorModel[]) => {
    state.Contractors = contractors
  },
  setRegions: (state: State, regions: PostcodeRegion[]) => {
    state.Regions = regions
  },
  setEscalationReasons: (state: State, escalationReason: MasterRecord[]) => {
    state.EscalationReasons = escalationReason
  },
  loadOutcodesByArea: async (state: State) => {
    if (!state.Outcodes || state.Outcodes.length === 0) {
      const response = await fetch('/mapdata/outcodes-by-area.json')
      state.Outcodes = await response.json()
    }
  },
}

class StoreMutations {
  public setSessionDetail(sd: SessionDetail): void {
    store.Instance.commit('setSessionDetail', sd)
  }

  public clearSessionDetail(): void {
    store.Instance.commit('clearSessionDetail')
  }

  public setUsers(users: UserModel[]) {
    store.Instance.commit('setUsers', users)
  }

  public setEmergencies(emergencies: EmergencyModel[]) {
    store.Instance.commit('setEmergencies', emergencies)
  }

  public setPolicyRouteToMarketList(route: PolicyRouteToMarketModel[]) {
    store.Instance.commit('setPolicyRouteToMarketList', route)
  }

  public updateJobTopLevelProperties(job: Job) {
    store.Instance.commit('updateJobTopLevelProperties', job)
  }

  public addOrReplaceJob(job: Job) {
    const oldJob = store.Instance.state.Jobs[job.id]
    store.Instance.commit('setJob', job)
    if (!oldJob) {
      store.Instance.commit('updateLastAddedNewJobAt')
    }
  }

  public addOrReplaceEmergency(emergency: Emergency) {
    store.Instance.commit('addOrReplaceEmergency', emergency)
  }

  public addOrReplaceEmergencyQA(emergencyQA: EmergencyQA) {
    store.Instance.commit('addOrReplaceEmergencyQA', emergencyQA)
  }

  public addOrReplaceProductQA(productQA: ProductQA) {
    store.Instance.commit('addOrReplaceProductQA', productQA)
  }

  public addOrReplaceHealthAndSafetyQA(healthAndSafetyQA: HealthAndSafetyQAModel) {
    store.Instance.commit('addOrReplaceHealthAndSafetyQA', healthAndSafetyQA)
  }

  public addOrReplaceVulnerabilityQA(vulnerabilityQA: VulnerabilityQAModel) {
    store.Instance.commit('addOrReplaceVulnerabilityQA', vulnerabilityQA)
  }

  public addOrReplaceIVSDetail(ivsDetail: IVSDetailModel) {
    store.Instance.commit('addOrReplaceIVSDetail', ivsDetail)
  }

  public addOrReplaceCallContractor(callContractor: CallContractorModel) {
    store.Instance.commit('addOrReplaceCallContractor', callContractor)
  }

  public addOrReplaceCallLog(callLog: CallLogModel) {
    store.Instance.commit('addOrReplaceCallLog', callLog)
  }

  public addOrReplaceClaimPicture(claimPicture: PictureUploadModel) {
    store.Instance.commit('addOrReplaceClaimPicture', claimPicture)
  }

  public addOrReplaceCustomerAvailability(customerAvailability: CustomerAvailabilityModel) {
    store.Instance.commit('addOrReplaceCustomerAvailability', customerAvailability)
  }

  public addOrReplaceFinancialSummary(financialSummary: FinancialSummaryModel) {
    store.Instance.commit('addOrReplaceFinancialSummary', financialSummary)
  }

  public addOrReplaceCustomerToPay(customerToPay: CustomerToPayModel) {
    store.Instance.commit('addOrReplaceCustomerToPay', customerToPay)
  }

  public addOrReplaceEngineerRequest(engineerRequest: EngineerRequestModel) {
    store.Instance.commit('addOrReplaceEngineerRequest', engineerRequest)
  }

  public setWeatherForcast(jobId: string, weatherForcast: WeatherForecastModel) {
    store.Instance.commit('setWeatherForcast', [jobId, weatherForcast])
  }

  public toggleEmergencyVisiblity(jobId: string, emergencyId: string) {
    store.Instance.commit('toggleEmergencyVisiblity', [jobId, emergencyId])
  }

  public updateValidationErrorMessage(jobId: string, emergencyQAId: string, message: string) {
    store.Instance.commit('updateValidationErrorMessage', [jobId, emergencyQAId, message])
  }

  public updateProductValidationErrorMessage(jobId: string, productQAId: string, message: string) {
    store.Instance.commit('updateProductValidationErrorMessage', [jobId, productQAId, message])
  }

  public triggerTimeLineRebuild(jobId: string) {
    store.Instance.commit('triggerTimeLineRebuild', jobId)
  }

  public addOrReplaceJobNote(jobNote: JobNoteModel) {
    store.Instance.commit('addOrReplaceJobNote', jobNote)
  }

  public addOrReplaceJobSmsText(sms: SMSModel) {
    store.Instance.commit('addOrReplaceJobSmsText', sms)
  }

  public addOrReplaceJobEmail(email: EmailModel) {
    store.Instance.commit('addOrReplaceJobEmail', email)
  }

  public addOrReplaceComplaint(complaint: ComplaintModel) {
    store.Instance.commit('addOrReplaceComplaint', complaint)
  }

  public addOrReplaceContractorAppointedDetail(contractorAppointed: ContractorAppointedModel) {
    store.Instance.commit('addOrReplaceContractorAppointedDetail', contractorAppointed)
  }

  public addOrReplacePolicyEnquiry(policyEnquiry: PolicyEnquiry) {
    store.Instance.commit('addOrReplacePolicyEnquiry', policyEnquiry)
  }

  public updateCurrentCallSid(currentCallSid: string) {
    store.Instance.commit('updateCurrentCallSid', currentCallSid)
  }

  public updateCurrentCallPolicySchedule(deliveryPhoneNumber: string) {
    store.Instance.commit('updateCurrentCallPolicySchedule', deliveryPhoneNumber)
  }

  public clearCurrentCallPolicySchedule() {
    store.Instance.commit('clearCurrentCallPolicySchedule')
  }

  public setDelayCodes(delayCodes: MasterRecord[]) {
    store.Instance.commit('setDelayCodes', delayCodes)
  }

  public addOrReplaceThirdPartyContractorAppointedDetail(contractorAppointed: ThirdPartyAppointedContractor) {
    store.Instance.commit('addOrReplaceThirdPartyContractorAppointedDetail', contractorAppointed)
  }

  public addOrReplaceSoftFixedEmergencyDetail(softFixedEmergency: SoftFixedEmergency) {
    store.Instance.commit('addOrReplaceSoftFixedEmergencyDetail', softFixedEmergency)
  }

  public setJobRefreshRequested(requested: boolean) {
    store.Instance.commit('setJobRefreshRequested', requested)
  }

  public setTrades(trades: TradeModel[]) {
    store.Instance.commit('setTrades', trades)
  }

  public setInsurerList(route: InsurerModel[]) {
    store.Instance.commit('setInsurerList', route)
  }

  public setInsurerPortalList(route: ClientTemplateModel[]) {
    store.Instance.commit('setInsurerPortalList', route)
  }

  public addOrReplaceEngineerVisitDetail(engineerVisitDetail: EngineerVisitDetail) {
    store.Instance.commit('addOrReplaceEngineerVisitDetail', engineerVisitDetail)
  }

  public addOrReplaceAccessNote(accessNote: AccessNoteModel) {
    store.Instance.commit('addOrReplaceAccessNote', accessNote)
  }

  public setPostCodeDistricts(districts: any[]) {
    store.Instance.commit('setPostCodeDistricts', districts)
  }

  public addOrReplaceBoilerBreakdownQA(boilerBreakdownQA: BoilerBreakdownQA) {
    store.Instance.commit('addOrReplaceBoilerBreakdownQA', boilerBreakdownQA)
  }

  public setCustomersPhoneNumber(phoneNumbersList: InsurerPhoneNumberModel[]) {
    store.Instance.commit('setCustomersPhoneNumber', phoneNumbersList)
  }

  public addOrReplaceContractorInvoiceDetail(invoiceDetail: ContractorInvoiceDetailModel) {
    store.Instance.commit('addOrReplaceContractorInvoiceDetail', invoiceDetail)
  }

  public addOrReplaceClientInvoiceDetail(invoiceDetail: ClientInvoiceDetailModel) {
    store.Instance.commit('addOrReplaceClientInvoiceDetail', invoiceDetail)
  }

  public addOrReplaceHelplinePolicy(helplinePolicy: HelplinePolicyModel) {
    store.Instance.commit('addOrReplaceHelplinePolicy', helplinePolicy)
  }

  public setNomineeRelations(nomineeRelations: MasterRecord[]) {
    store.Instance.commit('setNomineeRelations', nomineeRelations)
  }

  public addOrReplaceVisitHealthAndSafetyProcess(visitHealthAndSafetyProcess: VisitHealthAndSafetyProcess) {
    store.Instance.commit('addOrReplaceVisitHealthAndSafetyProcess', visitHealthAndSafetyProcess)
  }

  public removePolicyWideQA(jobId: string, questionId: string) {
    store.Instance.commit('removePolicyWideQA', [jobId, questionId])
  }

  public setSubsidenceConsultants(subsidenceConsultants: SubsidenceConsultantModel[]) {
    store.Instance.commit('setSubsidenceConsultants', subsidenceConsultants)
  }

  public setClaimManagementCompanies(claimManagementCompanies: ClaimManagementCompanyModel[]) {
    store.Instance.commit('setClaimManagementCompanies', claimManagementCompanies)
  }

  public setWorkers(workers: Worker[]) {
    store.Instance.commit('setWorkers', workers)
  }

  public setContractorRegistrationStatus(status: string) {
    store.Instance.commit('setContractorRegistrationStatus', status)
  }

  public addOrReplaceReportDetail(reportDetail: ReportDetail) {
    store.Instance.commit('addOrReplaceReportDetail', reportDetail)
  }

  public setMonitoringDetail(monitoringDetail: MonitoringDetail) {
    store.Instance.commit('setMonitoringDetail', monitoringDetail)
  }

  public addOrReplaceTestingProgress(testingProgressDetail: TestingProgressDetail) {
    store.Instance.commit('addOrReplaceTestingProgress', testingProgressDetail)
  }

  public removeJobDocument(jobId: string, itemId: string, itemType: string) {
    store.Instance.commit('removeJobDocument', [jobId, itemId, itemType])
  }

  public setEnquiryCategories(enquiryCategories: MasterRecord[]) {
    store.Instance.commit('setEnquiryCategories', enquiryCategories)
  }

  public setSIJobDelay(siJobDelay: JobDelay) {
    store.Instance.commit('setSIJobDelay', siJobDelay)
  }

  public setUSJobDelay(usJobDelay: JobDelay) {
    store.Instance.commit('setUSJobDelay', usJobDelay)
  }

  public addOrReplaceTrialPitDetail(siVisitTrialPit: SIVisitTrialPit) {
    store.Instance.commit('addOrReplaceTrialPitDetail', siVisitTrialPit)
  }

  public addOrReplaceCCTVControlLogDetail(cctvControlLog: CCTVControlLog) {
    store.Instance.commit('addOrReplaceCCTVControlLogDetail', cctvControlLog)
  }

  public addOrReplaceDatumControlLogDetail(datumControlLog: DatumControlLog) {
    store.Instance.commit('addOrReplaceDatumControlLogDetail', datumControlLog)
  }

  public addOrReplaceWaterMainsTestDetail(waterMainsTest: WaterMainsTest) {
    store.Instance.commit('addOrReplaceWaterMainsTestDetail', waterMainsTest)
  }

  public addOrReplaceTrialPitDrawing(trialPitDrawing: TrialPitDrawingModel) {
    store.Instance.commit('addOrReplaceTrialPitDrawing', trialPitDrawing)
  }

  public addOrReplaceDrainageReport(drainageReport: DrainageReportModel) {
    store.Instance.commit('addOrReplaceDrainageReport', drainageReport)
  }

  public addOrReplaceCustomerAppointedTradespeople(customerAppointedTradespeople: CustomerAppointedTradespeople) {
    store.Instance.commit('addOrReplaceCustomerAppointedTradespeople', customerAppointedTradespeople)
  }

  public addOrReplaceJobEscalationDetails(jobEscalationDetails: JobEscalationDetail) {
    store.Instance.commit('addOrReplaceJobEscalationDetails', jobEscalationDetails)
  }

  public addOrReplaceRepairEstimateDetail(repairEstimate: RepairEstimateModel) {
    store.Instance.commit('addOrReplaceRepairEstimateDetail', repairEstimate)
  }

  public setEnvironmentSecrets(environmentSecrets: EnvironmentSecrets) {
    store.Instance.commit('setEnvironmentSecrets', environmentSecrets)
  }

  public setTemplateCookieRecord(templateCookieRecord: TemplateCookieRecord | null) {
    store.Instance.commit('setTemplateCookieRecord', templateCookieRecord)
  }

  public setContractors(contractors: ContractorModel[]) {
    store.Instance.commit('setContractors', contractors)
  }

  public setRegions(regions: PostcodeRegion[]) {
    store.Instance.commit('setRegions', regions)
  }

  public setEscalationReasons(escalationReasons: MasterRecord[]) {
    store.Instance.commit('setEscalationReasons', escalationReasons)
  }

  public loadOutcodesByArea() {
    store.Instance.commit('loadOutcodesByArea')
  }
}

export default new StoreMutations()
