<template>
  <div v-if="isLoading">
    <v-progress-circular
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
  </div>
  <v-card v-else>
    <v-card-title>
      <h3 class="grey--text text--darken-3">Customer To Pay Summary</h3>
    </v-card-title>
    <div>
      <v-flex v-if="payments.length">
        <v-expansion-panel :value="openAccordion" expand>
          <v-expansion-panel-content v-for="(payment, index) in payments" :key="`payments-${index}`">
            <template #header>
              <v-flex>
                <v-layout justify-space-between align-center class="expansion-heading">
                  <v-layout column>
                    <h5 class="header-text">{{ humanizeString(payment.type) }}</h5>
                    <h6 class="subheader-text">{{ getFormattedDate(payment.createdDate) }}</h6>
                  </v-layout>
                  <h5 class="pr-5">{{ getFormattedCurrency(payment.amount) }}</h5>
                  <v-chip
                    small
                    :color="
                      payment.status === 'Success' || payment.status === 'Paid'
                        ? 'green'
                        : payment.status === 'Failed'
                        ? 'red'
                        : 'blue'
                    "
                    text-color="white"
                    class="status-chip"
                    >{{ payment.status }}</v-chip
                  >
                </v-layout>
              </v-flex>
            </template>
            <v-flex class="px-4 pb-4">
              <v-layout justify-space-between align-center class="expansion-heading">
                <v-layout column>
                  <p v-if="payment.cardLastFourDigits" class="mb-0 detail-text"
                    >Payment Card: <b>{{ payment.cardLastFourDigits }}</b></p
                  >
                  <p class="mb-0 detail-text"
                    >Payment Type: <b>{{ humanizeString(payment.paymentType) }}</b></p
                  >
                  <p class="mb-0 detail-text"
                    >Description: <b>{{ payment.description }}</b></p
                  >
                  <p v-if="payment.notes" class="mb-0 detail-text"
                    >Notes: <b>{{ payment.notes }}</b></p
                  >
                </v-layout>
              </v-layout>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </div>
  </v-card>
</template>

<script lang="ts">
import { PaymentSummary } from '@/api/models/customerToPay/payment-summary'
import PaymentsController from '@/api/payments-controller'
import Shared from '@/common/shared'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import { getFormattedCurrency } from '@/lib/currency'
import TimelineCardModel from '@/models/general/timeline'
import { useTimelineStore } from '@/pinia/timeline'
import moment from 'moment'
import Component from 'vue-class-component'

@Component
export default class CustomerPaymentsCardPreview extends TimeLineItemBase {
  public isLoading = true
  public headers = [
    {
      text: 'Date/Time',
      value: 'date',
      sortable: false,
    },
    {
      text: 'Amount',
      value: 'amount',
      sortable: false,
    },
    {
      text: 'Payment Card',
      value: 'paymentCard',
      sortable: false,
    },
    {
      text: 'Payment Type',
      value: 'paymentType',
      sortable: false,
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false,
    },
    {
      text: 'Description',
      value: 'description',
      sortable: false,
    },
    {
      text: 'Notes',
      value: 'notes',
      sortable: false,
    },
  ]
  public pagination = { rowsPerPage: -1 }
  public payments: PaymentSummary[] = []

  public async created() {
    this.payments = await PaymentsController.GetJobPayments(this.jobId)
    this.isLoading = false
  }

  public get card(): TimelineCardModel | null {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || null
  }

  public getFormattedCurrency(value: number) {
    return getFormattedCurrency(value)
  }

  public getFormattedDate(date: Date) {
    return Shared.getFormatedDate(moment(date), 'DD/MM/yy HH:mm')
  }

  public getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  public get openAccordion() {
    return Array.from({ length: this.payments.length }, (_, index) => index + 1)
  }

  public humanizeString(str: string) {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  }
}
</script>

<style scoped>
.header-text {
  font-size: 1.4rem;
}

.subheader-text {
  font-size: 1.1rem;
}

.expansion-heading {
  line-height: 1.5;
}

.detail-text {
  font-size: 1rem;
}

.status-chip {
  width: 60px;
  justify-content: center;
}
</style>
