import Vuex, { Store, GetterTree } from 'vuex'
import store, { State } from '@/store'
import Job from '@/models/Job'
import EmergencyQA from '@/models/EmergencyQA'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import CallContractorModel from '@/models/claim/CallContractorModel'
import CallLogModel from '@/models/claim/CallLogModel'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import FinancialSummaryModel from '@/models/claim/FinancialSummaryModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import JobNoteModel from '@/models/claim/JobNoteModel'
import SMSModel from '@/models/claim/SMSModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import UserModel from '@/models/user/UserModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import { JobVisitStatus } from '@/common/enums'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import MasterRecord from '@/models/MasterRecord'
import ThirdPartyAppointedContractor from '@/models/claim/ThirdPartyAppointedContractor'
import SoftFixedEmergency from './models/claim/SoftFixedEmergencyModel'
import TradeModel from './models/policyHolder/TradeModel'
import InsurerModel from './models/policy/InsurerModel'
import ClientTemplateModel from './models/client/ClientTemplateModel'
import EngineerVisitDetail from './models/claim/EngineerVisitDetailModel'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import BoilerBreakdownQA from './models/claim/BoilerBreakdownQA'
import VisitHealthAndSafetyProcess from './models/claim/VisitHealthAndSafetyProcessModel'
import SubsidenceConsultantModel from '@/models/SubsidenceConsultantModel'
import ClaimManagementCompanyModel from '@/models/ClaimManagementCompanyModel'
import { WorkerWithAdditions } from '@/components/dashboard/worker'
import ProductQA from '@/models/ProductQA'
import ReportDetail from '@/models/ReportDetail'
import MonitoringDetail from './models/siteInvestigation/MonitoringDetail'
import TestingProgressDetail from './models/siteInvestigation/TestingProgressDetail'
import EmailModel from './models/claim/EmailModel'
import IVSDetailModel from '@/models/IVSDetailModel'
import SIVisitTrialPit from './models/siteInvestigation/SIVisitTrialPit'
import CCTVControlLogModel from '@/models/siteInvestigation/CCTVControlLog'
import DatumControlLog from './models/siteInvestigation/DatumControlLog'
import WaterMainsTest from './models/siteInvestigation/WaterMainsTest'
import TrialPitDrawingModel from './models/siteInvestigation/TrialPitDrawingModel'
import DrainageReportModel from './models/claim/DrainageReportModel'
import CustomerAppointedTradespeople from './models/claim/CustomerAppointedTradespeople'
import JobDelay from '@/models/JobDelay'
import JobEscalationDetail from './models/claim/JobEscalationDetailModel'
import RepairEstimateModel from './models/undergroundServices/RepairEstimateModel'
import TemplateCookieRecord from './models/cookies/template-cookie-record'
import TradeDelay from './models/delay/TradeDelay'
import SessionDetail from './models/auth/SessionDetail'
import { Environment } from './common/environment'
import CookieRecord from './models/cookies/cookie-record'
import ContractorModel from './models/contractor/ContractorModel'
import { PostcodeRegion } from './models/contractor/RegionsConfiguration'
import { Postcode } from './models/contractor/Postcode'
import PolicySchedule from './models/policySchedule/policySchedule'

export const getterTree: GetterTree<State, State> = {
  getJobRefreshRequested: (state) => () => {
    if (store.Instance.state.JobRefreshRequested) {
      return true
    } else {
      return false
    }
  },
  getJob: (state) => (id) => {
    if (store.Instance.state.Jobs && store.Instance.state.Jobs[id]) {
      return store.Instance.state.Jobs[id]
    }
    return null
  },
  getQAsForEmergency: (state) => (jobId, emergencyId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const emergency = job.emergencies.find((e) => e.id === emergencyId)
    if (!emergency) {
      return []
    }
    return store.Instance.state.Jobs[jobId].emergenciesQAs.filter((e) => {
      return (
        (!e.forEmergencyTypeId && !e.forEmergencyDetailId) ||
        e.forEmergencyTypeId === emergency.typeId ||
        e.forEmergencyDetailId === emergency.detailId
      )
    })
  },
  getHealthAndSafetyQAForEmergency: (state) => (jobId, emergencyId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const emergency = job.emergencies.find((e) => e.id === emergencyId)
    if (!emergency) {
      return []
    }
    return store.Instance.state.Jobs[jobId].healthAndSafetyQAs.find((e) => {
      return (
        (!e.forEmergencyTypeId && !e.forEmergencyDetailId) ||
        (e.forEmergencyTypeId === emergency.typeId && !e.forEmergencyDetailId) ||
        (e.forEmergencyTypeId === emergency.typeId && e.forEmergencyDetailId === emergency.detailId)
      )
    })
  },
  getHealthAndSafetyQA: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].healthAndSafetyQAs
  },
  getVulnerabilityQA: (state) => (jobId) => {
    if (
      store.Instance.state.Jobs &&
      store.Instance.state.Jobs[jobId] &&
      store.Instance.state.Jobs[jobId].vulnerabilityQA
    ) {
      return store.Instance.state.Jobs[jobId].vulnerabilityQA
    }
    return new VulnerabilityQAModel()
  },
  getIVSDetail: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].ivsDetail
  },
  getCallContractorsForEmergency: (state) => (jobId, emergencyId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const emergency = job.emergencies.find((e) => e.id === emergencyId)
    if (!emergency) {
      return []
    }
    return store.Instance.state.Jobs[jobId].callContractors.filter((e) => {
      return !e.forEmergencyTypeId || e.forEmergencyTypeId === emergency.typeId
    })
  },
  getCallLogsForEmergency: (state) => (jobId, emergencyId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const emergency = job.emergencies.find((e) => e.id === emergencyId)
    if (!emergency) {
      return []
    }
    return store.Instance.state.Jobs[jobId].callLogs.filter((e) => {
      return !e.forEmergencyTypeId || e.forEmergencyTypeId === emergency.typeId
    })
  },
  getClaimPictures: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].claimPictures
  },
  getClaimPicturesById: (state) => (jobId, emergencyId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].claimPictures.filter((x) => {
      return x.forEmergencyDetailId === emergencyId
    })
  },
  getClaimPicturesByVisitId: (state) => (jobId, visitId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].claimPictures.filter((x) => {
      return x.visitId === visitId
    })
  },
  getCustomerAvailability: (state) => (jobId) => {
    if (
      store.Instance.state.Jobs &&
      store.Instance.state.Jobs[jobId] &&
      store.Instance.state.Jobs[jobId].customerAvailability
    ) {
      return store.Instance.state.Jobs[jobId].customerAvailability
    }
    return new CustomerAvailabilityModel()
  },
  getFinancialSummary: (state) => (jobId) => {
    if (
      store.Instance.state.Jobs &&
      store.Instance.state.Jobs[jobId] &&
      store.Instance.state.Jobs[jobId].financialSummary
    ) {
      return store.Instance.state.Jobs[jobId].financialSummary
    }
    return new FinancialSummaryModel()
  },
  getCustomerToPayByItemId: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    const customerToPay = job.customerToPay.find((e) => e.id === itemId)
    if (!customerToPay) {
      return null
    }
    return customerToPay
  },
  getAuthorisedCustomerToPay: (state) => (jobId) => {
    if (!store.Instance.state.Jobs || !store.Instance.state.Jobs[jobId]) {
      return null
    }
    const customerToPays: CustomerToPayModel[] = store.Instance.state.Jobs[jobId].customerToPay
    return customerToPays.find((c) => c.isTransactionCompleted)
  },
  getEngineerRequestForEmergency: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].engineerRequest.filter((e) => {
      return e.engineerVisitDetailId === itemId
    })
  },
  getPolicyRouteToMarketList: (state) => () => {
    if (store.Instance.state.PolicyRouteToMarketList) {
      return store.Instance.state.PolicyRouteToMarketList
    }
    return new PolicyRouteToMarketModel()
  },
  getJobNote: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    const jobNote = job.jobNotes.find((e) => e.id === itemId)
    if (!jobNote) {
      return null
    }
    return jobNote
  },
  getComplaints: (state) => (jobId) => {
    if (store.Instance.state.Jobs && store.Instance.state.Jobs[jobId] && store.Instance.state.Jobs[jobId].complaint) {
      return store.Instance.state.Jobs[jobId].complaint
    }
    return []
  },
  getComplaintById: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    const complaint = job.complaint.find((e) => e.id === itemId)
    if (!complaint) {
      return new ComplaintModel()
    }
    return complaint
  },
  getContractorAppointedDetail: (state) => (jobId, emergencyId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const emergency = job.emergencies.find((e) => e.id === emergencyId)
    if (!emergency) {
      return null
    }
    return job.contractorAppointed.filter((e) => {
      return (
        e.forEmergencyTypeId === emergency.typeId &&
        e.forEmergencyDetailId === emergency.detailId &&
        e.status !== JobVisitStatus[JobVisitStatus.Cancelled]
      )
    })
  },
  getContractorAppointedDetailById: (state) => (jobId, documentId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.contractorAppointed.find((e) => e.id === documentId)
  },
  getContractorAppointedDetailByContractorId: (state) => (jobId, contractorId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.contractorAppointed.find((e) => e.contractorId === contractorId)
  },
  getUsers: (state) => () => {
    if (store.Instance.state.Users) {
      return store.Instance.state.Users
    }
    return []
  },
  getIncidentManagers: (state) => () => {
    if (store.Instance.state.Users) {
      const matches = store.Instance.state.Users.filter((e) => e.roleName === 'IncidentManager' && e.isActive)
      if (matches) {
        return matches
      }
    }
    return []
  },
  getSkilledComplaintResolutionUsers: (state) => () => {
    if (store.Instance.state.Users) {
      const matches = store.Instance.state.Users.filter((e) => e.skills.complaintResolution > 0)
      if (matches) {
        return matches
      }
    }
    return []
  },
  getCurrentCallSid: (state) => () => {
    if (store.Instance.state.CurrentCallSid) {
      return store.Instance.state.CurrentCallSid
    }
    return ''
  },
  getCurrentCallClientId: (state) => () => {
    if (store.Instance.state.CurrentCallClientId) {
      return store.Instance.state.CurrentCallClientId
    }
    return ''
  },
  getCurrentCallPolicySchedule: (state) => () => {
    if (store.Instance.state.CurrentCallPolicySchedule) {
      return store.Instance.state.CurrentCallPolicySchedule
    }
    return null
  },
  getEmergencies: (state) => () => {
    if (store.Instance.state.Emergencies) {
      return store.Instance.state.Emergencies
    }
    return []
  },
  getDelayCodes: (state) => () => {
    if (store.Instance.state.DelayCodes) {
      return store.Instance.state.DelayCodes
    }
    return []
  },
  getThirdPartyAppointedContractor: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.thirdPartyAppointedContractor
  },
  getSoftFixedEmergencyDetailById: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.softFixedEmergency.find((e) => e.id === itemId)
  },
  getJobSmsTexts: (state) => (jobId, itemId) => {
    if (!state.Jobs) {
      return null
    }
    const job = state.Jobs[jobId]
    if (!job) {
      return null
    }
    const sms = job.SmsTexts.find((e) => e.id === itemId)
    if (!sms) {
      return null
    }
    return sms
  },
  getJobEmails: (state) => (jobId, itemId) => {
    if (!state.Jobs) {
      return null
    }
    const job = state.Jobs[jobId]
    if (!job) {
      return null
    }
    const email = job.Emails.find((e) => e.id === itemId)
    if (!email) {
      return null
    }
    return email
  },
  getTrades: (state) => () => {
    if (store.Instance.state.Trades) {
      return store.Instance.state.Trades
    }
    return []
  },
  getInsurerList: (state) => () => {
    if (store.Instance.state.Insurers) {
      return store.Instance.state.Insurers
    }
    return new InsurerModel()
  },
  getInsurerPortalList: (state) => () => {
    if (store.Instance.state.InsurersPortals) {
      return store.Instance.state.InsurersPortals
    }
    return new ClientTemplateModel()
  },
  getEngineerVisitDetail: (state) => (jobId, id) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.engineerVisitDetail.find((e) => e.id === id)
  },
  getAccessNote: (state) => (jobId) => {
    if (store.Instance.state.Jobs && store.Instance.state.Jobs[jobId] && store.Instance.state.Jobs[jobId].accessNote) {
      return store.Instance.state.Jobs[jobId].accessNote
    }
    return new AccessNoteModel()
  },
  getPostCodeDistricts: (state) => () => {
    if (store.Instance.state.PostCodeDistricts) {
      return store.Instance.state.PostCodeDistricts
    }
    return []
  },
  getBoilerBreakdownQA: (state) => (jobId, id) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.boilerBreakdownQA.find((e) => e.visitId === id)
  },
  getCustomersPhoneNumber: (state) => () => {
    if (store.Instance.state.CustomersPhoneNumber) {
      return store.Instance.state.CustomersPhoneNumber
    }
    return []
  },
  getPolicyWideQAs: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].emergenciesQAs.filter((e) => {
      return !e.forEmergencyTypeId && !e.forEmergencyDetailId
    })
  },
  getNomineeRelations: (state) => () => {
    if (store.Instance.state.NomineeRelations) {
      return store.Instance.state.NomineeRelations
    }
    return []
  },
  getVisitHealthAndSafetyProcessForEmergency: (state) => (jobId, id) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].visitHealthAndSafetyProcess.find((e) => {
      return e.engineerVisitDetailId === id
    })
  },
  getJobComplaintNotes: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    const jobNote = job.jobNotes.filter((e) => e.noteType.toLowerCase() === 'complaintnote')
    if (!jobNote) {
      return [null]
    }
    return jobNote
  },
  getSubsidenceConsultants: (state) => () => {
    if (store.Instance.state.SubsidenceConsultants) {
      return store.Instance.state.SubsidenceConsultants
    }
    return []
  },
  getClaimManagementCompanies: (state) => () => {
    if (store.Instance.state.ClaimManagementCompanies) {
      return store.Instance.state.ClaimManagementCompanies
    }
    return []
  },
  getWorkers: (state) => () => {
    if (store.Instance.state.Workers && store.Instance.state.Workers.length > 0) {
      return store.Instance.state.Workers
    }
    store.Instance.state.Workers = []
    return store.Instance.state.Workers
  },

  getProductQAs: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    return store.Instance.state.Jobs[jobId].productQAs
  },
  getContractorRegistrationStatus: (state) => () => {
    if (store.Instance.state.ContractorRegistrationStatus) {
      return store.Instance.state.ContractorRegistrationStatus
    }
    return ''
  },
  getReportDetail: (state) => (jobId) => {
    if (store.Instance.state.ReportDetailRecords) {
      const record = store.Instance.state.ReportDetailRecords.find((r) => r.jobId === jobId)
      return record ? record : null
    }
    return null
  },
  getEnquiryCategories: (state) => () => {
    if (store.Instance.state.EnquiryCategories) {
      return store.Instance.state.EnquiryCategories
    }
    return []
  },
  getMonitoringDetail: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].monitoringDetail
  },
  getTestingProgress: (state) => (jobId) => {
    if (!store.Instance.state.Jobs || !store.Instance.state.Jobs[jobId]) {
      return null
    }
    const record = store.Instance.state.TestingProgressDetails.find((r) => r.jobId === jobId)
    return record ? record : null
  },
  getSIJobDelay: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].siJobDelay
  },
  getUSJobDelay: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].usJobDelay
  },
  getTrialPitDetail: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const siTrialPitDetail = job.siTrialPitDetail.filter((e) => e.forEngineerVisitDetailId === itemId)
    if (!siTrialPitDetail || siTrialPitDetail.length < 1) {
      return []
    }
    return siTrialPitDetail
  },
  getCCTVControlLogDetail: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job || !job.cctvControlLog) {
      return null
    }
    if (job.cctvControlLog.forEngineerVisitDetailId === itemId) {
      return job.cctvControlLog
    }
    return null
  },
  getDatumControlLogDetail: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return []
    }
    const datumControlLogs = job.datumControlLogs.filter((e) => e.engineerVisitDetailId === itemId)
    if (!datumControlLogs || datumControlLogs.length < 1) {
      return []
    }
    return datumControlLogs
  },
  getWaterMainsTestDetail: (state) => (jobId, itemId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job || !job.waterMainsTest) {
      return null
    }
    if (job.waterMainsTest.engineerVisitDetailId === itemId) {
      return job.waterMainsTest
    }
    return null
  },
  getTrialPitDrawing: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].trialPitDrawing
  },
  getDrainageReport: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].drainageReport
  },
  getCustomerAppointedTradespeople: (state) => (jobId, id) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return job.customerAppointedTradespeople.find((e) => e.id === id)
  },
  getJobEscalationDetails: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return []
    }
    if (
      store.Instance.state.Jobs &&
      store.Instance.state.Jobs[jobId] &&
      store.Instance.state.Jobs[jobId].jobEscalationDetails.length > 0
    ) {
      return store.Instance.state.Jobs[jobId].jobEscalationDetails
    }
    return []
  },
  getJobActiveEscalationDetails: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    if (
      store.Instance.state.Jobs &&
      store.Instance.state.Jobs[jobId] &&
      store.Instance.state.Jobs[jobId].jobEscalationDetails.length > 0
    ) {
      const activeEscalation = store.Instance.state.Jobs[jobId].jobEscalationDetails.find(
        (x) =>
          x.deEscalationDescription === undefined ||
          x.deEscalationDescription === null ||
          x.deEscalationDescription === ''
      )
      return activeEscalation ? activeEscalation : null
    }
    return null
  },
  getRepairEstimateDetail: (state) => (jobId) => {
    if (!store.Instance.state.Jobs) {
      return null
    }
    const job = store.Instance.state.Jobs[jobId]
    if (!job) {
      return null
    }
    return store.Instance.state.Jobs[jobId].repairEstimates
  },
  getUnableToCompleteReason:
    (state) =>
    (jobId: string): string | null => {
      if (!store.Instance.state.Jobs) {
        return null
      }
      const job = store.Instance.state.Jobs[jobId]
      if (!job) {
        return 'No job loaded'
      }

      if (job.status === 'Completed') {
        return 'Job already completed.'
      }

      if (job.status === 'Cancelled') {
        return 'Job already cancelled.'
      }

      if (job.cancellationReason) {
        // If there's a cancellation reason, the next step must be to cancel the job, so don't worry about the other validation.
        return null
      }

      const acceptedEmergencies = job.emergencies.filter(
        (e) => e.isEmergencyAccepted || e.ctpCompletedForRejectedEmergency
      )
      const rejectedEmergencies = job.emergencies.filter(
        (e) => !e.isEmergencyAccepted && e.repudiationDescription && !e.ctpCompletedForRejectedEmergency
      )
      const outstandingEmergencies = job.emergencies.filter(
        (e) => !rejectedEmergencies.includes(e) && !acceptedEmergencies.includes(e)
      )

      if (outstandingEmergencies.length) {
        return 'One or more emergencies have not been accepted or rejected.'
      }

      // check if any contractor is appointed to any emergency
      const pendingVisits = job.contractorAppointed.filter(
        (c) =>
          c.status === JobVisitStatus[JobVisitStatus.Pending] ||
          c.status === JobVisitStatus[JobVisitStatus.Accepted] ||
          c.status === JobVisitStatus[JobVisitStatus.InProgress] ||
          c.status === JobVisitStatus[JobVisitStatus.AutoDeploying]
      )
      if (pendingVisits.length) {
        // check if any emergency has visits, which are not completed
        return 'One or more visits have not been completed.'
      }

      // check if every emergency has contractor appointed or not
      const appointedContractors = job.contractorAppointed.filter(
        (c) =>
          c.status !== JobVisitStatus[JobVisitStatus.Cancelled] &&
          c.status !== JobVisitStatus[JobVisitStatus.Unavailable]
      )
      const emergenciesThatShouldHaveContractor = acceptedEmergencies.filter(
        (em) => job.customerAppointedTradespeople.findIndex((i) => i.emergencyId === em.id) === -1
      )
      for (const emergency of emergenciesThatShouldHaveContractor) {
        const contractorAppointedCard = appointedContractors.find((e) => e.emergencyId === emergency.id)
        if (!contractorAppointedCard) {
          return 'One or more emergencies have not been assigned a contractor.'
        }
      }

      if (job.customerAppointedTradespeople && job.customerAppointedTradespeople.length) {
        // check if any CAT has been assigned and expiry date has not been passed
        const cat = job.customerAppointedTradespeople.filter((cat) => cat)[0]
        if (cat.invoiceImageUrl === null && new Date(cat.invoiceUrlLinkExpiredAt) > new Date()) {
          // check if invoice uploaded or not for all CAT
          return 'Awaiting invoice for customer appointed trade.'
        }
      }

      return null
    },
  templateCookieRecord: (state) => state.TemplateCookieRecord,
  tradeDelays: (state) => state.TradeDelays,
  sessionDetail: (state) => state.SessionDetail,
  userDetail: (state): UserModel | null => {
    if (state.SessionDetail.detailRecordType !== 'UserDetail') {
      return null
    }
    return state.SessionDetail.detailRecord.UserDetail
  },
  environment: (state) => state.Environment,
  lastAddedNewJobAt: (state) => state.LastAddedNewJobAt,
  contractors: (state) => state.Contractors,
  regions: (state) => state.Regions,
  escalationReasons: (state) => state.EscalationReasons,
  outcodesByArea: (state) => state.Outcodes,
}

class StoreGetters {
  public getJob(jobId: string): Job | null {
    return store.Instance.getters.getJob(jobId)
  }

  public getQAsForEmergency(jobId: string, emergencyId: string): EmergencyQA[] {
    return store.Instance.getters.getQAsForEmergency(jobId, emergencyId)
  }

  public getHealthAndSafetyQAForEmergency(jobId: string, emergencyId: string): HealthAndSafetyQAModel {
    return store.Instance.getters.getHealthAndSafetyQAForEmergency(jobId, emergencyId)
  }

  public getHealthAndSafetyQA(jobId: string): HealthAndSafetyQAModel[] {
    return store.Instance.getters.getHealthAndSafetyQA(jobId)
  }

  public getVulnerabilityQA(jobId: string): VulnerabilityQAModel {
    return store.Instance.getters.getVulnerabilityQA(jobId)
  }

  public getIVSDetail(jobId: string): IVSDetailModel | null {
    return store.Instance.getters.getIVSDetail(jobId)
  }

  public getCallContractorsForEmergency(jobId: string, emergencyId: string): CallContractorModel[] {
    return store.Instance.getters.getCallContractorsForEmergency(jobId, emergencyId)
  }

  public getCallLogsForEmergency(jobId: string, emergencyId: string): CallLogModel[] {
    return store.Instance.getters.getCallLogsForEmergency(jobId, emergencyId)
  }

  public getClaimPictures(jobId: string): PictureUploadModel[] {
    return store.Instance.getters.getClaimPictures(jobId)
  }

  public getClaimPicturesById(jobId: string, emergencyId): PictureUploadModel[] {
    return store.Instance.getters.getClaimPicturesById(jobId, emergencyId)
  }

  public getClaimPicturesByVisitId(jobId: string, visitId): PictureUploadModel[] {
    return store.Instance.getters.getClaimPicturesByVisitId(jobId, visitId)
  }

  public getCustomerAvailability(jobId: string): CustomerAvailabilityModel {
    return store.Instance.getters.getCustomerAvailability(jobId)
  }

  public getFinancialSummary(jobId: string): FinancialSummaryModel {
    return store.Instance.getters.getFinancialSummary(jobId)
  }

  public getAuthorisedCustomerToPay(jobId: string): CustomerToPayModel | undefined {
    return store.Instance.getters.getAuthorisedCustomerToPay(jobId)
  }

  public getCustomerToPayByItemId(jobId: string, itemId: string): CustomerToPayModel {
    return store.Instance.getters.getCustomerToPayByItemId(jobId, itemId)
  }

  public getEngineerRequestForEmergency(jobId: string, itemId: string): EngineerRequestModel[] {
    return store.Instance.getters.getEngineerRequestForEmergency(jobId, itemId)
  }

  public getPolicyRouteToMarketList(): PolicyRouteToMarketModel[] {
    return store.Instance.getters.getPolicyRouteToMarketList()
  }

  public getJobNote(jobId: string, itemId: string): JobNoteModel | null {
    return store.Instance.getters.getJobNote(jobId, itemId)
  }

  public getComplaints(jobId: string): ComplaintModel[] {
    return store.Instance.getters.getComplaints(jobId)
  }

  public getComplaintById(jobId: string, itemId: string): ComplaintModel {
    return store.Instance.getters.getComplaintById(jobId, itemId)
  }

  public getContractorAppointedDetail(jobId: string, emergencyId: string): ContractorAppointedModel[] {
    return store.Instance.getters.getContractorAppointedDetail(jobId, emergencyId)
  }

  public getContractorAppointedDetailById(jobId: string, documentId: string): ContractorAppointedModel {
    return store.Instance.getters.getContractorAppointedDetailById(jobId, documentId)
  }

  public getContractorAppointedDetailByContractorId(jobId: string, contractorId: string): ContractorAppointedModel {
    return store.Instance.getters.getContractorAppointedDetailByContractorId(jobId, contractorId)
  }

  public getSoftFixedEmergencyDetailById(jobId: string, id: string): SoftFixedEmergency {
    return store.Instance.getters.getSoftFixedEmergencyDetailById(jobId, id)
  }

  public getUsers(): UserModel[] {
    return store.Instance.getters.getUsers()
  }

  public getIncidentManagers(): UserModel[] {
    return store.Instance.getters.getIncidentManagers()
  }

  public getSkilledComplaintResolutionUsers(): UserModel[] {
    return store.Instance.getters.getSkilledComplaintResolutionUsers()
  }

  public getCurrentCallSid(): string {
    return store.Instance.getters.getCurrentCallSid()
  }

  public getCurrentCallClientId(): string {
    return store.Instance.getters.getCurrentCallClientId()
  }

  public getCurrentCallPolicySchedule(): PolicySchedule | null {
    return store.Instance.getters.getCurrentCallPolicySchedule()
  }

  public getEmergencies(): EmergencyModel[] {
    return store.Instance.getters.getEmergencies()
  }

  public getDelayCodes(): MasterRecord[] {
    return store.Instance.getters.getDelayCodes()
  }

  public getThirdPartyAppointedContractor(jobId: string): ThirdPartyAppointedContractor {
    return store.Instance.getters.getThirdPartyAppointedContractor(jobId)
  }

  public getJobRefreshRequested(): boolean {
    return store.Instance.getters.getJobRefreshRequested()
  }

  public getJobSmsTexts(jobId: string, itemId: string): SMSModel | null {
    return store.Instance.getters.getJobSmsTexts(jobId, itemId)
  }

  public getJobEmails(jobId: string, itemId: string): EmailModel | null {
    return store.Instance.getters.getJobEmails(jobId, itemId)
  }

  public getTrades(): TradeModel[] {
    return store.Instance.getters.getTrades()
  }

  public getInsurerList(): InsurerModel[] {
    return store.Instance.getters.getInsurerList()
  }

  public getInsurerPortalList(): ClientTemplateModel[] {
    return store.Instance.getters.getInsurerPortalList()
  }

  public getEngineerVisitDetail(jobId: string, id: string): EngineerVisitDetail {
    return store.Instance.getters.getEngineerVisitDetail(jobId, id)
  }

  public getAccessNote(jobId: string): AccessNoteModel {
    return store.Instance.getters.getAccessNote(jobId)
  }

  public getPostCodeDistricts() {
    return store.Instance.getters.getPostCodeDistricts()
  }

  public getBoilerBreakdownQA(jobId: string, id: string): BoilerBreakdownQA {
    return store.Instance.getters.getBoilerBreakdownQA(jobId, id)
  }

  public getCustomersPhoneNumber() {
    return store.Instance.getters.getCustomersPhoneNumber()
  }

  public getPolicyWideQAs(jobId: string): EmergencyQA[] {
    return store.Instance.getters.getPolicyWideQAs(jobId)
  }

  public getNomineeRelations(): MasterRecord[] {
    return store.Instance.getters.getNomineeRelations()
  }

  public getVisitHealthAndSafetyProcessForEmergency(jobId: string, id: string): VisitHealthAndSafetyProcess {
    return store.Instance.getters.getVisitHealthAndSafetyProcessForEmergency(jobId, id)
  }

  public getJobComplaintNotes(jobId: string): JobNoteModel[] | null {
    return store.Instance.getters.getJobComplaintNotes(jobId)
  }

  public getSubsidenceConsultants(): SubsidenceConsultantModel[] {
    return store.Instance.getters.getSubsidenceConsultants()
  }

  public getClaimManagementCompanies(): ClaimManagementCompanyModel[] {
    return store.Instance.getters.getClaimManagementCompanies()
  }

  public getWorkers(): WorkerWithAdditions[] {
    return store.Instance.getters.getWorkers()
  }

  public getProductQAs(jobId: string): ProductQA[] {
    return store.Instance.getters.getProductQAs(jobId)
  }

  public getContractorRegistrationStatus(): string {
    return store.Instance.getters.getContractorRegistrationStatus()
  }

  public getReportDetail(jobId: string): ReportDetail | null {
    return store.Instance.getters.getReportDetail(jobId)
  }

  public getEnquiryCategories(): MasterRecord[] {
    return store.Instance.getters.getEnquiryCategories()
  }

  public getMonitoringDetail(jobId: string): MonitoringDetail | null {
    return store.Instance.getters.getMonitoringDetail(jobId)
  }

  public getTestingProgress(jobId): TestingProgressDetail | null {
    return store.Instance.getters.getTestingProgress(jobId)
  }

  public getSIJobDelay(jobId): JobDelay | null {
    return store.Instance.getters.getSIJobDelay(jobId)
  }

  public getUSJobDelay(jobId): JobDelay | null {
    return store.Instance.getters.getUSJobDelay(jobId)
  }

  public getTrialPitDetail(jobId: string, engineerVisitDetailId: string): SIVisitTrialPit[] {
    return store.Instance.getters.getTrialPitDetail(jobId, engineerVisitDetailId)
  }

  public getCCTVControlLogDetail(jobId: string, engineerVisitDetailId: string): CCTVControlLogModel | null {
    return store.Instance.getters.getCCTVControlLogDetail(jobId, engineerVisitDetailId)
  }

  public getDatumControlLogDetail(jobId: string, engineerVisitDetailId: string): DatumControlLog[] {
    return store.Instance.getters.getDatumControlLogDetail(jobId, engineerVisitDetailId)
  }

  public getWaterMainsTestDetail(jobId: string, engineerVisitDetailId: string): WaterMainsTest | null {
    return store.Instance.getters.getWaterMainsTestDetail(jobId, engineerVisitDetailId)
  }

  public getTrialPitDrawing(jobId: string): TrialPitDrawingModel[] {
    return store.Instance.getters.getTrialPitDrawing(jobId)
  }

  public getDrainageReport(jobId: string): DrainageReportModel | null {
    return store.Instance.getters.getDrainageReport(jobId)
  }

  public getCustomerAppointedTradespeople(jobId: string, id: string): CustomerAppointedTradespeople | null {
    return store.Instance.getters.getCustomerAppointedTradespeople(jobId, id)
  }

  public getJobEscalationDetails(jobId: string): JobEscalationDetail[] {
    return store.Instance.getters.getJobEscalationDetails(jobId)
  }

  public getJobActiveEscalationDetails(jobId: string): JobEscalationDetail {
    return store.Instance.getters.getJobActiveEscalationDetails(jobId)
  }

  public getRepairEstimateDetail(jobId: string): RepairEstimateModel[] {
    return store.Instance.getters.getRepairEstimateDetail(jobId)
  }

  public getUnableToCompleteReason(jobId: string): string | null {
    return store.Instance.getters.getUnableToCompleteReason(jobId)
  }

  public getTemplateCookieRecord(): TemplateCookieRecord {
    return store.Instance.getters.templateCookieRecord
  }

  public getTradeDelays(): TradeDelay[] {
    return store.Instance.getters.tradeDelays
  }

  public getSessionDetail(): SessionDetail {
    return store.Instance.getters.sessionDetail
  }

  public getEnvironementVariables(): Environment {
    return store.Instance.getters.environment
  }

  public getContractors(): ContractorModel[] {
    return store.Instance.getters.contractors
  }

  public getRegions(): PostcodeRegion[] {
    return store.Instance.getters.regions
  }

  public getEscalationReasons(): MasterRecord[] {
    return store.Instance.getters.escalationReasons
  }

  public getOutcodesByArea(): Postcode[] {
    return store.Instance.getters.outcodesByArea
  }
}

export default new StoreGetters()
