import { JobType } from '@/models/types'
import { Vue } from 'vue-property-decorator'

// TODO: add other colours once known and update SetTheme funtion
export abstract class Theme {
  public abstract primary: string
  public abstract secondary: string
  // accent: string,
  // error: string,
  // info: string,
  // success: string,
  // warning: string,
}

export class DefaultTheme implements Theme {
  public primary = '#607d8c'
  public secondary = '#e91e63'
}

class HomeEmergencyTheme implements Theme {
  public primary = '#009688'
  public secondary = '#e91e63'
}

class DrainageTheme implements Theme {
  public primary = '#03a9f4'
  public secondary = '#e91e63'
}

class SiteInvestigationTheme implements Theme {
  public primary = '#00bcd4'
  public secondary = '#e91e63'
}

export function GetCurrentTheme(componentInstance: Vue): Theme {
  return componentInstance.$vuetify.theme as Theme
}

export function GetTheme(Type: JobType | undefined | null) {
  switch (Type) {
    case 'US':
      return new DrainageTheme()
    case 'HE':
      return new HomeEmergencyTheme()
    case 'SI':
      return new SiteInvestigationTheme()
    default:
      return new DefaultTheme()
  }
}

export function SetTheme(componentInstance: Vue, theme: Theme) {
  // set values
  componentInstance.$vuetify.theme.primary = theme.primary
  componentInstance.$vuetify.theme.secondary = theme.secondary
}

export function SetThemeForJobType(componentInstance: Vue, Type: JobType | undefined) {
  // get the matching theme
  const theme: Theme = GetTheme(Type)
  SetTheme(componentInstance, theme)
}

export const colours = {
  brand: '#607d8c',
}
