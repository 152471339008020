import { render, staticRenderFns } from "./VIPCustomer.vue?vue&type=template&id=5a016f1a&scoped=true"
import script from "./VIPCustomer.vue?vue&type=script&lang=ts"
export * from "./VIPCustomer.vue?vue&type=script&lang=ts"
import style0 from "./VIPCustomer.vue?vue&type=style&index=0&id=5a016f1a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.9_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_lodas_njaui5j7ox37wcdpl467s5x2le/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a016f1a",
  null
  
)

export default component.exports