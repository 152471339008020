<template>
  <v-flex xs12 class="timeline-cards" :class="selected ? 'card-active' : ''" :style="getDynamicCardStyle">
    <div>
      <v-card class="mb-3 title-text right-arrow" :class="getCardClass" :ripple="true">
        <span class="timeline-icon">
          <v-icon>{{ model.icon }}</v-icon>
        </span>
        <span class="left-bar"></span>
        <span v-show="!showFlatCard" class="arrow"></span>
        <span class="date-text">{{ getElapsedDuration }}</span>
        <div class="card-inner-detail">
          <h3
            v-if="!showFlatCard"
            :class="
              model.status && model.status === 'Follow On Required'
                ? 'status-title visit-complete-chip'
                : model.status
                ? 'status-title'
                : ''
            "
            class="pa-3"
          >
            <span>{{ model.title }}</span>
            <v-chip
              v-if="model.status && isEngineerVisitCard"
              :color="getEngineerVisitStatusColor"
              small
              class="white--text"
              :class="
                model.status === engineerJobVisitStatus[engineerJobVisitStatus.ReAssigned] ? 're-assigned-visit' : ''
              "
            >
              {{ getEngineerVisitStatus(model.status) }}
            </v-chip>
            <v-chip v-if="isJobEscalationCard" :color="getJobEscalationStatusColor" small class="white--text">
              {{ getJobEscalationStatus }}
            </v-chip>
            <v-chip
              v-if="model.status && isConfirmDateCard"
              :color="getConfirmDateStatusColor"
              small
              class="white--text"
            >
              {{ getConfirmDateStatus(model.status) }}
            </v-chip>
            <v-chip v-if="isTestingCard" :color="testingStatusColor" small class="white--text">
              {{ testingStatus }}
            </v-chip>
            <v-chip v-if="model.status && isIVSCard" :color="getIVSColor" small class="white--text">
              {{ getIVSStatus(model.status) }}
            </v-chip>
            <v-chip
              v-if="model.status && !isEngineerVisitCard && !isConfirmDateCard && !isIVSCard"
              :color="
                model.status === jobVisitStatus[jobVisitStatus.Unavailable]
                  ? ''
                  : model.status === jobVisitStatus[jobVisitStatus.Cancelled]
                  ? 'red'
                  : 'green'
              "
              small
              class="white--text"
            >
              {{
                model.status === jobVisitStatus[jobVisitStatus.Unavailable]
                  ? ''
                  : model.status === jobVisitStatus[jobVisitStatus.Cancelled]
                  ? 'Cancelled'
                  : model.status === jobVisitStatus[jobVisitStatus.Completed]
                  ? 'Completed'
                  : model.status === 'AutoDeploying'
                  ? 'Auto Deploying'
                  : model.status === 'Follow On Required'
                  ? 'Follow On Required'
                  : 'Assigned'
              }}
            </v-chip>
            <v-chip v-if="model.cardBodyComponent == 'SoftFixCard'" color="green" class="white--text" small>
              Emergency Complete
            </v-chip>
            <v-chip
              v-if="model.cardBodyComponent === 'CustomerToPayCard' && showCustomerToPayCardChip"
              color="red"
              class="white--text"
              small
            >
              Expired
            </v-chip>
            <span v-if="model.cardAdditionalChipsComponent">
              <slot name="additional-chips"></slot>
            </span>
          </h3>
          <div class="question-title-desc" :class="!showFlatCard ? 'px-3 pt-3 pb-2 ' : 'px-3 py-1'">
            <slot name="body"></slot>
            <v-layout wrap>
              <v-flex lg7 xs12>
                <div class="small-text text-gray">
                  <slot name="footer"></slot>
                </div>
              </v-flex>
              <v-flex v-show="!showFlatCard" lg5 xs12>
                <div class="small-text text-gray text-xs-right date-outer">
                  <v-icon color="secondary">date_range</v-icon>
                  {{ getCreatedDateForDisplay }}
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="showEmergency" class="emergency px-0 py-1">
            <v-divider v-if="!showFlatCard" class="grey mb-1 lighten-3"></v-divider>
            <span class="chip-btn px-3">
              <span v-for="emergency in getEmergencies" :key="emergency.id">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-chip text-color="white" :small="true" class="emergency-chip" v-on="on">
                      <v-avatar :size="26">
                        <img
                          v-if="emergency.fileURL !== '' && emergency.fileURL !== null"
                          :src="emergency.fileURL"
                          alt=""
                        />
                        <img v-else src="/img/emergency.svg" alt="" />
                      </v-avatar>
                      {{ emergency.detailDescription }}
                    </v-chip>
                  </template>
                  <span>
                    {{ emergency.typeDescription ? emergency.typeDescription : emergency.detailDescription }}
                  </span>
                </v-tooltip>
              </span>
            </span>
          </div>
        </div>
      </v-card>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import TimeLineCardModel from '@/models/TimeLineCardModel'
import Job from '@/models/Job'
import Emergency from '@/models/Emergency'
import Store from '@/store'
import StoreGetters from '@/storeGetters'
import Shared from '@/common/shared'
import { JobVisitStatus, EngineerJobVisitStatus, SIJobProductProgress } from '@/common/enums'

@Component
export default class TimelineCard extends Vue {
  @Prop() private model: TimeLineCardModel
  @Prop() private selected: boolean
  private jobVisitStatus = JobVisitStatus
  private engineerJobVisitStatus = EngineerJobVisitStatus

  private get job(): Job | null {
    return StoreGetters.getJob(this.model.forJobId)
  }

  private get getEmergencies(): Emergency[] {
    if (this.job && this.model && this.model.forEmergencyId) {
      const item = this.job.emergencies.find((e) => e.id === this.model.forEmergencyId)
      if (item) {
        return [item]
      } else {
        return []
      }
    }
    return (this.job || { emergencies: [] }).emergencies
  }

  private get getDynamicCardStyle(): any {
    return this.selected
      ? {
          width: 'calc(100% + 24px)',
          'max-width': 'calc(100% + 24px)',
        }
      : {
          width: '100%',
          'max-width': '100%',
        }
  }

  private get getCardClass(): string {
    let c = ''
    if (this.model && this.model.colour) {
      c = this.model.colour + '-bar'
    }
    if (this.model.cardClass) {
      if (c) {
        c += ' '
      }
      c += this.model.cardClass
    }
    return c
  }

  private get getCreatedDateForDisplay(): string {
    if (this.model.createdDate) {
      return Shared.getFormatedDate(this.model.createdDate, Store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }

  private get isLegacyAutoDeploymentEnabled() {
    return !this.$ld.variation('fnol-401-multiple-contractor-notifications')
  }

  private get showFlatCard(): boolean {
    return this.model.cardBodyComponent === 'CallContractorCard' ||
      this.model.cardBodyComponent === 'FailedToContactCallCard' ||
      this.model.cardBodyComponent === 'JobCompleteConfirmationCard' ||
      (this.isLegacyAutoDeploymentEnabled && this.model.cardBodyComponent === 'AutoDeploymentCard') ||
      this.model.cardBodyComponent === 'JobReopenCard'
      ? true
      : false
  }

  private get showEmergency(): boolean {
    if (this.job && this.job.jobType) {
      switch (this.job.jobType) {
        // For HE, do not show the emergency for cards that apply to the job as a whole
        case 'HE':
          return this.model.cardBodyComponent === 'ComplaintCard' ||
            this.model.cardBodyComponent === 'VulnerabilityQACard' ||
            this.model.cardBodyComponent === 'JobCompleteConfirmationCard' ||
            this.model.cardBodyComponent === 'JobCompleteCard' ||
            this.model.cardBodyComponent === 'PolicyWideQACard' ||
            (this.model.cardBodyComponent === 'CustomerToPayCard' && !this.model.forEmergencyId) ||
            this.model.cardBodyComponent === 'OtherFeesDetailCard' ||
            this.model.cardBodyComponent === 'JobEscalationCard' ||
            this.model.cardBodyComponent === 'JobReopenCard' ||
            this.model.cardBodyComponent === 'CustomerPaymentsCard' ||
            (this.job && this.job.helplinePolicy && this.model.cardBodyComponent === 'FNOLCard')
            ? false
            : true
        // For SI/US do not show the emergency footer, as there will only ever be one emergency on the job
        case 'US':
        case 'SI':
        default:
          return false
      }
    } else {
      return false
    }
  }

  private get isEngineerVisitCard(): boolean {
    return this.model.cardBodyComponent === 'EngineerVisitCard' ? true : false
  }

  private get getEngineerVisitStatusColor(): string {
    if (
      this.model.status === this.engineerJobVisitStatus[this.engineerJobVisitStatus.JobStarted] ||
      this.model.status === this.engineerJobVisitStatus[this.engineerJobVisitStatus.PolicyHolderAbsent] ||
      this.model.status === 'Follow On Required'
    ) {
      return 'blue'
    }

    if (
      this.model.status === this.engineerJobVisitStatus[this.engineerJobVisitStatus.Pending] ||
      this.model.status === this.engineerJobVisitStatus[this.engineerJobVisitStatus.Accepted] ||
      this.model.status === this.engineerJobVisitStatus[this.engineerJobVisitStatus.Completed]
    ) {
      return 'green'
    }

    return 'red'
  }

  private getEngineerVisitStatus(engineerStatus: string): string {
    if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.Pending]) {
      return 'Assigned'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.RunningLate]) {
      return 'Running Late'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.PolicyHolderAbsent]) {
      return 'Customer Not Present'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted]) {
      return 'Job Started'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.OnTheWay]) {
      return 'On The Way'
    } else if (engineerStatus === 'Follow On Required') {
      return 'Follow On Required'
    } else if (engineerStatus === EngineerJobVisitStatus[EngineerJobVisitStatus.ReAssigned]) {
      return 'Reassigned'
    } else {
      return engineerStatus
    }
  }

  private get isJobEscalationCard(): boolean {
    return this.model.cardBodyComponent === 'JobEscalationCard' ? true : false
  }

  private get getJobEscalationStatusColor(): string {
    return this.isJobDeEscalated ? 'blue' : 'red'
  }

  private get getJobEscalationStatus(): string {
    return this.isJobDeEscalated ? 'De-Escalated' : 'Escalated'
  }

  private get isJobDeEscalated(): boolean {
    const selectedCard = this.job!.jobEscalationDetails.find((x) => x.id === this.model.forItemId)
    return selectedCard && selectedCard.deEscalationDescription ? true : false
  }

  private get isConfirmDateCard(): boolean {
    return this.model.cardBodyComponent === 'ConfirmDateCard' ? true : false
  }

  private get isIVSCard(): boolean {
    return this.model.cardBodyComponent === 'IVSProcessCard' ? true : false
  }

  private get getConfirmDateStatusColor(): string {
    if (this.model.status === this.jobVisitStatus[this.jobVisitStatus.InProgress]) {
      return 'blue'
    }

    if (
      this.model.status === this.jobVisitStatus[this.jobVisitStatus.Pending] ||
      this.model.status === this.jobVisitStatus[this.jobVisitStatus.Accepted] ||
      this.model.status === this.jobVisitStatus[this.jobVisitStatus.Completed]
    ) {
      return 'green'
    }

    return 'red'
  }

  private get getIVSColor(): string {
    if (this.model.status === SIJobProductProgress[SIJobProductProgress.InProgress]) {
      return 'blue'
    }
    if (this.model.status === SIJobProductProgress[SIJobProductProgress.Finished]) {
      return 'green'
    }
    return 'red'
  }

  private getIVSStatus(status: string): string {
    if (status === SIJobProductProgress[SIJobProductProgress.NotStartedYet]) {
      return 'Not Started Yet'
    } else if (status === SIJobProductProgress[SIJobProductProgress.InProgress]) {
      return 'In Progress'
    } else if (status === SIJobProductProgress[SIJobProductProgress.Finished]) {
      return 'Completed'
    } else {
      return status
    }
  }

  private getConfirmDateStatus(status: string): string {
    if (status === JobVisitStatus[JobVisitStatus.Pending]) {
      return 'Assigned'
    } else if (status === JobVisitStatus[JobVisitStatus.Accepted]) {
      return 'Accepted'
    } else if (status === JobVisitStatus[JobVisitStatus.Completed]) {
      return 'Completed'
    } else if (status === JobVisitStatus[JobVisitStatus.InProgress]) {
      return 'In Progress'
    } else {
      return status
    }
  }

  private get isTestingCard(): boolean {
    return this.model.cardBodyComponent === 'TestingCard'
  }

  private get testingStatusColor(): string {
    const progress = StoreGetters.getTestingProgress(this.model.forJobId)
    return !progress || progress.testingComplete ? 'green' : 'blue'
  }

  private get testingStatus(): string {
    const progress = StoreGetters.getTestingProgress(this.model.forJobId)
    return !progress || progress.testingComplete ? 'Completed' : 'Pending'
  }

  private get getElapsedDuration(): string {
    if (this.model.createdDate) {
      const now = moment(new Date())
      const duration = moment.duration(now.diff(this.model.createdDate))
      const minutes = duration.asMinutes()
      if (minutes > Store.Instance.state.Environment.TimelineCardHourLimit) {
        const hours = duration.asHours()
        if (hours > Store.Instance.state.Environment.TimelineCardDayLimit) {
          const days = duration.asDays()
          return days.toFixed(0) + ' days ago'
        } else {
          return hours.toFixed(0) + ' hour ago'
        }
      } else if (minutes > Store.Instance.state.Environment.TimelineCardMinuteLimit) {
        return minutes.toFixed(0) + ' mins ago'
      } else {
        return 'just now'
      }
    }
    return '---'
  }

  private get showCustomerToPayCardChip() {
    if (this.model.forItemId) {
      const customerToPay = StoreGetters.getCustomerToPayByItemId(this.model.forJobId, this.model.forItemId)
      if (customerToPay && !customerToPay.isTransactionCompleted) {
        return this.model.createdDate < moment().subtract(7, 'd') ? true : false
      }
      return false
    }
    return false
  }
}
</script>

<style scoped>
.flatCard.v-card {
  box-shadow: none;
  background-color: transparent;
}

.flatCard.right-arrow:after {
  display: none !important;
}

.status-chip {
  position: absolute;
  right: 30px;
  top: 7px;
}
h3.status-title {
  padding-right: 130px !important;
}
.date-outer .v-icon {
  font-size: 22px;
  margin-right: 5px;
}

@media screen and (max-width: 1425px) {
  .visit-complete-chip.status-title {
    font-size: 14px;
    padding-right: 175px !important;
    line-height: 1.7;
  }
  .visit-complete-chip .status-chip {
    margin-right: 0px;
  }
  .visit-complete-chip >>> .v-chip__content {
    padding: 0px 6px !important;
    font-size: 13px;
  }
}
</style>

<style scoped>
/* questions card specific styles */
.not-covered h3 {
  background-color: #ef9a9a;
}
.not-covered .arrow {
  border-color: transparent transparent #ef9a9a #ef9a9a;
}
.not-covered.right-arrow:after {
  color: #ff5252;
}
.covered.right-arrow:after {
  color: #4caf50;
}
.pending-question h3 {
  background-color: #ffcc80 !important;
}
.pending-question .arrow {
  border-color: transparent transparent #ffcc80 #ffcc80;
}
.pending-question.right-arrow:after {
  color: #ff9800;
}
.covered .status-icon,
.not-covered .status-icon,
.pending-question .status-icon {
  position: absolute;
  right: 30px;
  top: 13px;
}
.re-assigned-visit {
  background: #fcc02f !important;
}
</style>
