<template>
  <span>
    <v-speed-dial
      v-model="changeSpeedDialIcon"
      :absolute="true"
      style="bottom: 45px; right: 45px"
      :open-on-hover="false"
    >
      <v-btn
        slot="activator"
        v-model="changeSpeedDialIcon"
        color="secondary darken-1"
        dark
        fab
        hover
        class="btn-floating btn-large"
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-tooltip v-for="item in items" :key="item.id" left class="top-tooltip">
        <template #activator="{ on }">
          <v-btn fab dark small :style="getBackgroundColourStyleFor(item)" v-on="on" @click="openDialog(item)">
            <img v-if="item.id === 'US'" class="drainage-img" src="/img/drainage.png" alt="" />
            <img v-else-if="item.id === 'SI'" class="si-img" src="/img/si.png" alt="" />
            <em v-else class="btn-floating">
              <v-icon>{{ item.icon }}</v-icon>
            </em>
          </v-btn>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </v-speed-dial>

    <v-dialog
      v-if="showNewHEJobDialog"
      :value="showNewHEJobDialog"
      persistent
      max-width="1000px"
      :content-class="isDuplicateJobOpen ? 'hide-wizard-dialog-wrapper h-100' : 'h-100'"
    >
      <iframe
        id="NewHEJobWrapper"
        ref="NewHEJobWrapper"
        :src="heWizardUrl"
        class="he-wizard-frame"
        @load="sendInitialData($event.target)"
      ></iframe>
    </v-dialog>

    <v-dialog v-model="showDialog" content-class="wizard-dialog v-dialog--scrollable" persistent max-width="820px">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>{{ dialogToolbarTitle }}</v-toolbar-title>
          <v-menu v-if="!showUploadDocument" bottom left>
            <template #activator="{ on }">
              <v-btn icon dark v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile v-for="item in items" :key="item.id" @click="openDialog(item)">
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text
          :ref="AddDialogContentRef"
          class="add-dialog-content scroll-content-dialog pb-0"
          :style="dialogContentDynamicStyle"
        >
          <AddDrainageClaimWizard
            v-show="showDrainageClaimWizard"
            :ref="DrainageChildRef"
            :wizard-visible="showDrainageClaimWizard"
            @updateContentHeight="updateContentHeight"
            @wizardPageChanged="wizardPageChanged"
            @wizardComplete="closeDialog"
            @wizardBackEndOperation="wizardBackEndOperation"
            @showUploadDocument="onShowUploadDocument"
          />
          <AddSIClaimWizard
            v-show="showSIClaimWizard"
            :ref="SIChildRef"
            :wizard-visible="showSIClaimWizard"
            @updateContentHeight="updateContentHeight"
            @wizardPageChanged="wizardPageChanged"
            @wizardComplete="closeDialog"
            @wizardBackEndOperation="wizardBackEndOperation"
            @showUploadDocument="onShowUploadDocument"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <div v-if="!showUploadDocument" class="btn-wrap">
            <v-btn
              v-if="
                (showDrainageClaimWizard && drainageWizardStep === 1) ||
                (showSIClaimWizard && siWizardStep === 1) ||
                (showSIClaimWizard && siWizardStep === 2)
              "
              flat
              @click="clearHEWizardForm"
            >
              Clear Form
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="" @click="closeDialog">Close</v-btn>
            <v-btn color="primary" :loading="isLoading" :disabled="isLoading" class="mr-0" @click="nextClicked">
              {{ nextButtonText }}
            </v-btn>
          </div>
          <div v-else class="btn-wrap">
            <v-btn
              :disabled="isLoading || !isAnyDocumentsUploaded"
              :loading="isLoading"
              color="primary"
              class="mr-0"
              @click="uploadDocumentClicked"
            >
              Upload
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AddDrainageClaimWizard from '@/components/AddDrainageClaimWizard.vue'
import AddSIClaimWizard from '@/components/AddSIClaimWizard.vue'
import { AddNewJobItem } from '@/common/interfaces'
import DialogWizard from '@/common/DialogWizard'
import { GetTheme, SetTheme, SetThemeForJobType, GetCurrentTheme, Theme } from '@/common/themes'
import Store from '@/store'
import eventBus from '@/common/bus'
import { sendCommandMessageToFrame } from '@/messaging/messaging-service'
import InitialWizardDataCommand from '@/messaging/commands/initial-wizard-data-command'
import InitialWizardData from '@/messaging/models/initial-wizard-data'
import storeGetters from '@/storeGetters'

@Component({
  components: {
    AddDrainageClaimWizard,
    AddSIClaimWizard,
  },
})
export default class FloatingAddJobButton extends Vue {
  private SIChildRef = 'SIWizard'
  private DrainageChildRef = 'DrainageWizard'
  private AddDialogContentRef = 'addDialogContent'
  private isLoading = false
  private themeBeforeOpenAsJSON = ''
  private changeSpeedDialIcon = false
  private requestedContentHeightInPixels = 480
  private nextButtonText = 'Next'
  private selectedItem: AddNewJobItem | null = null
  private showUploadDocument = false
  private items: AddNewJobItem[] = this.getJobTypeItem

  public heWizardUrl = `${window.location.protocol}//${window.location.host}/components/he-wizard/`
  public heWizardFrame: HTMLIFrameElement

  private isAnyDocumentsUploaded = false

  private wizardPageChanged(nextButtonText: string) {
    // update button text and reset scroller
    this.nextButtonText = nextButtonText
    ;(this.$refs[this.AddDialogContentRef] as any).scrollTop = 0
  }

  private wizardBackEndOperation(isProcessStart: boolean) {
    this.isLoading = isProcessStart
  }

  private updateContentHeight(newValue: number) {
    this.requestedContentHeightInPixels = newValue
  }

  private get dialogContentDynamicStyle(): string {
    return 'height: ' + this.requestedContentHeightInPixels.toString() + 'px;'
  }

  private get showDialog(): boolean {
    return this.selectedItem !== null
  }

  private getBackgroundColourStyleFor(item: AddNewJobItem) {
    return 'background-color:' + GetTheme(item.id).primary
  }

  private get dialogToolbarTitle(): string {
    if (this.selectedItem !== null) {
      return this.selectedItem.title
    }
    return 'N/A'
  }

  public get drainageWizardStep() {
    if (this.showDrainageClaimWizard) {
      const child = this.$refs[this.DrainageChildRef] as AddDrainageClaimWizard
      return child.stepIndex
    }
    return undefined
  }

  public get siWizardStep() {
    if (this.showSIClaimWizard) {
      const child = this.$refs[this.SIChildRef] as AddSIClaimWizard
      return child.stepIndex
    }
    return undefined
  }

  private get showDrainageClaimWizard(): boolean {
    return this.selectedItem !== null && this.selectedItem.id === 'US'
  }

  private get showSIClaimWizard(): boolean {
    return this.selectedItem !== null && this.selectedItem.id === 'SI'
  }

  public get showNewHEJobDialog(): boolean {
    return this.$store.getters['heWizardModule/isHeWizardVisible']
  }

  public get isDuplicateJobOpen(): boolean {
    return this.$store.getters['heWizardModule/isDuplicateJobOpen']
  }

  private clearHEWizardForm() {
    if (this.showDrainageClaimWizard) {
      const child = this.$refs[this.DrainageChildRef] as any as AddDrainageClaimWizard
      child.clearForm()
    } else if (this.showSIClaimWizard) {
      const child = this.$refs[this.SIChildRef] as any as AddSIClaimWizard
      child.clearForm()
    }
  }

  private nextClicked(): void {
    if (this.selectedItem === null) {
      return
    }

    let refName: string
    switch (this.selectedItem.id) {
      case 'US':
        refName = this.DrainageChildRef
        break
      case 'SI':
        refName = this.SIChildRef
        break
      default:
        return
    }

    const child = this.$refs[refName] as any as DialogWizard
    if (child) {
      child.processCurrentPage()
    }
  }

  private openDialog(forItem: AddNewJobItem): void {
    this.isLoading = false
    if (forItem.id === 'HE') {
      this.$store.dispatch('heWizardModule/setHeWizardVisible', true)
      return
    }

    if (this.selectedItem === null) {
      this.themeBeforeOpenAsJSON = JSON.stringify(GetCurrentTheme(this))
    }

    SetThemeForJobType(this, forItem.id)

    // open custom dashboard
    // CustomDashboard type not included in new JobType, so here checking type as any
    if ((forItem.id as any) === 'CustomDashboard') {
      this.$router.replace('/dynamicDashboard')
      return
    }

    this.selectedItem = forItem
  }

  private closeDialog(): void {
    this.isLoading = false
    if (this.themeBeforeOpenAsJSON && !this.showUploadDocument) {
      const t: Theme = JSON.parse(this.themeBeforeOpenAsJSON) as Theme
      SetTheme(this, t)
    }
    this.selectedItem = null
  }

  private mounted(): void {
    eventBus.$on('openHEWizardForPH', () => {
      this.$store.dispatch('heWizardModule/setHeWizardVisible', true)
    })
    eventBus.$on('policyScheduleIdHEFilter', () => {
      this.$store.dispatch('heWizardModule/setHeWizardVisible', true)
    })
    eventBus
      .$off('checkIsAnyDocumentsUploaded')
      .$on('checkIsAnyDocumentsUploaded', (isAnyDocumentsUploaded: boolean) => {
        this.isAnyDocumentsUploaded = isAnyDocumentsUploaded
      })
    eventBus.$off('documentUploadingStarted').$on('documentUploadingStarted', () => {
      this.isLoading = true
    })
    // Send updated initial data to wizard if a call has been answered or ended
    eventBus.$on('sendInitialDataToWizard', () => {
      const frame = this.$refs.NewHEJobWrapper as HTMLIFrameElement
      if (frame) this.sendInitialData(frame)
    })

    if (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Finance' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor' ||
        Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager')
    ) {
      // CustomDashboard type not included in new JobType, so here type is given as any
      const dashboardButton: any = {
        id: 'CustomDashboard',
        title: 'New Custom Dashboard',
        icon: 'dashboard',
      }
      this.items.push(dashboardButton)
    }
  }

  private onShowUploadDocument(value: boolean) {
    this.showUploadDocument = value
    if (!value) {
      this.isAnyDocumentsUploaded = false
    }
  }

  private uploadDocumentClicked(): void {
    eventBus.$emit('uploadDocuments')
  }

  private get getJobTypeItem(): AddNewJobItem[] {
    const jobTypes = Store.Instance.state.SessionDetail.detailRecord.UserDetail.jobTypes
    // if no type selected
    if (!jobTypes) {
      return []
    }
    const items: AddNewJobItem[] = []
    if (jobTypes.find((x) => x === 'HE')) {
      items.push({ id: 'HE', title: 'New HE Job', icon: 'insert_chart' })
    }
    if (jobTypes.find((x) => x === 'US')) {
      items.push({ id: 'US', title: 'New Drainage', icon: 'format_quote' })
    }
    if (jobTypes.find((x) => x === 'SI')) {
      items.push({ id: 'SI', title: 'New SI Job', icon: 'publish' })
    }

    return items
  }

  public sendInitialData(frame: HTMLIFrameElement) {
    const command = new InitialWizardData()
    command.sessionDetail = storeGetters.getSessionDetail()
    command.fnolCallSid = storeGetters.getCurrentCallSid()

    const policySchedule = storeGetters.getCurrentCallPolicySchedule()
    if (policySchedule) {
      command.clientId = policySchedule.clientId
      command.scheduleId = policySchedule.id
      command.isCommercialPropertyPolicy = policySchedule.isCommercialPropertyPolicy
    }
    sendCommandMessageToFrame(frame, new InitialWizardDataCommand(command))
  }
}
</script>

<style>
@media screen and (max-height: 850px) {
  .add-dialog-content {
    max-height: 480px;
  }
}
.add-dialog-content {
  overflow-y: auto;
}
.v-dialog .v-card {
  background: #fafafa !important;
}
.v-list {
  padding: 0 !important;
}
.v-btn-floating {
  margin: 6px 0;
}
.v-toolbar__title {
  line-height: 1.3;
}
.drainage-img,
.si-img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
  overflow: hidden;
}
.v-dialog .v-card .v-card__actions .btn-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.v-dialog .v-card .v-card__actions .btn-wrap:last-child {
  justify-content: flex-end;
}

.he-wizard-frame {
  display: block;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* Hides the He-Wizard when a duplicate job in open in the partial job view */
.v-dialog__content--active:has(> .hide-wizard-dialog-wrapper) {
  z-index: -1000 !important;
}
</style>
