import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useTwilioDevice } from './device'
import { useTwilioWorker } from './worker'
import { useTwilioWorkspace } from './workspace'
import { useTwilioCall } from './call'
import { getLogger } from 'loglevel'

const logger = getLogger('@simplifi/twilio-client')

export const useTwilioStore = defineStore('twilio', () => {
  const initialized = ref<boolean>(false)
  const device = useTwilioDevice()
  const worker = useTwilioWorker()
  const workspace = useTwilioWorkspace()
  const call = useTwilioCall()

  async function init() {
    logger.debug('twilio:init...')

    await device.init()
    await worker.init()
    await workspace.init()
    call.init()

    initialized.value = true
  }

  return {
    // Twilio
    init,
    initialized,

    // Device
    device: computed(() => device.instance),
    audioConfig: computed(() => device.audioConfig),

    // Worker
    worker: computed(() => worker.instance),
    setWorkerActivity: worker.setActivity,
    returnWorkerToQueue: worker.returnToQueue,
    workerInQueue: computed(() => worker.inQueue),
    workerNotInQueueReason: computed(() => worker.notInQueueReason),
    workerInWrapUp: computed(() => worker.inWrapUp),
    workerWrapUpCountdown: computed(() => worker.wrapUpCountdown),

    // Call
    call: computed(() => call.call),
    callAlert: computed(() => call.callAlert),
    reservation: computed(() => call.reservation),
    callMuted: computed(() => call.callMuted),
    callOnHold: computed(() => call.onHold),
    inCall: computed(() => call.inCall),
    callingLabel: computed(() => call.callingLabel),
    callPolicyName: computed(() => call.policyName),
    setCall: call.setCall,
    clearCall: call.clearCall,
    clearCallAlert: call.clearCallAlert,
    clearReservation: call.clearReservation,
    toggleCallMute: call.toggleMute,
    toggleCallHold: call.toggleHold,
    makeCall: call.makeCall,
    answerCall: call.answerCall,
    endCall: call.endCall,
  }
})
