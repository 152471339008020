import PaymentsController from '@/api/payments-controller'
import { PaymentErrorResponse } from '@/api/models/customerToPay/payment-error-response'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import Job from '@/models/Job'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useCustomerToPayStore = defineStore('Customer-To-Pay', () => {
  const paymentInitiated = ref(false)
  const paymentDetailsConfirmed = ref(false)
  const paymentDetailsOpened = computed(() => [!paymentDetailsConfirmed.value])
  const paymentOpened = computed(() => [paymentDetailsConfirmed.value || isTransactionCompleted.value])
  const paymentSid = ref('')
  const isPaymentConfirmed = ref(false)
  const idempotencyKey = ref('')
  const escalateCtpResponseModel = ref<EscalateCTPResponseModel | null>(null)
  const escalatedCtpAmount = ref(0)
  const paymentError = ref<PaymentErrorResponse | null>(null)
  const isSendInvoice = ref(false)
  const isLoading = ref(false)
  const customerToPay = ref<CustomerToPayModel>(new CustomerToPayModel(''))
  const isTransactionCompleted = computed(
    () => isPaymentConfirmed.value || customerToPay.value.isTransactionCompleted || false
  )
  const invoiceError = ref<PaymentErrorResponse | null>(null)
  const invoiceCreated = ref(false)

  async function initiatePayment(callSid: string, job: Job) {
    isLoading.value = true
    paymentError.value = null
    idempotencyKey.value = job.id + crypto.randomUUID()
    const result = await PaymentsController.Initiate({
      callSid,
      jobId: job.id,
      idempotencyKey: idempotencyKey.value,
      jobType: job.jobType,
    })

    isLoading.value = false

    if (!result) {
      paymentError.value = {
        description: 'Unable to initiate payment.',
        nextSteps: 'Please try again.',
        enablePaymentLink: false,
      }
      return
    }

    paymentSid.value = result.sid
    paymentInitiated.value = true
  }

  async function confirmPayment(callSid: string, paymentMethodId: string, job: Job) {
    const response = await PaymentsController.Confirm({
      callSid,
      paymentMethodId,
      amount: customerToPay.value.amount,
      jobId: job.id,
      statementDescription: customerToPay.value.statementDescription,
      cardholderName: customerToPay.value.cardHolderName,
      cardholderEmail: customerToPay.value.cardHolderEmail,
      paymentType: customerToPay.value.paymentType,
      billingAddress: {
        line1: customerToPay.value.billingAddress.line1,
        line2: customerToPay.value.billingAddress.line2,
        postcode: customerToPay.value.billingAddress.postcode,
        city: customerToPay.value.billingAddress.city,
        state: customerToPay.value.billingAddress.state,
        country: customerToPay.value.billingAddress.country,
      },
      escalateCtp: escalateCtpResponseModel.value,
      emergencyId: !customerToPay.value.emergencyId ? job.emergencies[0]?.id || '' : customerToPay.value.emergencyId,
      paySid: paymentSid.value,
      jobType: job.jobType,
    })

    if (!response) {
      paymentError.value = {
        description: 'Unable to confirm payment.',
        nextSteps: 'Please try again.',
        enablePaymentLink: false,
      }
      paymentInitiated.value = false
      paymentSid.value = ''
    } else if (response.isTransactionSuccessful) {
      isPaymentConfirmed.value = true
    } else {
      paymentError.value = response.paymentError
      paymentInitiated.value = false
      paymentSid.value = ''
    }

    isLoading.value = false
  }

  async function createInvoice(job: Job, callSid: string | null) {
    isLoading.value = true
    const response = await PaymentsController.CreateInvoice({
      jobId: job.id,
      emergencyId: !customerToPay.value.emergencyId ? job.emergencies[0]?.id || '' : customerToPay.value.emergencyId,
      callSid,
      paymentId: null,
      amount: customerToPay.value.amount,
      billingAddress: {
        line1: customerToPay.value.billingAddress.line1,
        line2: customerToPay.value.billingAddress.line2,
        postcode: customerToPay.value.billingAddress.postcode,
        city: customerToPay.value.billingAddress.city,
        state: customerToPay.value.billingAddress.state,
        country: customerToPay.value.billingAddress.country,
      },
      escalateCtp: escalateCtpResponseModel.value,
      statementDescription: customerToPay.value.statementDescription,
      cardholderName: customerToPay.value.cardHolderName,
      cardholderEmail: customerToPay.value.cardHolderEmail,
      paymentType: customerToPay.value.paymentType,
      jobType: job.jobType,
    })

    if (!response) {
      invoiceError.value = {
        description: 'Unable to create invoice.',
        nextSteps: 'Please try again.',
        enablePaymentLink: true,
      }
    } else if (response.paymentError) {
      invoiceError.value = response.paymentError
    } else {
      invoiceCreated.value = true
    }

    isLoading.value = false
  }

  function reset() {
    isLoading.value = false
    paymentSid.value = ''
    paymentInitiated.value = false
    paymentDetailsConfirmed.value = false
    paymentError.value = null
    isSendInvoice.value = false
    isPaymentConfirmed.value = false
    invoiceError.value = null
    invoiceCreated.value = false
  }

  return {
    paymentInitiated,
    paymentDetailsConfirmed,
    paymentDetailsOpened,
    paymentOpened,
    paymentSid,
    isPaymentConfirmed,
    idempotencyKey,
    escalateCtpResponseModel,
    escalatedCtpAmount,
    paymentError,
    isSendInvoice,
    isLoading,
    customerToPay,
    isTransactionCompleted,
    invoiceError,
    invoiceCreated,
    initiatePayment,
    confirmPayment,
    createInvoice,
    reset,
  }
})
