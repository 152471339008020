<template>
  <v-snackbar v-model="show" :timeout="0" bottom left vertical>
    <TransitionGroup name="slide" class="d-flex flex-col gap-2">
      <div v-for="{ id, jobId, message } in items" :key="Number(id)" class="notification">
        <h3 class="secondary--text">
          <b>{{ jobId }}</b>
        </h3>
        <div>{{ message }}</div>
      </div>
    </TransitionGroup>

    <v-btn color="pink" flat @click="clear">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

type Notification = {
  id: number
  jobId: string
  message: string
}

@Component({
  name: 'UDENotifications',
  components: {},
})
export default class UDENotifications extends Vue {
  public timeout = 5000
  public items: Notification[] = []
  public show = false

  public addNotification(jobId: string, message: string) {
    const id = window.setTimeout(() => {
      this.items.splice(
        this.items.findIndex((n) => n.id === id),
        1
      )
      if (!this.items.length) {
        this.show = false
      }
    }, this.timeout)

    this.items.push({ id, jobId, message })
    this.show = true
  }

  public clear() {
    for (const { id } of this.items) {
      clearTimeout(id)
    }
    this.items = []
    this.show = false
  }
}
</script>

<style scoped>
.v-snack >>> .v-snack__content {
  height: auto;
}

.notification {
  background-color: #8882;
  padding: 0.5rem 1rem;
}

.slide-enter-active {
  animation: slide 0.15s;
}
.slide-leave-active {
  animation: slide 0.15s reverse;
}
@keyframes slide {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
