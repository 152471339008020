import moment from 'moment'
import CosmosJobsDbRecordBase from '@/models/CosmosJobsDbRecordBase'
import Address from '@/models/Address'

export default class CustomerToPayModel extends CosmosJobsDbRecordBase {
  public cardHolderName: string
  public billingAddress: Address = new Address()
  public jobAddress: Address = new Address()
  public amount = 0
  public cardBrand: string
  public cardLastFourDigit: number
  public statementDescription: string
  public paymentType: PaymentType
  public transactionDate: moment.Moment | null
  public balanceTransactionId: string
  public isTransactionCompleted: boolean
  public chargeId: string
  public emergencyId: string
  public cardHolderEmail: string
  public paymentIntentId: string
  public isTransactionCancelled: boolean
  public constructor(jobId: string) {
    super()
    this.jobId = jobId
  }
}

export enum PaymentType {
  Unknown = 'Unknown',
  CustomerContribution = 'CustomerContribution',
  CustomerToPay = 'CustomerToPay',
  ExcessPayment = 'ExcessPayment',
  Drainage = 'Drainage',
  AnsaCustomerToPay = 'AnsaCustomerToPay',
  AnsaExcessPayment = 'AnsaExcessPayment',
}

export const PaymentTypes: Partial<Record<PaymentType, { label: string; description?: string; value?: string }>> = {
  CustomerContribution: {
    value: PaymentType.CustomerContribution,
    label: 'Customer Contribution - VAT Applicable',
    description: 'Payment where the work exceeds the policy limit',
  },
  CustomerToPay: {
    value: PaymentType.CustomerToPay,
    label: 'Customer To Pay - VAT Applicable',
    description: 'Payment for repairs not covered under the policy',
  },
  ExcessPayment: {
    value: PaymentType.ExcessPayment,
    label: 'Excess Payment - No VAT',
    description: 'VAT-exempt payment, typically for drainage jobs',
  },
}

export const CtpPaymentTypes: Partial<Record<PaymentType, { label: string; description?: string; value?: string }>> = {
  ...PaymentTypes,
  Drainage: {
    value: PaymentType.Drainage,
    label: 'CTP Drainage - VAT Applicable',
    description: 'Payment for Drainage/Underground Services work ONLY',
  },
}

export const NewPaymentTypes: Partial<Record<PaymentType, { label: string; description?: string; value?: string }>> = {
  CustomerContribution: {
    value: PaymentType.CustomerContribution,
    label: 'CET - Customer Contribution - VAT Applicable',
    description: 'Payment where the work exceeds the policy limit',
  },
  CustomerToPay: {
    value: PaymentType.CustomerToPay,
    label: 'CET - Customer To Pay - VAT Applicable',
    description: 'Payment for repairs not covered under the policy',
  },
  ExcessPayment: {
    value: PaymentType.ExcessPayment,
    label: 'CET - Excess Payment - No VAT',
    description: 'VAT-exempt payment, typically for drainage jobs',
  },
  AnsaCustomerToPay: {
    value: PaymentType.AnsaCustomerToPay,
    label: 'ANSA - Customer To Pay - VAT Applicable',
    description: 'Payment for Drainage/Underground Services work ONLY',
  },
  AnsaExcessPayment: {
    value: PaymentType.AnsaExcessPayment,
    label: 'ANSA - Excess Payment - No VAT',
    description: 'VAT-exempt payment for US jobs ONLY',
  },
}
