// Stolen from here: https://github.com/lodash/lodash/issues/4400#issuecomment-905035552
// Because standard debounce doesn't always return a promise for invocations.
export function debounceAsync<T, Callback extends (...args: any[]) => Promise<T>>(
  callback: Callback,
  wait: number
): (...args: Parameters<Callback>) => Promise<T> {
  let timeoutId: number | null = null

  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    return new Promise<T>((resolve) => {
      const timeoutPromise = new Promise<void>((resolve) => {
        timeoutId = window.setTimeout(resolve, wait)
      })
      timeoutPromise.then(async () => {
        resolve(await callback(...args))
      })
    })
  }
}
